<template>
    <svg class="svg-icon" aria-hidden="true" :width="width" :height="height">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
    export default {
        name: "SvgIcon",
        props: {
            name: {
                type: String,
                required: true
            },
            width: {
                type: [Number, String],
                default: 18,
            },
            height: {
                type: [Number, String],
                default: 18,
            }
        },
        setup() {
            const req = require.context('@/assets/svg', false, /\.svg$/);
            const requireAll = requireContext => requireContext.keys().map(requireContext);
            requireAll(req);
        },
        computed: {
            iconName() {
                return `#icon-${this.name}`
            }
        }
    }
</script>

<style scoped>
    .svg-icon {
        vertical-align: -0.15em;
        fill: currentColor;
        /*overflow: hidden;*/
    }
</style>
