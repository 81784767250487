import {getCookieValue} from "../../../util";
import {logToUranusEsRequest} from "../../../api/api";
import config from "../../../config";
/**
 * 埋点，数据存到es
 * @params {event_name, project_name, value} 参数必填
 */
export  function logToES (_params) {
  if(!config.noModel) {
    try {
      let params = _params;
      var now = new Date();
      var UTCNow = new Date();
      UTCNow.setFullYear(now.getUTCFullYear())
      UTCNow.setMonth(now.getUTCMonth())
      UTCNow.setDate(now.getUTCDate())
      UTCNow.setHours(now.getUTCHours())
      UTCNow.setMinutes(now.getUTCMinutes())
      UTCNow.setSeconds(now.getUTCSeconds())
      // let traceid= "null" //测试
      // let user_id= "null"  //测试
      let traceid = getCookieValue('traceid')
      let sid = getCookieValue('sid')
      let user_id = ""
      if (sessionStorage.getItem('user') != null) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        user_id = user.uid
      }
      params['traceid'] = traceid == null ? "" : traceid
      params['sid'] = sid == null ? "" : sid
      params['user_id'] = user_id
      params['timestamp'] = Math.floor(UTCNow / 1000)
      _logToES(params).then(res => {
        // console.log('_logToES  res === ', res)
      }).catch(err => {
        // console.log('_logToES  err === ', err)
      })
    } catch (err) {
      // console.log('logToES  err:', err)
    }
  }
}

function _logToES  (params) {
    return logToUranusEsRequest(params);
}

