<template>
    <el-card class="answerBox" :class="{twinkleBox:isTwinkle(answerSheet.question)}"
             :id="getDomId(answerSheet.question.id)">
        <div style="display: flex;flex-direction: row">
            <div style="display: flex;flex-direction: column; width: 100%;">
                <div style="margin-bottom: 10px; font-weight: 600">
                    <span :class="{'dangerText':!answerSheet.question.title}">{{answerSheet.question.title||'您尚未输入问题'}}</span>
                    <span v-if="canSearch && showVariable" style="color: lightslategray;">({{answerSheet.question.answerName}})</span>

                </div>
                <div v-if="answerSheet.question.description">
                    <span class="hoverExchangeBgButton"  @click.prevent.stop="questionDescVisible=!questionDescVisible" @dblclick.prevent @pointerdown.prevent.stop>
                        <i class="icon iconfont icon-wenhao"></i>
                        <span style="margin-left: 5px;">说明</span>
                    </span>
                </div>
                <el-collapse-transition>
                    <div  v-show="questionDescVisible" class="question-description">
                        <div style="padding:10px" v-html="answerSheet.question.description"></div>
                    </div>
                </el-collapse-transition>
                <div>
                    <el-radio-group :disabled="isPreview" v-if="bool(answerSheet.question)" v-model="answerSheet.answer.content">
                        <el-radio v-for="option in boolSelectValues" :key="option.value" :label="option.value">
                            {{option.name}}
                        </el-radio>
                    </el-radio-group>
                    <el-radio-group :disabled="isPreview" v-else-if="single(answerSheet.question)" v-model="answerSheet.answer.content">
                        <el-radio v-for="option in answerSheet.question.options"  :key="option.id" :label="option.name">
                            <span style="white-space: normal;line-height: 24px;">{{option.name}}</span>
                            <el-tooltip class="item" effect="light" placement="top"
                                        v-if="option.desc">
                                <i class="icon iconfont icon-wenhao" style="margin-left: 8px;" @click.prevent.stop
                                   @mousedown.prevent.stop></i>
                                <template #content>
                                    <span v-html="option.desc" style="white-space: pre-wrap"></span>
                                </template>
                            </el-tooltip>
                        </el-radio>
                    </el-radio-group>
                    <el-checkbox-group class="multiply-selection" :disabled="isPreview" v-else-if="multiple(answerSheet.question)" v-model="answerSheet.answer.content">
                        <el-checkbox v-for="option in answerSheet.question.options" :key="option.id"
                                     :label="option.name">
                            <span style="white-space: normal;line-height: 24px;">{{option.name}}</span>
                            <el-tooltip class="item" effect="light" placement="top"
                                        v-if="option.desc">
                                <i class="icon iconfont icon-wenhao" style="margin-left: 8px;" @click.stop></i>
                                <template #content>
                                    <span v-html="option.desc" style="white-space: pre-wrap"></span>
                                </template>
                            </el-tooltip>
                        </el-checkbox>
                    </el-checkbox-group>
                    <!-- type="textarea" -->
                    <!-- :rows="2" -->
                    <!-- <el-input v-else
                              placeholder="请输入答案"
                              v-model="answerSheet.answer.content"
                              @focus.prevent="answerInputFocus">
                    </el-input> -->

                    <el-input v-else-if="isTextType(answerSheet.question)"
                              :placeholder="isPreview ? '未填写' : '请输入答案'"
                              v-model="answerSheet.answer.content"
                              :ref="textInput"
                              :disabled="isPreview"
                              :type="isPreview ? 'textarea' : 'text'"
                              @focus.prevent="answerInputFocus">
                    </el-input>

                    <el-dropdown @command="handleOrganizationCommand" trigger="click" style="width:100%;" v-else>
                        <div>
                            <el-input
                                    :placeholder="isPreview ? '未填写' : '请输入答案'"
                                    :ref="textInput"
                                    v-model="answerSheet.answer.content"
                                    @focus.prevent="answerInputFocus"
                                    :disabled="isPreview"
                                    :type="isPreview ? 'textarea' : 'text'"
                                    :class="{'organiaztion-answer-input': answerSheet.question.answerType === 5}">
                            </el-input>
                            <el-tooltip v-if="answerSheet.question.answerType === 5 && answerSheet.answer.content.length === 0" class="item" effect="dark" content="请填写组织名称" placement="top">
                                <div class="icon iconfont icon-organization-answer icon-not-answer"></div>
                            </el-tooltip>
                            <el-tooltip v-if="answerSheet.question.answerType === 5 && answerSheet.answer.content.length > 0 && answerSheet.finded === true" class="item" effect="dark" content="已找到该组织信息" placement="top">
                                <div class="icon iconfont icon-organization-answer icon-correct"></div>
                            </el-tooltip>
                            <el-tooltip v-if="answerSheet.question.answerType === 5 && answerSheet.answer.content.length > 0 && answerSheet.finded === false" class="item" effect="dark" content="暂未找到该组织" placement="top">
                                <div class="icon iconfont icon-organization-answer icon-not-finded"></div>
                            </el-tooltip>
                        </div>

                        <template #dropdown v-if="answerSheet.question.answerType === 5 && !answerSheet.question.parentId && recentOrganizations.length > 0">
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="organization in recentOrganizations" :key="organization" :command="organization">{{organization}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>


                    <span v-if="noOptions(answerSheet.question)" class="dangerText">您尚未添加可选项</span>

                </div>
            </div>
            <div v-if="canSearch && !isMobile" style="display: flex;flex-direction: column; margin-left:10px">
                <el-link type="primary" @click="highlightPreviousNext(answerSheet.question)" href="javascript:void(0)">
                    <i class="el-icon-arrow-up"></i>
                </el-link>
                <el-link type="primary" @click="highlightNextRect(answerSheet.question)" href="javascript:void(0)">
                    <i class="el-icon-arrow-down"></i>
                </el-link>
            </div>
        </div>

    </el-card>
</template>
<script setup>
import {ref} from 'vue';
import {colorLog} from "../util";
let isUserComposing = ref(false);
let textInput = ref(null);
let inputVNode = ref(null);
textInput = (n)=>{
  if(!n) return;
  inputVNode.value = n;
  inputVNode.value.$el.addEventListener('compositionstart',()=>{
    isUserComposing.value = true;
  })
  inputVNode.value.$el.addEventListener('compositionend',()=>{
    isUserComposing.value = false;
  })
}
</script>

<script>
import {colorLog, isMobile} from "../util";
import {
        BOOL_OPTIONS,
        convertContentToStringIFNotNull,
        noOptions,
        VARIABLE_TYPE_OPTIONS
    } from "../constant/question";
    import {bool, isTextType, multiple, single, singleOrMultiple} from "./common/question";
    import {saveQuestionAnswer, getRecentOrganizations} from "../api/api";

    export default {
        name: "DocQuestionAnswerMode",
        props: ["answerSheet", "autoSave", "highlightNextRect", "highlightPreviousNext", "canSearch", "showVariable","isPreview"],
        data: function () {
            return {
                boolSelectValues: BOOL_OPTIONS,
                isTextType:isTextType,
                bool: bool,
                single: single,
                multiple: multiple,
                singleOrMultiple: singleOrMultiple,
                noOptions: noOptions,
                saveTimer: null,
                questionDescVisible: false,
                answerOrganization: false,
                recentOrganizations: [],
                notifyAnswerChangedTimer: null,
                isMobile:isMobile(),
                inputEvent:null,
            };
        },
        mounted() {
            //刚切换过来的时候让editorJs知道答案是什么。左侧菜单是立即切换过来的，此时右侧内容还没有渲染，应该等右侧预览模式的内容渲染完毕再主动调用回答问题的方法
            // if(this.answerSheet.answer.id){
            //     //如果这是一个已经保存到数据库的答案,刚开始进来只需要通知一下就可以了
            //     this.notifyAnswerChanged(false);
            // }else{
            //     //如果是一个新的答案，则需要保存到数据库
            //     this.answerChanged(false)
            // }
        },
        watch: {
            "answerSheet.answer.content": function () {
                this.answerChanged(true);
            }
        },
        methods: {
            isTwinkle: function (question) {
                return question && question.extraUIInfo && question.extraUIInfo.twinkle
            },
            answerChanged: function (scrollToRect) {
                const self = this
                self.notifyAnswerChanged(scrollToRect);
                console.log('autoSave:' + this.autoSave);
                if (!this.autoSave) {
                    return;
                }
                console.log('start save');
                if (self.saveTimer) {
                    clearTimeout(self.saveTimer);
                }
                self.saveTimer = setTimeout(() => {
                    let saveAnswer = Object.assign({}, self.answerSheet.answer);
                    //将结果转换成字符串再提交
                    saveAnswer.content = convertContentToStringIFNotNull(saveAnswer.content);
                    saveQuestionAnswer(saveAnswer).then(res => {
                        //将接口返回的信息更新到界面的答案对象中，但保留界面的答案对象的content字段（不用再转一次）。
                        let useAnswer = res.data.data
                        useAnswer.content = self.answerSheet.answer.content
                        self.answerSheet.answer = useAnswer;
                    })
                }, 1000);
            },
            tryToReFocusTextInput(wait = 0){
              const textInput = this.inputVNode;
              const endContainer = window.getSelection().getRangeAt(0).endContainer
              if(!textInput || !endContainer.contains(textInput.$el)) return;
              setTimeout(() => {
                textInput.blur();
                textInput.focus();
              },wait);
            },
            updateInputEvent(...args){
              colorLog('updateInputEvent',args)
            },
            notifyAnswerChanged: function (scrollToRect) {
                if (this.notifyAnswerChangedTimer != null) {
                    clearTimeout(this.notifyAnswerChangedTimer);
                }
                this.notifyAnswerChangedTimer = setTimeout(() => {
                    if(this.isUserComposing){
                      colorLog.orange('停止emit answer-changed 因为用户正在拼写');
                      return;
                    }
                    this.$emit('answer-changed', {"answer": this.answerSheet, "scrollToRect": scrollToRect})
                    this.notifyAnswerChangedTimer = null;
                    //解决input失焦
                    this.tryToReFocusTextInput(500);
                    this.tryToReFocusTextInput(1500);
                }, 1500);
            },
            getDomId: function (itemId) {
                return 'question-answer-box-' + itemId;
            },

            answerInputFocus: function (event) {
                this.answerOrganization = this.answerSheet.question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value;

                if (this.answerOrganization) {
                    getRecentOrganizations().then(res => {
                        if (res.status === 200 && res.data instanceof Array) {
                            this.recentOrganizations = res.data;
                        } else {
                            this.recentOrganizations = [];
                        }
                    }).catch(err => {
                        console.error('getRecentOrganizations  err:', err);
                        // 请求出错
                        this.recentOrganizations = [];
                    });
                }
            },
            handleOrganizationCommand: function (command) {
                this.answerSheet.answer.content = command;
                this.notifyAnswerChanged(true);
            }
        }
    }
</script>
<style scoped lang="scss">
    .dangerText {
        color: #F56C6C;
    }

    .answerBox {
        position: relative;
        margin: 10px 0;
        text-align: left;

        .el-radio, .el-checkbox {
            display: block;
            margin: 5px 0 5px 5px;
        }

        .el-card__body {
            padding: 5px !important;
        }
    }
</style>

<style lang="scss">
    .answerBox {
        .el-card__body {
            padding: 5px !important;
        }
        &.is-always-shadow{
          box-shadow: none !important;
          border: none;
        }
    }
    .hoverExchangeBgButton{
        display: inline-block;
        cursor: pointer;
        margin-bottom: 7px;
    }
    .hoverExchangeBgButton:hover{
        color: #409EFF;
    }
    .question-description{
        white-space: pre-wrap;
        margin-bottom: 7px;
        margin-left: 6px;
        color: lightslategray;
        border-left:solid #409EFF 3px;
    }

    .organiaztion-answer-input input{
        padding-right: 40px;
    }

    .icon-organization-answer {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 24px;
        height: 24px;
        float: right;
        top: -32px;
        right: 10px;
        position: relative;
    }

    .icon-not-answer {
        background-image: url('../assets/images/notAnswer.png');
    }

    .icon-not-finded {
        background-image: url('../assets/images/notFinded.png');
    }

    .icon-correct {
        background-image: url('../assets/images/correct.png');
    }
</style>
