import lodashDeepClone from 'lodash.clonedeep';
import axios from "./api/api";
import {ElMessage} from "element-plus";

/**
 * 将item的propName属性由 json字符串 转换为 对象,如果不是json字符串则为普通string
 * @param {{}} item 需要转换的对象
 * @param {string}propName 属性
 */
export const itemJsonStrPro2Obj = function (item, propName) {
    if (!isStr(item[propName])) {
        return
    }
    try {
        item[propName] = JSON.parse(item[propName]);
        if (typeof item[propName] === 'number') {
            item[propName] = String(item[propName])
        }
        // eslint-disable-next-line no-empty
    } catch (e) {
    }
}

/**
 * 将item的propName属性由 对象 转换为 json字符串
 * @param {{}} item 需要转换的对象
 * @param {string}propName 属性
 */
export const itemObjProp2JSONStr = function (item, propName) {
    if (isStr(item[propName]) || item[propName] === null) {
        return
    }
    item[propName] = JSON.stringify(item[propName]);
};

export const isStr = function (o) {
    return typeof o === 'string'
}

const showMessage = Symbol('showMessage')
/**
 *  重写ElementUI的Message
 *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
 */
class MetaMessage {
  success (options, single = true) {
    return this[showMessage]('success', options, single)
  }
  warning (options, single = true) {
    return this[showMessage]('warning', options, single)
  }
  info (options, single = true) {
    return this[showMessage]('info', options, single)
  }
  error (options, single = true) {
    return this[showMessage]('error', options, single)
  }

  [showMessage] (type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName('el-message').length === 0) {
        return ElMessage[type](options)
      }
    } else {
        return ElMessage[type](options)
    }
  }
}

export { MetaMessage}
/**
 * 判断是否为手机访问
 * @return {RegExpMatchArray}
 */
export const isMobile=function() {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

/**
 * 生成UUID
 * @returns {string}
 */
export const uuidGen = function () {
    let s = [];
    let hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    return s.join("");
};

export const debounce = function (fn,wait) {
    let timer = null;
    let context = this;
    return function (...args){
        if(timer){
            clearTimeout(timer);
        }

        timer = setTimeout(()=>{
            fn.apply(context,args);
            timer = null;
        },wait);
    }
}

export const debounceBackTimer = function (fn,wait) {
    let timer = null;
    let context = this;
    function clear(){
        clearTimeout(timer);
    }
    return [function (...args){
        if(timer){
            clear();
        }

        timer = setTimeout(()=>{
            fn.apply(context,args);
            timer = null;
        },wait);
    },clear]
}

export const getUniqueId = function (prefix='',suffix='') {
    return prefix + Date.now() + parseInt(Math.random() * 1000) + suffix;
}

export function getCookie(key){
    return document.cookie.replace(new RegExp('\\s','g'),'').split(';').map(item =>{
        let [k,v] = item.split('=')
        return {[k]:v}
    }).reduce((obj,item)=>{
        return Object.assign(obj,item)
    },{})[key];
}

export function getCookies(){
    return document.cookie.replace(new RegExp('\\s','g'),'').split(';').map(item =>{
        let [k,v] = item.split('=')
        return {[k]:v}
    }).reduce((obj,item)=>{
        return Object.assign(obj,item)
    },{});
}

export function getBase64(imgUrl,cb) {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
        if (this.status == 200) {
            var blob = this.response;
            let src = window.URL.createObjectURL(blob);
            cb(src);
        }
    }
    xhr.send();
}

export function transformTimeInfo(start,end){
    start = new Date(start);
    end = new Date(end);
    let span = end - start;
    let secondQuota = 1000;
    let minuteQuota = 1000 * 60;
    let hourQuota = 1000 * 60 * 60;
    let dayQuota = 1000 * 60 * 60 * 24;

    let transformStr = '';
    if((span / secondQuota) < 60){
        transformStr = '刚刚';
    }
    // else if(5 < (span / secondQuota) && (span / secondQuota) < 60){
    //     transformStr = `${(span / secondQuota).toFixed(0)}秒前`;
    // }
    else if(1 <= (span / minuteQuota) && (span / minuteQuota) < 60){
        transformStr = `${(span / minuteQuota).toFixed(0)}分钟前`;
    }else if(1 <= (span / hourQuota) && (span / hourQuota) < 24){
        transformStr = `${(span / hourQuota).toFixed(0)}小时前`;
    }else if(1<= (span / dayQuota) && (span / dayQuota) <= 7){
        transformStr = `${(span / dayQuota).toFixed(0)}天前`;
    }else{
        transformStr = new Date(end).toLocaleDateString();
    }
    return transformStr;
}

export function transformTimeInfoForList(start,end = Date.now()){
    start = new Date(start);
    end = new Date(end);

    let span = end - start;
    let minuteQuota = 1000 * 60;

    let transformStr;
    if(span < minuteQuota){
        transformStr = `刚刚`;
    }else if(start.getDate() == end.getDate() && start.getFullYear() == end.getFullYear() && start.getMonth() == end.getMonth()){
        transformStr = `今天 ${start.getHours()}:${start.getMinutes() < 10 ? '0':''}${start.getMinutes()}`;
    }else if(start.getFullYear() == end.getFullYear()){
        transformStr = `${start.getMonth() + 1}月${start.getDate()}日 ${start.getHours()}:${start.getMinutes() < 10 ? '0':''}${start.getMinutes()}`;
    }else{
        transformStr = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    }

    return transformStr;
}

//返回当前月天数
export function getMonthDays(year,month){
    let isLeapYear = false;
    if((year % 4 == 0 && year % 100 != 0) || year % 400 == 0){
        isLeapYear = true;
    }

    switch (month){
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
            return 30;
        case 2:
            if(isLeapYear) return 29;
            return 28;
        default:
            return 30;
    }
}

export  function hashCode (str) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const colors = [
    '#44acb6',
    '#9ccf5f',
    '#b0b6e3',
    '#b49cde',
    '#7ddfec',
    '#f7c100',
    '#51bd8a',
    '#f26092',
    '#c2c2c2',
    '#43d1e3',
    '#fedb08',
    '#7985ce',
    '#90a5b0',
    '#bdaca5',
    '#d5e449',
    '#bc65cb',
    '#aed77e',
    '#46c3f9',
    '#a3a3a3'
]

export class ColorInfo{
    constructor(rgba,r,g,b) {
        this.rgba = rgba;
        this.r = r;
        this.g = g;
        this.b = b;
        this.hex = this.getHex().hex;
    }
    getHex(){
        return rgbToHex(this.rgba);
    }
}

export const hexToRgb = function(val){   //HEX十六进制颜色值转换为RGB(A)颜色值
    var an,bn,cn,r,b,g;
    if((/^#/g).test(val)){
        an = val.slice(1,3);
        bn = val.slice(3,5);
        cn = val.slice(5,7);

        r = parseInt(an,16);
        b = parseInt(bn,16);
        g = parseInt(cn,16);

        return new ColorInfo('rgb(' + r + ',' + b + ',' + g + ')',r,b,g);
    }else{
        return new ColorInfo('无效值：' + val);
    }
}

export function rgbToHex (val){  //RGB(A)颜色转换为HEX十六进制的颜色值
    var r, g, b, a,
        regRgba = /rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3})(,([.\d]+))?\)/,    //判断rgb颜色值格式的正则表达式，如rgba(255,20,10,.54)
        rsa = val.replace(/\s+/g,'').match(regRgba);
    if(!!rsa){
        r = parseInt(rsa[1]).toString(16);
        r = r.length == 1 ? '0' + r : r;
        g = (+rsa[2]).toString(16);
        g = g.length == 1 ? '0' + g : g;
        b = (+rsa[3]).toString(16);
        b = b.length == 1 ? '0' + b : b;
        a = (+(rsa[5] ? rsa[5] : 1)) * 100;
        return {hex:'#' + r + g + b, alpha: Math.ceil(a)};
    }else{
        return {hex:val, alpha:100};
    }
}

export const getHashColor=function(str){
    if(typeof str === 'string') {
        let hash = getHashCode(str);
        return colors[Math.abs(hash)%(colors.length)];
    }else if(typeof str === 'number'){
        return colors[str%(colors.length)];
    }

    return null;
}
export const getHashCode=function(str) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

const _colorLog = {

    resolveValue(args){
        typeof args[0] === 'object' ? args.unshift('Obj') : null
        return args[0];
    },
    produceStyle(color){
        //通用样式
        let universalStyle = 'color:#fff;border-radius:10px;padding:5px 5px;font-weight:bold';
        return `background:${color};${universalStyle}`;
    },
    proxyLog(color,args){
        console.log(`%c${this.resolveValue(args)}`,this.produceStyle(color),...args.slice(1));
    },
    log(...args){
        this.blue(...args);
    },
    red(...args){
        this.proxyLog('red',args);
    },
    blue(...args){
        this.proxyLog('blue',args);
    },
    orange(...args){
        this.proxyLog('orange',args);
    },
    pink(...args){
        this.proxyLog('pink',args);
    },
    green(...args){
        this.proxyLog('#40e569',args);
    },
    $1(...args){
        this.proxyLog('#00acec',args);
    },
    $2(...args){
        this.proxyLog('#edbf92',args);
    },
    $3(...args){
        this.proxyLog('#978e8e',args);
    },
    random(...args){
        this.proxyLog(`rgb(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)})`,args);
    }
}

const colorLogFn = (...args) => {
    _colorLog.red(...args);
}


export const colorLog = new Proxy(colorLogFn,{
    apply(target,context,args){
        target(...args);
    },
    get(target,name){
        if(name in _colorLog){
            return _colorLog[name];
        }else{
            return (...args)=>{
                _colorLog.proxyLog(name,args)
            }
        }
    }
})

export const MetaLogger = {
    info(...args){
        colorLog.blue(...args)
    },
    warning(...args){
        colorLog.orange(...args)
    },
    error(...args){
        colorLog.red(...args)
    },
    logo(){
        // let str="                                        \n" +
        //     "                LLLLL,     fLLLLL           \n" +
        //     "               LLLLLLL1    LLLLLL           \n" +
        //     "             .LLLLLLLGGf    iLL1            \n" +
        //     "            iLLLLLLGGGGGL       LLLL        \n" +
        //     "           tLLLLLLCCGGGGLL     LLLLLL.      \n" +
        //     "          LLLLLLL   GGGGLLL    1LLLLLLi     \n" +
        //     "         LLLLLLL     LGGLLLL,   ;LLLLLLf    \n" +
        //     "        LLLLLLL       1GLLLLL:   .LLLLLLL   \n" +
        //     "      .LLLLLLL         ;LLLLLLt    LLLLLLL  \n" +
        //     "     .LLLLLLt           ,LLLLLLf    LLLLLLL \n" +
        //     "     .LLLLLi              LLLLLf     LLLLLL \n" +
        //     "       iii                 ,tt         11,  \n" ;
        let str =
            "           ___           ___                         ___           ___           ___     \n" +
            "          /\\  \\         /\\__\\                       /\\  \\         /\\__\\         /\\  \\    \n" +
            "         |::\\  \\       /:/ _/_         ___         /::\\  \\       /:/ _/_       /::\\  \\   \n" +
            "         |:|:\\  \\     /:/ /\\__\\       /\\__\\       /:/\\:\\  \\     /:/ /\\  \\     /:/\\:\\  \\  \n" +
            "       __|:|\\:\\  \\   /:/ /:/ _/_     /:/  /      /:/ /::\\  \\   /:/ /::\\  \\   /:/  \\:\\  \\ \n" +
            "      /::::|_\\:\\__\\ /:/_/:/ /\\__\\   /:/__/      /:/_/:/\\:\\__\\ /:/__\\/\\:\\__\\ /:/__/ \\:\\__\\\n" +
            "      \\:\\~~\\  \\/__/ \\:\\/:/ /:/  /  /::\\  \\      \\:\\/:/  \\/__/ \\:\\  \\ /:/  / \\:\\  \\ /:/  /\n" +
            "       \\:\\  \\        \\::/_/:/  /  /:/\\:\\  \\      \\::/__/       \\:\\  /:/  /   \\:\\  /:/  / \n" +
            "        \\:\\  \\        \\:\\/:/  /   \\/__\\:\\  \\      \\:\\  \\        \\:\\/:/  /     \\:\\/:/  /  \n" +
            "         \\:\\__\\        \\::/  /         \\:\\__\\      \\:\\__\\        \\::/  /       \\::/  /   \n" +
            "          \\/__/         \\/__/           \\/__/       \\/__/         \\/__/         \\/__/    ";
        // let str =
        //     "                LLLLL,     fLLLLL                      ___           ___                         ___           ___           ___     \n" +
        //     "               LLLLLLL1    LLLLLL                     /\\  \\         /\\__\\                       /\\  \\         /\\__\\         /\\  \\    \n" +
        //     "             .LLLLLLLGGf    iLL1                     |::\\  \\       /:/ _/_         ___         /::\\  \\       /:/ _/_       /::\\  \\   \n" +
        //     "            iLLLLLLGGGGGL       LLLL                 |:|:\\  \\     /:/ /\\__\\       /\\__\\       /:/\\:\\  \\     /:/ /\\  \\     /:/\\:\\  \\  \n" +
        //     "           tLLLLLLCCGGGGLL     LLLLLL.             __|:|\\:\\  \\   /:/ /:/ _/_     /:/  /      /:/ /::\\  \\   /:/ /::\\  \\   /:/  \\:\\  \\ \n" +
        //     "          LLLLLLL   GGGGLLL    1LLLLLLi           /::::|_\\:\\__\\ /:/_/:/ /\\__\\   /:/__/      /:/_/:/\\:\\__\\ /:/__\\/\\:\\__\\ /:/__/ \\:\\__\\\n" +
        //     "         LLLLLLL     LGGLLLL,   ;LLLLLLf          \\:\\~~\\  \\/__/ \\:\\/:/ /:/  /  /::\\  \\      \\:\\/:/  \\/__/ \\:\\  \\ /:/  / \\:\\  \\ /:/  /\n" +
        //     "        LLLLLLL       1GLLLLL:   .LLLLLLL          \\:\\  \\        \\::/_/:/  /  /:/\\:\\  \\      \\::/__/       \\:\\  /:/  /   \\:\\  /:/  / \n" +
        //     "      .LLLLLLL         ;LLLLLLt    LLLLLLL          \\:\\  \\        \\:\\/:/  /   \\/__\\:\\  \\      \\:\\  \\        \\:\\/:/  /     \\:\\/:/  /  \n" +
        //     "     .LLLLLLt           ,LLLLLLf    LLLLLLL          \\:\\__\\        \\::/  /         \\:\\__\\      \\:\\__\\        \\::/  /       \\::/  /   \n" +
        //     "     .LLLLLi              LLLLLf     LLLLLL           \\/__/         \\/__/           \\/__/       \\/__/         \\/__/         \\/__/    \n"+
        //     "       iii                 ,tt         11,  \n";
            console.log(`%c${str}`,'color:#0000FF')

    }
}

function isEmailOrRandId(str){
    let reg = /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^[a-zA-z0-9]+)/i
    return reg.test(str);
}

export const getUserName =  (name)=>{
    if(name.startsWith('用户')) {
        let emailPhone = name.substr('用户'.length,name.length-'用户'.length);
        if(isEmailOrRandId(emailPhone)){
            return emailPhone;
        }
    }return name;

}

export const isPhoneNumber = (number)=>{
    let pattern = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return pattern.test(number);
}

export const isEmail = (email)=>{
    let pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return pattern.test(email);
}

export const checkPlatformIsMobile= () => {
    let sUserAgent = navigator.userAgent.toLowerCase();
    return (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent));
}
export const isTrimEmpty = (str) => {
    if (!str) {
        return true;
    }
    return str.replace(/[ 　  \s]/g,"").length===0
};

export function getBrowser() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    var isIE = userAgent.indexOf("compatible") > -1
        && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    var isSafari = userAgent.indexOf("Safari") > -1
        && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1
        && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (fIEVersion == 11) {
            return "IE11";
        } else {
            return "0";
        }//IE版本过低
        return "IE";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "FF";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }

}

export function copyToClipboard(s,callback) {
    if (window.clipboardData) {
        window.clipboardData.setData('text', s);
    } else {
        (function(s) {
            document.oncopy = function(e) {
                e.clipboardData.setData('text', s);
                e.preventDefault();
                document.oncopy = null;
            }
        })(s);
        document.execCommand('Copy');
    }
    callback && callback();
}

export function formatDate(date) {
    if(typeof date != "object"){
        date = new Date(date);
    }
    function addZero(str){
        str += '';
        str = (str.length == 1 ? '0':'') + str;
        return str;
    }
    var year=date.getFullYear();
    var mon = date.getMonth()+1;
    var day = date.getDate();
    let hours = date.getHours();
    let minus = addZero(date.getMinutes());
    return year+'/'+mon+'/'+day + ' ' + hours + ':'+minus;
}

export class DomEventListenerManager{
    constructor(){
        this.listeners = [];
    }

    registerListener(node,eventName,handler,...params){
        this.listeners.push({node,eventName,handler,params});
        node.addEventListener(eventName,handler,...params);
    }

    removeListener(node,eventName,handler,...params){
        let filteredListeners = [];
        const indexs = [];
        const args = arguments;
        for(const index in this.listeners){
            const listener = this.listeners[index];
            if(
                (node ? listener.node === node : true)
                && (eventName || args.length >= 2 ? eventName === listener.eventName : true)
                && (handler || args.length >= 3 ? handler === listener.handler : true)
                && ((params && params.length) || args.length >= 4 ? params === listener.params : true)
            ){
                indexs.push(index);
                filteredListeners.push(this.listeners[index]);
            }
        }

        this.listeners = this.listeners.filter((item,index)=>{
            return indexs.indexOf(index) === -1;
        })

        while(filteredListeners.length){
            const listener = filteredListeners.pop();
            const {node,eventName,handler} = listener;
            const params = listener.params ? listener.params : [];
            if(node){
                node.removeEventListener(eventName,handler,...params);
            }
        }
    }

    destroy(){
        this.removeListener();

        for (const field in this) {
            if (Object.prototype.hasOwnProperty.call(this, field)) {
                delete this[field];
            }
        }

        Object.setPrototypeOf(this, null);
    }
}
export class BriefEmitter{
    constructor() {
        this.listeners = [];
    }

    emit(eventName,...args){
        this.listeners.forEach(({eventName:_eventName,handler,once},index)=>{
            if(_eventName === eventName){
                if(handler){
                    handler(...args);
                }else{
                    this.listeners.splice(index,1);
                }

                if(once){
                    this.listeners.splice(index,1);
                }
            }
        })
    }

    on(eventName,handler,once){
        this.listeners.push({eventName,handler,once});
    }
    off(eventName,handler){
        const args = arguments;
        this.listeners.forEach((listener,index) => {
            if(
                (eventName ? eventName === listener.eventName : true)
                && (handler || args.length >= 2 ? handler === listener.handler : true)
            ){
                this.listeners.splice(index,1);
            }
        })
    }

    destroy(){
        this.off();
        for (const field in this) {
            if (Object.prototype.hasOwnProperty.call(this, field)) {
                delete this[field];
            }
        }

        Object.setPrototypeOf(this, null);
    }
}

export function getCookieValue(name) { /**获取cookie的值，根据cookie的键获取值**/
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var point = cookieArr[i].indexOf("=");
        var cookiePair = [
            cookieArr[i].substring(0, point),
            cookieArr[i].substring(point + 1, cookieArr[i].length)
        ];
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return "";
}

export function deleteCookie(name,domain) {  /**根据cookie的键，删除cookie，其实就是设置其失效**/
    let delString = `${name}=0;path=/;domain=${domain};expires=${new Date(0).toUTCString()}`;
    console.log('delString : ',delString)
    document.cookie = delString;
}

class SingleListenerManager{
    constructor() {
        this.listeners = [];
    }
    on(eventName,callback,once= false){
        this.listeners.push({
            eventName,
            callback,
            once
        })
    }

    emit(eventName,...params){
        this.listeners.forEach(({eventName:en,callback:cb,once},index)=>{
            if(en === eventName){
                cb(...params)
                if(once){
                    this.listeners.splice(index,1);
                }
            }
        })
    }

    off(eventName,callback){
        const args = arguments;
        this.listeners.forEach((listener,index) => {
            if(
                (eventName ? eventName === listener.eventName : true)
                && (callback || args.length >= 2 ? callback === listener.callback : true)
            ){
                this.listeners.splice(index,1);
            }
        })
    }
}

export const singleListenerManager = new SingleListenerManager();

export const deepClone = (initialObj) => {
    return lodashDeepClone(initialObj);
}

/**
 * 异步下载文件
 * @param url
 * @returns {Promise<void>}
 */
export async function asyncDownloadFile(url,param){
    const res = await axios.get(url,{
        responseType:'blob',
        params:param
    });

    // colorLog.red('asyncDownloadFile res',res);
    let resType = res.data.type.toLowerCase();
    if( resType == 'text/plain' || resType != 'multipart/form-data'){
        //返回的类型不是multiply,将错误信息打出
        readFileInfo(res.data);
        return;
    }
    let info = res.headers['content-disposition'];
    if(!info) {
        ElMessage.error('下载失败');
        return;
    }
    let matched = info.match(/filename=(.+)\.(pdf|docx)$/);
    let filename = matched[1];
    let fileType = matched[2];

    downloadByBlob(res.data,fileType,decodeURIComponent(filename));
}

function readFileInfo(data){
    let fr = new FileReader();
    fr.onload = function (e) {
        let resText;
        try{
            resText  = JSON.parse(e.target.result);
        }catch (err){
            colorLog.red('readFileInfo',err)
            resText = e.target.result;
        }
        ElMessage.error(resText);
    }
    fr.readAsText(data);
}
/**
 * 通过blob请求格式下载文件
 * @param {Object} data blob请求格式接口返回data
 * @param {String} fileType 文件类型后缀
 * @param {String} fileName 文件名
 */
function downloadByBlob(data, fileType, fileName) {
    ElMessage.info(`开始下载 ${fileName}.${fileType}`);
    try {
        let url = window.URL.createObjectURL(data);
        let eleLink = document.createElement('a');
        eleLink.href = url;
        eleLink.download = `${fileName}.${fileType}`;
        document.body.appendChild(eleLink);
        eleLink.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        readFileInfo(error.response.data);
        console.error('download function error!', error);
    }
}

export function appPostMessage(obj){
    colorLog.blue('appPostMessage',obj)
    window.ReactNativeWebView?.postMessage(JSON.stringify(obj));
}

export function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}
export function copyStringToClipboard(str){
    let input = document.createElement('textarea')
    input.value =str;
    document.body.appendChild(input)
    input.select()
    document.execCommand('Copy')
    document.body.removeChild(input);
}

export function isDangerousContent(content,showWarning){
  const htmlInvalid = /<(\w+)>.*<\/\1>/g.test(content);
  if(showWarning && htmlInvalid){
    ElMessage.error('内容不合法');
  }
  return htmlInvalid;
}

export function getSpecificParentElement(innerNode,judgeFn){
  /**
   * @type HTMLElement
   */
  let currentElement = innerNode;
  while(currentElement && !judgeFn(currentElement)){
    currentElement = currentElement.parentElement;
  }
  return currentElement;
}

/**
 * 获取某元素所在的滚动窗口
 * @param innerNode
 * @returns {HTMLElement}
 */
export function getScrollParentElement(innerNode){
  return getSpecificParentElement(innerNode,(element) => {
    return ['scroll','auto'].includes(getComputedStyle(element).overflowY)
  })
}
