<template>
    <!-- :style="{height: scrollerHeight}" -->
<!--    <div id="editorjs_container_scroller" :class="{'previewModeScrollerClass': mode === ACTIVE_MODEL.ANSWER_MODEL}">-->
    <div id="editorjs_container_scroller" class="previewModeScrollerClass">
        <div class="introContainer" v-show="showIntroInfo">
            <div class="introTitle" v-if="introInfo && introInfo.questionnaireTitle&&introInfo.questionnaireTitle.length>0">{{introInfo.questionnaireTitle}}</div>
            <div class="introDesc" v-if="introInfo && introInfo.questionnaireIntro&&introInfo.questionnaireIntro.length>0" v-html="introInfo.questionnaireIntro"></div>
        </div>
        <div id="editorjs_edit_preview" style="min-height: calc(100% - 100px);"></div>
        <slot></slot>
    </div>
</template>

<script>
    import { getTemplateSetting } from "../api/api";
    import { ACTIVE_MODEL } from "../constant/question"
    import EditorJS from "../assets/js/editorjs/editor"
    import MetaSotaBlock  from "../assets/js/editorjs/metasotablock"
    import * as InlineTools from "../assets/js/editorjs/inline-tools"
    import {ImageModule,TableModule,Numbering} from "../assets/js/editorjs/editor-modules";
    import {ElLoading, ElMessage} from 'element-plus';
    import {colorLog, isMobile} from "../util";
    import {EventsType} from "../assets/js/websocket/websocket-message-type";
    import config from "../config";
    export default {
        name: "DocContent",
        props: ['fileId', 'auth','content', 'defaultMode', 'variableConfig', 'getSetting', 'contractId'],
        emits:['editor-ready','editor-messages', 'editor-get-pro-data'],
        data: function () {
            return {
                editorCurrentContent: this.content,
                ACTIVE_MODEL: ACTIVE_MODEL,
                editorEdit: null,
                editorPreview: null,
                editorEditPreview: null,
                isMobile: isMobile(),
                mode: this.defaultMode || ACTIVE_MODEL.EDIT_MODEL,
                editorTools: {
                    metaParagraph:{
                        class: MetaSotaBlock.MetaParagraph,
                        inlineToolbar: true,
                        config: {
                            endpoints: {
                                uploadByFile: `${config.baseUrl}file/${this.fileId}/file-image`,
                                uploadByUrl: `${config.baseUrl}file/${this.fileId}/file-image`,
                                downloadUrl: `${config.baseUrl}file/${this.fileId}/file-image/`
                            },
                        },
                    },
                    contents: {
                        class: MetaSotaBlock.ContentsBlock
                    },
                    boldInlineTool: {class: InlineTools.BoldInlineTool,},
                    fontSizeIncreaseTool:{class: InlineTools.FontSizeIncreaseTool,},
                    fontSizeDecreaseTool:{class: InlineTools.FontSizeDecreaseTool,},
                    linkInlineTool: {class: InlineTools.LinkInlineTool,},
                    italicInlineTool: {class: InlineTools.ItalicInlineTool,},
                    underLineInlineTool: {class: InlineTools.UnderlineInlineTool,},
                    // marker: {class:  InlineTools.Marker},
                    alignLeft:{class: InlineTools.AlignLeft},
                    alignCenter:{class: InlineTools.AlignCenter},
                    alignRight:{class: InlineTools.AlignRight},
                    inlineToolList:{class: InlineTools.InlineToolList},
                    fontSizeSelectorTool: {
                      class: InlineTools.FontSizeSelectorTool,
                    },
                    fontFamilySelectorTool: {
                      class: InlineTools.FontFamilySelectorTool,
                    },
                    listItem: {class: MetaSotaBlock.ListItem, inlineToolbar: true,
                        config: {
                            endpoints: {
                                uploadByFile: `/file/${this.fileId}/file-image`,
                                uploadByUrl: `/file/${this.fileId}/file-image`,
                                downloadUrl: `/file/${this.fileId}/file-image/`
                            },
                        },
                    },
                    table: {class: MetaSotaBlock.Table, inlineToolbar: true,},
                    templateVariableText: {
                        class:  InlineTools.TemplateVariableText
                    },
                    templateVariableBool: {
                        class:  InlineTools.TemplateVariableBool
                    },
                    separator:{
                      class:MetaSotaBlock.Separator,
                      inlineToolbar: true,
                    },
                    formatPainter:{
                      class: InlineTools.FormatPainterTool
                    },
                },
                saver: null,
                loadingInstance: null,
                introInfo: {
                    questionnaireTitle: '',
                    questionnaireIntro: ''
                },
                imageConfig: {
                    downloadUrl: config.baseUrl + `file/${this.fileId}/file-image/`,
                    uploadByUrl: config.baseUrl + `file/${this.fileId}/file-image`,
                    uploadByFile: config.baseUrl + `file/${this.fileId}/file-image`
                }
            }
        },
        watch: {
            variableConfig: {
                handler: function(newVal, oldVal){
                    console.log('docContent watch variableConfig  newVal === ', newVal);
                    console.log('docContent watch variableConfig  oldVal === ', oldVal);
                    const variableConfig = {
                        showVariable: newVal.showVariable
                    }
                    this.editorEditPreview.changeVariableConfig(variableConfig);
                },
                deep:true
            },
            fileId:function () {
                if(this.fileId==null) return;
                console.log('docContent  fileId changed  this.fileId === ', this.fileId);
                // this.init();
            }
        },
        computed: {
            scrollerHeight: function() {
                const height = (window.innerHeight - 60) + 'px';
                return height;
            },
            showIntroInfo: function () {
                return !this.isMobile && this.mode === ACTIVE_MODEL.ANSWER_MODEL
                    && this.introInfo && ((this.introInfo.questionnaireTitle && this.introInfo.questionnaireTitle.length > 0) || (this.introInfo.questionnaireIntro && this.introInfo.questionnaireIntro.length > 0));
            }
        },
        mounted:function () {
          if(this.fileId==null)return;
          this.init();
        },
        methods:{
            init:function (){
              if (this.defaultMode === ACTIVE_MODEL.ANSWER_MODEL) {
                getTemplateSetting(this.fileId,this.auth).then(res=>{
                  if (res.data.code === 0) {
                    this.introInfo = res.data.data;
                    this.initEditor()
                  }
                });
              } else {
                this.initEditor()
              }
            },
            initEditor: function () {
              // console.log('initEditor   this.content ==== ', this.content);
                this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
                // console.log('initEditor  this.content === ', JSON.stringify(this.content));
                this.saver = null;
                this.editorEditPreview = new EditorJS({
                    holder: 'editorjs_edit_preview',
                    scroller: 'editorjs_container_scroller',
                    /**
                     * Tools list
                     */
                    tools: this.editorTools,
                    headerTools:[],
                    minHeight:50,
                    logLevel: 'INFO',
                    modules: {
                      // ImageModule: {
                      //   type: "class",
                      //   class: ImageModule
                      // },
                      Numbering:{
                        type:"instance",
                        instance:new Numbering()
                      }
                    },
                    /**
                     * This Tool will be used as default
                     */
                    initialBlock: 'metaParagraph',


                    mode: this.mode === ACTIVE_MODEL.EDIT_MODEL ? 'TEMPLATE_MODE' : 'TEMPLATE_PREVIEW_MODE',
                    variableConfig: this.variableConfig,
                    /**
                     * Initial Editor data
                     */
                    // data: testData,
                    data: this.editorCurrentContent,
                    onReady: () => {

                        MetaSotaBlock.Emitter.on("image-load", arg => {
                            console.log('image-load', arg)
                        });


                        console.log('editor    ready ----- ');
                        // setTimeout(() => { this.loadingInstance.close(); }, 500);
                        this.loadingInstance.close();
                        this.$emit('editor-ready')
                        this.editorEditPreview.on('template-variable-change', (params) => {
                            // console.log('template-variable-change  params:', params);
                            this.$emit('editor-messages',params)
                        });
                        this.editorEditPreview.on(EventsType.BLOCK_ON_FOCUS,()=>{
                          this.$emit('editor-focus')
                        })
                    },
                    onChange: () => {
                        // console.log('editorEditPreview  change ----- this.mode === ', this.mode);
                        if (this.mode !== ACTIVE_MODEL.EDIT_MODEL) {
                            return;
                        }
                        this.$emit('editor-content-changed')
                        if (this.saver) {
                            clearTimeout(this.saver);
                        }

                        this.saver = setTimeout(() => {
                            const _data = this.editorEditPreview.save()
                            if (JSON.stringify(_data) === JSON.stringify(this.editorCurrentContent)) {
                                this.saver = null;
                                return;
                            }
                            console.log(`editor  save this.mode:${this.mode}  _data:`, _data);
                            this.axios.post(`/template-file/${this.fileId}/pro-data`, JSON.stringify(Object.assign(_data, {type: 'template'})), {
                                headers: {
                                    'Content-Type': 'application/json;charset=UTF-8'
                                }
                            }).then(res => {
                                // console.log('editorEdit  saveFileMetaProData  res === ', res);
                                this.saver = null;
                                if (res.data.code === 0) {
                                    this.editorCurrentContent = _data;
                                    this.$emit('editor-get-pro-data',_data);
                                    // this.editorPreview.content.render(this.editorCurrentContent);
                                }
                            }).catch(error => {
                                ElMessage({
                                  showClose: true,
                                  message: "保存内容失败",
                                  type: 'error'
                                });
                                this.saver = null;
                            });
                        }, 1000)
                    }
                });
                // this.editorPreview = new EditorJS({
                //     holder: 'editorjs_preview',
                //     scroller: 'editorjs_container_scroller',
                //     /**
                //      * Tools list
                //      */
                //     tools: this.editorTools,
                //     /**
                //      * This Tool will be used as default
                //      */
                //     initialBlock: 'metaParagraph',
                //     mode: 'TEMPLATE_PREVIEW_MODE',
                //     /**
                //      * Initial Editor data
                //      */
                //     // data: testData,
                //     data: this.content,
                //     onReady: () => {
                //         this.$emit('editor-ready')
                //         this.editorPreview.on('template-variable-change', (params) => {
                //             // console.log('template-variable-change  params:', params);
                //             this.$emit('editor-messages',params)
                //         });
                //     },
                //     onChange: () => {
                //         // console.log('editorjs_preview  change do not save----- '); // 预览模式的内容不保存
                //     }
                // });
            },
            getAssociateQuestion:function () {
                return this.getCurrentEditor().getQuestions()
            },
            getAssociationVisibleControlQuestion:function(){
                if (this.getCurrentEditor().getAssociationVisibleControlQuestion){
                    return this.getCurrentEditor().getAssociationVisibleControlQuestion()
                }
                // todo 上面的方法写好之后下面这个需要删除
                return this.getAssociateQuestion().map(item => item.uuid);
            },
            deleteQuestionRelatedArea:function(params){
                console.log('deleteQuestionRelatedArea,uuid',params)
                if (this.getCurrentEditor().deleteQuestionRelatedArea) {
                    console.log('deleteQuestionRelatedArea')
                    // todo，现在这个方法还没写好，所以加了个if判断。
                    this.getCurrentEditor().deleteQuestionRelatedArea(params);
                }
            },
            getCurrentEditor:function(){
                // if (this.mode === ACTIVE_MODEL.EDIT_MODEL) {
                //     return this.editorEdit;
                // } else {
                //     return this.editorPreview;
                // }
                return this.editorEditPreview;
            },
            highlightQuestion: function (questionUUID, twinkle, directory, index) {
                return this.getCurrentEditor().highlightQuestion(questionUUID, twinkle, directory, index)
            },
            answerChanged: function (questionUUID,answer,involvedQuestionVisible,scrollToRect) {
                // console.log('answerChanged,start to send msg to editor***')
                // console.log('answerChanged  answer:', answer)
                if (this.mode === ACTIVE_MODEL.ANSWER_MODEL) {
                    return this.editorEditPreview.answerQuestion(questionUUID,answer,involvedQuestionVisible,scrollToRect)
                }
            },
            answerAllQuestion: function (answers) {
                if (this.mode === ACTIVE_MODEL.ANSWER_MODEL) {
                    this.editorEditPreview.answerAllQuestion(answers)
                }
            },
            refreshQuestions:function(questions){
                console.log('docContent   refresh questions:', questions);
                this.editorEditPreview.refreshQuestions(questions)
            },
            questionVariableChanged:function (question) {
                // console.log('questionVariableChanged,start to send msg to editor***')
                // console.log(question)
                if (this.mode === ACTIVE_MODEL.EDIT_MODEL) {
                    this.editorEditPreview.setTemplateVariable(question)
                }
            },
            editorResize: function () {
                this.editorEditPreview.editorResize();
            },
            variableConfigChange: function (config) {
                this.editorEditPreview.changeVariableConfig(config);
            },
            modeChange: function(mode, initCallback) {
                this.mode = mode;
                this.editorEditPreview.changeMode(mode === ACTIVE_MODEL.EDIT_MODEL ? 'TEMPLATE_MODE' : 'TEMPLATE_PREVIEW_MODE');

                if (mode === ACTIVE_MODEL.ANSWER_MODEL) {
                    this.introInfo = this.getSetting();
                    const editorContent = JSON.parse(JSON.stringify(this.editorCurrentContent));
                    this.editorEditPreview.content.render(editorContent)
                    if(initCallback){
                      initCallback()
                    }
                } else {
                    this.editorEditPreview.content.render(this.editorCurrentContent);
                    if(initCallback){
                      initCallback()
                    }
                }
            },
            questionOptionChanged: function (questionUUID, oldOption, newOption) {
                this.editorEditPreview.questionOptionChanged(questionUUID, oldOption, newOption);
            }
        },
        unmounted:function () {
            // 这里暂时重新开启，不destroy掉可能会导致内容重复bug（在线上环境，回答问题界面，从回答tab切到预览tab两次以上，就会发现文档内容重复了两次）。
            this.editorEditPreview?.destroy()
        }
    }
</script>

<style scoped  lang="scss">
    #editorjs_container_scroller {
        height: 100%;
    }
    .introContainer{
        /*padding:10px 50px;*/
        /*background-color: rgba(241,242,245);*/
        /*background-color: #ECF5FF;*/
        /*background-color: #F4F4F4;*/
        background-color: #fdf7e3;
        color:#3c4146;
        border: 1px solid gainsboro;
        /*box-shadow: 0 1px 10px 0 rgb(128 174 222);*/
        /*box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);*/
        box-shadow:0 1px 2px rgba(0,0,0,0.05),0 1px 4px rgba(0,0,0,0.05),0 2px 8px rgba(0,0,0,0.05);
        position: relative;
        margin: 20px 50px 20px;
        border-radius: 5px;
        .my-popover__arrow{
            position: absolute;
            line-height: 0;
            top: -7px;
            left: 12px;
            background: rgba(241,242,245);
            width: 10px;
            height: 10px;
            border: 1px solid #e4e7ed;
            border-bottom-color: transparent;
            border-right-color: transparent;
            transform: rotate(45deg);
        }
    }
    .introTitle {
        text-align: left;
        font-family: "Times New Roman", 宋体;
        /*font-size: 15pt;*/
        font-weight: bold;
        line-height: 2em;
        background-color: #fbf2d4;
        color: #6a737c;
        border-bottom: 1px solid #f1e5bc;;
        padding: 5px 10px;
    }
    .introDesc{
        font-family: "Times New Roman", 宋体;
        font-size: 14px;
        text-align: left;
        line-height: 2.5em;
        white-space: pre-wrap;
        padding: 5px 10px;
    }
    #editorjs_container_scroller::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        display: block;
    }
    #editorjs_container_scroller::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
    }
    #editorjs_container_scroller::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
    }
    #editorjs_container_scroller::-webkit-scrollbar-track-piece {
        background-color: #f8f8f8;
    }
    #editorjs_container_scroller {
        overflow-y: auto;
        overflow-x: hidden;
        background-color: white;
    }
    .previewModeScrollerClass {
        // border: 1px solid #EBEEF5;
        box-shadow: 0px 5px 10px 0px rgb(8 24 60 / 15%);
    }
</style>
