export const ContractApprovalStatus = {
    DRAFT:0,//默认
    LAUNCH_READY_TO_SIGN:10,//发起待签署
    LAUNCH_READY_TO_SIGN_PASSED:11,//发起待签署通过
    LAUNCH_READY_TO_SIGN_REFUSED:12,//发起待签署被拒
    LAUNCH_SIGNED:20,//发起已签署
    LAUNCH_SIGNED_PASSED:21,//发起已签署通过
    LAUNCH_SIGNED_REFUSED:22,//发起已签署被拒;
    CANCELED:30
}

export const CLMResourceType = {
    PRO:1,
    PUBLISHED_TEMPLATE:2,
    CONTRACT:4,
    ALL:7
}

export const ApprovalType = {
    TEMPLATE_PUBLISH:1, // 发布模板
    READY_TO_SIGN:2, // 发起待签署
    SIGNED:3 // 已签署
}

export const ApprovalStatus = {
    WAITING_TO_APPROVE:0,
    PASSED:1,
    REJECT:2
}

export const FilterType = {
    ALL:0,//全部文件
    CONTRACT_WAIT_APPROVE_BY_ME:1,//待我审核的合同
    PUBLISHED_TEMPLATE_WAIT_APPROVE_BY_ME:2,//待我审核的模版
}

export const ContractSharePermissionType = {
    READ_ONLY:0,//只读
    FULL_ACCESS:1,//完全访问
    EDITABLE:2,//可编辑
    CAN_COMMENT:3//可评论
}

export const ContractDateStatus = {
    GENERATED: 0, // 默认状态（刚生成）
    UPCOMING: 1, // 即将发生
    PENDING: 2, // 待处理
    PROCESSED: 3 // 已处理
}

export const ContractDatePeriodTimeUnit = {
    YEAR: {
        name: "年", value: 1
    },
    MONTH: {
        name: "月", value: 2
    },
    WEEK: {
        name: "周", value: 3
    },
    DAY: {
        name: "日", value: 4
    },
    QUARTER: {
        name: "季度", value: 5
    }
}
//文件的类型
export const FileTypeName = {
    ContractFile:'contract-file'
}
export const ContractDatePeriodTimeUnitMap = {
    "年": 1,
    "月": 2,
    "周": 3,
    "日": 4,
}

export const CheckTimeTextResultType = {
    BASE_TIME: "baseTime",
    TIME_LENGTH: "timeLength",
    CIRCLE_TIME: "circleTime",
    UN_KNOWN: "unKnown"
}

export const ContractDateFilter = {
    ALL: 0,
    NOT_EXPIRED: 1,
    EXPIRED: 2,
    PENDING: 4,
    PROCESSED: 8,
}


export function getContractStatusStyleClassName(state){
    if (state === ContractApprovalStatus.DRAFT) {
        return 'stage-gray';
    } else if (state === ContractApprovalStatus.LAUNCH_SIGNED_PASSED) {
        return 'stage-green';
    } else if(state === ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED){
        return 'stage-purple';
    } else if(state === ContractApprovalStatus.LAUNCH_READY_TO_SIGN){
        return 'stage-yellow';
    }else if(state === ContractApprovalStatus.CANCELED){
        return 'stage-black';
    } else if(state === CLMResourceType.PRO){
        return 'stage-gray';
    } else {
        return 'bg-mona-lisa';
    }
}

export function getContractStatusLabel(state){
    if (state === ContractApprovalStatus.DRAFT) {
        return '草稿';
    } else if (state === ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED) {
        return '定稿';
    } else if (state === ContractApprovalStatus.LAUNCH_SIGNED_PASSED) {
        return '已签署';
    } else if (state === ContractApprovalStatus.CANCELED) {
        return '已取消';
    } else if(state === ContractApprovalStatus.LAUNCH_READY_TO_SIGN){
        return '审批中';
    }else if(state === ContractApprovalStatus.LAUNCH_READY_TO_SIGN_REFUSED){
        return '已驳回';
    }
}

/**
 * 返回该permissionType是否有评论的编辑权限。
 * @param permissionType
 * @return {boolean}
 */
export const checkCommentEditPermission = function (canComment) {
    return canComment;
};

export function getOtherParties(otherParties){
  if(otherParties){
    let ownKeyWord;
    try {
      ownKeyWord = sessionStorage.getItem('teamInfo').ownKeyWord.split(',');
    }catch (e){
      //本地环境会进入这个逻辑
      ownKeyWord = ['秘塔'];
    }
    let keyWordReg = new RegExp(`(${ownKeyWord.join('|')})`,'g');
    otherParties = otherParties.replace(/(有限公司|不明|有限责任公司|公司)/g,'');
    let arr = JSON.parse(otherParties);
    arr = arr.filter(item => {
      //如果名称包含团队关键字,就去除该名称
      if(item.search(keyWordReg) > -1){
        return false;
      }
      return item.length > 0
    });
    return arr;
  }else{
    return []
  }
}

export function composeOtherParties(otherParties){
  const filteredOtherParties = getOtherParties(otherParties);
  return filteredOtherParties[0].substring(0,8) +
    ((filteredOtherParties.length > 1 || filteredOtherParties[0].length > 8)  ? '...':'')
}
