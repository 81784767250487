<template>
  <el-config-provider :locale="locale">
  <!--<div id="nav">-->
    <!--<router-link to="/home">Home</router-link> |-->
    <!--<router-link to="/about">About</router-link>-->
  <!--</div>-->
  <router-view v-if="!reloading"/>
  </el-config-provider>
  <div class="feedback" v-if="!checkPlatformIsMobile() && !config.noModel">
    <FeedbackButton></FeedbackButton>
  </div>
</template>
<script>
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import {MetaLogger,checkPlatformIsMobile} from "./util";
import FeedbackButton from "./components/FeedbackButton";
import {globalTagClick,globalMonitorClick} from "./assets/js/tag/tag";
import "./assets/css/contract.css";
import "./assets/css/icon.css";
import "./assets/css/test.css";
import './assets/css/customScroller.css';
import config from "./config"

export default {
  name:"layout",
  components:{FeedbackButton, [ElConfigProvider.name]: ElConfigProvider},
  data(){
    return {
      config:config,
      locale:zhCn,
      reloading:false,
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods:{
    checkPlatformIsMobile,
    reload(){
      this.reloading = true;
      this.$nextTick(() => {
        this.reloading = false;
      });
    },
  },
  mounted() {
    MetaLogger.logo();
    //调用该方法 作用:监听docuemnt的click事件->emit时请求埋点接口
    globalMonitorClick();
    //这里的目的是用来更改element-ui的css变量值
    document.querySelector('body').classList.add('meta-custom-el--variation');
  }
}
</script>
<style>
:root{
  font-size: 14px;
  --contractViewMaskZIndex:999;
  --topbarHeight:5.7vw;
  --bottomRadiusValue : .78vw;
  --minWidth:1200px;
  --topbarWidth:100%;
  --minBaseWidth:1200px;
  /*关于页面头部尺寸的变量*/
  --header-top-part-height:3rem;
  --header-bottom-part-height:4rem;
  --header-height:calc(var(--header-top-part-height) + var(--header-bottom-part-height));

  --meta-right-side-header-height:7rem;

  /*用户头像圆圈尺寸*/
  --userIconSize:28px;

  /*蓝色*/
  --lightBlue:#5280FF;
  --lightBlueLight:#A8BFFF;
  --darkBlue:#3B5DBB;
  --blue-1:#D5E0FF;
  --hoverLightBlue:#ecf5ff;
  --hoverDarkBlue: #c5d9ef;
  /*灰色*/
  --near-white:rgb(248,249,250);
  --near-white-2:#fafbfd;
  --gray-1:rgb(239 240 241);
  --gray-2:rgb(196,198,206);
  --gray-3:#97989d;
  --gray-4:#E3E2E0;
  --border-gray:rgb(220, 223, 230);
  --font-gray:#777;
  --page-gray:rgb(248,249,250);

  /*黑色*/
  --font-black:#2c3e50;
  /* 阴影 */
    --box-shadow-1:0 2px 5px 1px var(--gray-2),0 0px 5px 3px var(--gray-1);

  /* timing-function */
  --cubic-bounce-1 :cubic-bezier(0.41, 1.03, 0.67, 1.1);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  text-size-adjust: none;

}
.insetCenter{
  position: absolute;
  top:50%;
  left:50%;

  transform:translate(-50%,-50%);
}
.meta-light-blue{
  color:var(--lightBlue);
}
#nav {
  padding: 30px;
}
/*对el 按钮的自定义*/
.el-message-box__btns  .el-button{
  border-radius: 0 !important;
}
.el-button{
  border-radius: 0 !important;
}
.el-button--primary{
  background-color: var(--lightBlue) !important;
  border-radius: 0 !important;
}
.el-button--primary.is-disabled[disabled]{
  background: var(--gray-3) !important;
  border-color: var(--gray-3) !important;
}
.el-button--primary:hover{
  background-color: var(--darkBlue) !important;
}
.el-upload-dragger .el-upload__text em{
  color: var(--lightBlue) !important;
}
.el-upload-dragger:hover{
  border-color: var(--lightBlue) !important;
}
.el-button--default:hover:not([cancelcustom]):not([class*=el-button--primary]):not(.is-disabled){
  color: var(--lightBlue) !important;
  border-color: var(--lightBlue) !important;
}
.el-button--default[cancelcustom]:hover{
  filter:brightness(95%);
}
.el-button--default:not([cancelcustom],[class*=el-button--primary]):focus{
  color:var(--lightBlue) !important;
}
.el-button:not([cancelcustom]):focus{
  border-color: var(--lightBlue) !important;
}
.el-button--primary:focus{
  color:#fff!important;
}
.el-button--primary{
  border-color: var(--lightBlue) !important;
}
.el-menu *:hover{
  color: var(--lightBlue) !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label{
  color: var(--lightBlue) !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: var(--lightBlue) !important;
  border-color: var(--lightBlue) !important;
}
.el-switch.is-checked .el-switch__core{
  border-color: var(--lightBlue)!important;
  background-color: var(--lightBlue)!important;
}
.el-tag:not([class*=el-tag--info]){
  color: var(--lightBlue) !important;
  border-color: var(--lightBlue)!important;
}
.el-pagination button:hover{
  color: var(--lightBlue) !important;
}
.el-menu-item.is-active{
  color: var(--lightBlue) !important;
}
.el-dialog__footer .el-button--default{
  border-radius: 0 !important;
}
.el-tag{
  --el-tag-font-color:var(--lightBlue) !important;
  --el-tag-hover-color:var(--lightBlue) !important;
}
/*对el 按钮的自定义 end*/

.meta-custom-el--variation{
  --el-color-primary:var(--lightBlue);

}



#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html,body{
  height: 100%;
  overflow: hidden;
}
</style>
