import { createRouter, createWebHashHistory } from 'vue-router'
import AnswerQuestion from "../views/AnswerQuestion";

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // {
  //   path: '/',
  //   name: 'DocTemplate',
  //   component: DocTemplate
  // },
  {
    path: '/',
    name: 'Home',
    redirect:'/index',
  },
  {
    path: '/doc',
    name: 'doc',
    component: () => import('../views/EditorInitializer')
  },
  {
    path: '/answerQuestion',
    name: 'answerQuestion',
    component:() => import('../views/AnswerQuestion')
  },
  {
    path: '/answerQuestionSubmitPage',
    name: 'answerQuestionSubmitPage',
    component:() => import('../views/AnswerQuestionSubmitPage')
  },
  {
    path: '/answerSheetInfo/:fileId',
    name: 'answerSheetInfo',
    component:() => import('../views/TemplateAnswerSheetInfo')
  },
  {
    path: '/answerSheetSimpleInfo/:fileId',
    name: 'answerSheetSimpleInfo',
    component:() => import('../views/TemplateAnswerSheetsSimpleInfo')
  },
  {
    path: '/answerSheetCompleteInfo/:fileId',
    name: 'answerSheetCompleteInfo',
    component:() => import('../views/TemplateAnswerSheetsCompleteInfo')
  },
  {
    path: '/help',
    name: 'help',
    component:() => import('../views/Help')
  },
  {
    path: '/templatePublish',
    name: 'templatePublish',
    component:() => import('../views/TemplatePublish'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/templateApproval',
    name: 'templateApproval',
    component:() => import('../views/TemplateApprovalList')
  },{
    path:'/publishedTemplateCategory',
    name:'publishedTemplateCategory',
    component:()=>import('../views/PublishedTemplateCategory'),
    meta: {
      requireAuth: true
    }
  },
  {
    path:'/contract/draft',
    name:'draftContract',
    component: () => import('../views/contract/DraftContract')
  },
  {
    path:'/contract/view',
    name:'contractView',
    component: () => import('../views/contract/ContractView'),
    meta: {
      requireAuth: true
    }
  },
    {
        path:'/mobile/contract/view',
        name:'contractViewApp',
        component: () => import('../views/mobileViews/contract/contractViewApp'),
        meta: {
            requireAuth: true
        }
    },
{
    path:'/contractList',
    name:'contractList',
    component:()=>import('../views/ContractList'),
    meta: {
      requireAuth: true
    }
  },{
    path:'/contractHistory',
    name:'contractHistory',
    component:()=>import('../views/contract/ContractHistoryView')
  }
  ,{
    path:'/contractEdit',
    name:'contractEdit',
    component:()=>import('../views/ContractEdit'),meta: {
      requireAuth: true
    }
  }
 ,{
    path:'/organization',
    name:'organization',
    component:()=>import('../views/organization'),
    meta: {
      requireAuth: true
    }
  },
  {
    path:'/contract/:contractId/diff',
    name:'contractDiff',
    component:()=>import('../views/contract/ContractDiff2'),
    meta: {
      requireAuth: true
    }
  },
    {
        path:'/mobile/contract/:contractId/diff',
        name:'contractSinglePageDiff',
        component:()=>import('../views/mobileViews/contract/contractSinglePageDiff'),
        meta: {
            requireAuth: true
        }
    },
    {
    path:'/organizationUserSelection',
    name:'organization-user-selection',
    component:()=>import('../components/organizationUserSelection'),
    meta: {
      requireAuth: true
    },
  },{
    path:'/createTeam',
    name:'createTeam',
    component:()=>import('../views/CreateTeam'),
    meta: {
      requireAuth: true
    },
  },
  {
    path:'/pdfView',
    name:'pdfView',
    component:()=>import('../components/PdfPreview'),
    meta: {
      requireAuth: true
    }
  },
  {
    path:'/dataDictionary',
    name:'dataDictionary',
    component:()=>import('../views/DataDictionary'),
    meta:{
      requireAuth: true
    },
  },
  {
    path:'/user',
    name:'user',
    component:()=>import('../views/User'),
    meta:{
       requireAuth: true
    },
  },
  {
    path:'/role',
    name:'role',
    component:()=>import('../views/Role'),
    meta:{
      requireAuth: true
    },
  },
  {
    path:'/role/edit/:id?',
    name:'roleEdit',
    component:()=>import('../views/RoleEdit'),
    meta:{
      requireAuth: true
    },
  },
  {
    path:'/contract/files',
    name:'files',
    component:()=>import('../views/contract/ContractFiles'),
    meta:{
      requireAuth: true
    },
  },
{
    path:'/contract/files/:pageIndex',
    name:'filesCertain',
    component:()=>import('../views/contract/ContractFiles'),
    meta:{
        requireAuth: true
    },
},
  {
    path:'/contract/activities',
    name:'activities',
    component:()=>import('../views/contract/ContractActivities'),
    meta:{
      requireAuth: true
    },
  },
  {
    path:'/index',
    name:'contractIndex',
    component:()=>import('../views/contract/ContractIndex'),
    meta:{
      requireAuth: true
    }
  },
{
    path:'/contract/viewVersionFile/:versionCode',
    name:'viewVersionFile',
    component:()=>import('../views/mobileViews/contract/viewVersionFile'),
},
    {
        path:'/contract/viewVersionFileApp/:contractId/:versionId',
        name:'viewVersionFileApp',
        component:()=>import('../views/mobileViews/contract/viewVersionFileApp'),
    },
  {
    path:'/advanced-search',
    name:'advancedSearch',
    component:()=>import('../views/contract/AdvancedSearch'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/new-document',
    name:'newDocument',
    component:()=>import('../views/contract/NewDocument'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/settings/bulk-upload/:id?',
    name:'bulkUpload',
    component:()=>import('../views/contract/BulkUpload'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/settings/teamManagement',
    name:'teamManagement',
    component:()=>import('../views/TeamManagement'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/settings/addTeam',
    name:'addTeam',
    component:()=>import('../views/AddTeam'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/settings/editTeam',
    name:'editTeam',
    component:()=>import('../views/EditTeam'),
    meta:{
      requireAuth: true
    }
  },{
    path: '/settings/profile',
    name:'profile',
    component:()=>import('../views/MyDetails'),
    meta: {
      requireAuth: true
    }
  },{
    path: '/index/deadline',
    name:'deadline',
    component:()=>import('../views/contract/ContractDeadline'),
    meta:{
      requireAuth: true
    }
  },
  {
    path: '/index/approvals',
    name:'approvals',
    component:()=>import('../views/contract/ContractApprovals'),
    meta:{
      requireAuth: true
    }
  },
  {
    path: '/index/approvals/edit/:id?',
    name:'approvalEdit',
    component:()=>import('../views/MetaApprovalEdit'),
    meta:{
      requireAuth: true
    }
  },
  {
    path: '/login',
    name:'login',
    component:()=>import('../views/Login'),
  },
    {
        path:'/mobileLogin',
        name:'mobileLogin',
        component: ()=>import('../views/mobileViews/MobileLogin')
    },
    {
        path:'/weChatAuth',
        name:'weChatAuth',
        component: ()=>import('../views/mobileViews/WeChatAuth')
    },
    {
        path: '/mobile/resolveReject',
        name:'resolveReject',
        component: ()=>import('../views/mobileViews/ResolveReject'),
        meta:{
            requireAuth: true
        }
    },
    {
        path: '/mobile/templateDocContent',
        name: 'templateDocContent',
        component: ()=>import('../views/mobileViews/template/TemplateDocContent'),
        meta:{
            requireAuth: true
        }
    },
  {
    path: '/events',
    name: 'events',
    component:() => import('../views/Events'),
    meta:{
      requireAuth: true
    }
  },{
    path: '/register',
    name:'register',
    component:()=>import('../views/CompleteUserInfo'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/joinTeam',
    name:'joinTeam',
    component:()=>import('../views/JoinTeam')
  },{
    path: '/contract/index/messages',
    name:'messages',
    component:()=>import('../views/contract/ContractMessages'),
    meta:{
      requireAuth: true
    }
  },{
    path: '/test/selector',
    name:'selectorTest',
    component:()=>import('../components/selector/TestForSelector'),
  },{
    path: '/test/tagEdit',
    name:'tagEdit',
    component:()=>import('../views/test/TestTagEdit'),
  },
    {
        path: '/test/titleEdit',
        name:'titleEdit',
        component:()=>import('../views/test/TestTitleEditor'),
    }
  ,{
    path: '/home',
    name:'home',
    component:() => location.hostname=='localclm.metaeye.cn' ? import('../views/home/HomePage-single') : import('../views/home/HomePage'),
  },
    {
    path: '/test/signUp',
    name:'signUpTest',
    component:()=>import('../components/account/Test4SignUp'),
  },
    {
        path: '/test/editor',
        name:'testForEditor',
        component:()=>import('../views/test/editor/TestMobileEditor'),
    },
    {
        path:'/privacyPolicy',
        name:'privacyPolicy',
        component:()=>import('../views/home/privacyPolicy'),
    },
    {
        path:'/servicePolicy',
        name:'servicePolicy',
        component:()=>import('../views/home/servicePolicy'),
    },
  {
    path:'/error',
    name:'error',
    component:()=>import('../views/Error'),
  },
    {
        path:'/404',
        name:'notfound',
        component:()=>import('../views/Error'),
        meta:{
            "errorMsg":"没有找到该页面"
        }
    },
    {
        path:'/helpCenter',
        name:'helpCenter',
        component:()=>import('../views/help/HelpCenter')
    },
    {
        path:'/statistic',
        name:'statistic',
        component:()=>import('../views/Statistic')
    },
    {
        path:'/mobile/answerSheetReview',
        name:'answerSheetReview',
        component:()=>import('../views/mobileViews/contract/MobileAnswerSheetReview')
    },
    {
        path:'/mobile/templatePreviewQuestions',
        name:'templatePreviewQuestions',
        component:()=>import('../views/mobileViews/template/TemplatePreviewQuestions')
    },
    {
      path:'/teamStatistic',
      name:'teamStatistic',
      component:()=>import('../views/TeamStatistics'),
      meta:{
        requireAuth: true
      }
    },
    {
      path:'/visualizationManagement',
      name:'visualizationManagement',
      component:()=>import('../views/VisualizationManagement'),
      meta: {
        requireAuth: true
      },
    },
    {
        path:'/upgrade',
        name:'upgrade',
        component:()=>import('../views/Upgrade'),
        meta: {
            requireAuth: true
        },
    },
    {
        path:'/order',
        name:'order',
        component:()=>import('../views/Order'),
        meta: {
            requireAuth: true
        },
    },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log('router beforeEach from === ', from);
//   console.log('router beforeEach to === ', to);
//   next();
// })

export default router
