import {getUniqueId} from "../../../util";

export const CHART_TYPES = {
    BAR:'柱状图',
    LINE:'折线图',
    PIE:'饼图',
    TABLE:'表',
    '表':'TABLE',
    '饼图':'PIE',
    '折线图':'LINE',
    '柱状图':'BAR',
}

export const CHART_EVENTS = {
    EXPAND_INPUT_AREA:'0',
    CHECK_INPUT_VALIDATION:'1'
}

export const CONFIG_TYPES = {
    METRICS:"度量",
    BUCKETS:"聚合"
}

export const AGGREGATIONS = {
    AVERAGE:'平均值',
    COUNT:'总数',
    MAX:'最大值',
    MEDIAN:'中位数',
    MIN:'最小值',
    PERCENTILE_RANKS:'百分位等级',
    PERCENTILES:'百分位数',
    STANDARD_DEVIATION:'标准方差',
    SUM:'总和',
    UNIQUE_COUNT:'唯一计数',

    DATE:'日期',
    FILTER:'过滤器',
    TERMS:'条款'
}

export const CONFIG_OPTIONS = {
    X_AXIS:'X轴',
    Y_AXIS:'Y轴',
    DOT_SIZE:'点',
    SPLIT_SERIES:'分割系',
    SLICE_SIZE:'份',
    SPLIT_SLICES:'分割份',
    METRIC:'度',
    SPLIT_ROWS:'分行',
}

export const CONFIG_TYPE_OPTIONS = {
    [CHART_TYPES.LINE]:{
        [CONFIG_TYPES.METRICS]: {
            [CONFIG_OPTIONS.Y_AXIS]:{
                limit: Infinity,
            }
        },
        [CONFIG_TYPES.BUCKETS]: {
               [CONFIG_OPTIONS.X_AXIS]:{
                   limit: 1,
               },
               [CONFIG_OPTIONS.SPLIT_SERIES]:{
                   limit: 3
               }
        }
    },
    [CHART_TYPES.BAR]:{
        [CONFIG_TYPES.METRICS]: {
            [CONFIG_OPTIONS.Y_AXIS]:{
                limit: Infinity
            }
        },
        [CONFIG_TYPES.BUCKETS]: {
            [CONFIG_OPTIONS.X_AXIS]: {
                limit: 1
            },
            [CONFIG_OPTIONS.SPLIT_SERIES]: {
                limit: 3
            }
        }
    },
    [CHART_TYPES.PIE]:{
        [CONFIG_TYPES.METRICS]:{
            [CONFIG_OPTIONS.SLICE_SIZE]: {
                limit:1
            }
        },
        [CONFIG_TYPES.BUCKETS]: {
                [CONFIG_OPTIONS.SPLIT_SLICES]: {
                    limit: Infinity
                }
        },
    },
    [CHART_TYPES.TABLE]:{
        [CONFIG_TYPES.METRICS]: {
            [CONFIG_OPTIONS.METRIC]: {
                limited: Infinity
            }
        },
        [CONFIG_TYPES.BUCKETS]: {
            [CONFIG_OPTIONS.SPLIT_ROWS] :{
                limit: Infinity
            }
        }
    },
}

export const FIELD_TYPES = {
    NUMBER:'数值',
    STRING:'关键字',
    DATE:'日期',
}

export const AGGREGATION_TYPES = {
    [AGGREGATIONS.AVERAGE]: [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.COUNT]: [FIELD_TYPES.NUMBER,FIELD_TYPES.DATE,FIELD_TYPES.STRING],
    [AGGREGATIONS.MAX]: [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.MEDIAN]: [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.MIN] : [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.PERCENTILE_RANKS] : [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.PERCENTILES] : [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.STANDARD_DEVIATION] : [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.SUM] : [FIELD_TYPES.NUMBER],
    [AGGREGATIONS.DATE] : [FIELD_TYPES.DATE],
    [AGGREGATIONS.TERMS] : [FIELD_TYPES.NUMBER,FIELD_TYPES.DATE,FIELD_TYPES.STRING],
    [AGGREGATIONS.UNIQUE_COUNT] : [FIELD_TYPES.NUMBER,FIELD_TYPES.DATE,FIELD_TYPES.STRING],
    [AGGREGATIONS.FILTER] : [FIELD_TYPES.NUMBER,FIELD_TYPES.DATE,FIELD_TYPES.STRING],
}

export const METRICS_AGGREGATIONS = {
    [CHART_TYPES.LINE]:{
        AVERAGE:AGGREGATIONS.AVERAGE,
        COUNT:AGGREGATIONS.COUNT,
        MAX:AGGREGATIONS.MAX,
        MEDIAN:AGGREGATIONS.MEDIAN,
        MIN:AGGREGATIONS.MIN,
        PERCENTILE_RANKS: AGGREGATIONS.PERCENTILE_RANKS,
        PERCENTILES: AGGREGATIONS.PERCENTILES,
        SUM:AGGREGATIONS.SUM,
        UNIQUE_COUNT: AGGREGATIONS.UNIQUE_COUNT,
        STANDARD_DEVIATION: AGGREGATIONS.STANDARD_DEVIATION
    },
    [CHART_TYPES.BAR]:{
        AVERAGE:AGGREGATIONS.AVERAGE,
        COUNT:AGGREGATIONS.COUNT,
        MAX:AGGREGATIONS.MAX,
        MEDIAN:AGGREGATIONS.MEDIAN,
        MIN:AGGREGATIONS.MIN,
        PERCENTILE_RANKS: AGGREGATIONS.PERCENTILE_RANKS,
        PERCENTILES: AGGREGATIONS.PERCENTILES,
        SUM:AGGREGATIONS.SUM,
        UNIQUE_COUNT: AGGREGATIONS.UNIQUE_COUNT,
        STANDARD_DEVIATION: AGGREGATIONS.STANDARD_DEVIATION
    },
    [CHART_TYPES.TABLE]:{
        AVERAGE:AGGREGATIONS.AVERAGE,
        COUNT:AGGREGATIONS.COUNT,
        MAX:AGGREGATIONS.MAX,
        MEDIAN:AGGREGATIONS.MEDIAN,
        MIN:AGGREGATIONS.MIN,
        PERCENTILE_RANKS: AGGREGATIONS.PERCENTILE_RANKS,
        PERCENTILES: AGGREGATIONS.PERCENTILES,
        SUM:AGGREGATIONS.SUM,
        UNIQUE_COUNT: AGGREGATIONS.UNIQUE_COUNT,
    },
    [CHART_TYPES.PIE]:{
        COUNT:AGGREGATIONS.COUNT,
        SUM:AGGREGATIONS.SUM,
        UNIQUE_COUNT: AGGREGATIONS.UNIQUE_COUNT,
    }
}

export const BUCKETS_AGGREGATIONS = {
    DATE: AGGREGATIONS.DATE,
    FILTER: AGGREGATIONS.FILTER,
    TERMS: AGGREGATIONS.TERMS
}

export const FILTER_OPERATORS = {
    IS:"是",
    IS_NOT:"不是",
    IS_ONE_OF:"包含其中之一",
    IS_NOT_ONE_OF:"不包含其中之一",
    BEFORE:'之前',
    AFTER:'之后',
    IN_RANGE:'在范围内',
    OUT_OF_RANGE:'不在范围内'
}

export const FILTER_VALUE_PROPS = {
    CERTAIN:'value',
    MULTI:'values',
    VALUE_RANGE:'valueRange',
    TIME_RANGE:'timeRange'
}

export const FILTER_OPERATORS_OF_TYPE = {
    [FIELD_TYPES.NUMBER]:[
        FILTER_OPERATORS.IS,
        FILTER_OPERATORS.IS_NOT,
        FILTER_OPERATORS.IS_ONE_OF,
        FILTER_OPERATORS.IS_NOT_ONE_OF,
        FILTER_OPERATORS.IN_RANGE,
        FILTER_OPERATORS.OUT_OF_RANGE,
    ],
    [FIELD_TYPES.STRING]:[
        FILTER_OPERATORS.IS,
        FILTER_OPERATORS.IS_NOT,
        FILTER_OPERATORS.IS_ONE_OF,
        FILTER_OPERATORS.IS_NOT_ONE_OF,
    ],
    [FIELD_TYPES.DATE]:[
        FILTER_OPERATORS.BEFORE,
        FILTER_OPERATORS.AFTER,
        FILTER_OPERATORS.IN_RANGE,
        FILTER_OPERATORS.OUT_OF_RANGE,
    ]
}


export const FILTER_EXCLUDE_OPERATOR = [
    FILTER_OPERATORS.IS_NOT_ONE_OF,
    FILTER_OPERATORS.IS_NOT,
    FILTER_OPERATORS.AFTER,
    FILTER_OPERATORS.OUT_OF_RANGE,
]

//取逻辑相反
export const FILTER_CONVERSE_OPERATOR = {
    [FILTER_OPERATORS.IS_NOT_ONE_OF]:FILTER_OPERATORS.IS_ONE_OF,
    [FILTER_OPERATORS.IS_ONE_OF]:FILTER_OPERATORS.IS_NOT_ONE_OF,
    [FILTER_OPERATORS.IS]:FILTER_OPERATORS.IS_NOT,
    [FILTER_OPERATORS.IS_NOT]:FILTER_OPERATORS.IS,
    [FILTER_OPERATORS.BEFORE]:FILTER_OPERATORS.AFTER,
    [FILTER_OPERATORS.AFTER]:FILTER_OPERATORS.BEFORE,
    [FILTER_OPERATORS.IN_RANGE]:FILTER_OPERATORS.OUT_OF_RANGE,
    [FILTER_OPERATORS.OUT_OF_RANGE]:FILTER_OPERATORS.IN_RANGE,
}

export const AGGREGATION_FILTER_OPERATORS = {
    EQUAL:'等于',
    NOT_EQUAL:'不等于',
    LESS_THAN:'小于',
    GREATER_THAN:'大于',
    LESS_THAN_OR_EQUAL_TO:'小于等于',
    GREATER_THAN_OR_EQUAL_TO:'大于等于',
    AFTER:'之后',
    BEFORE:'之前',
    IN_RANGE: '在范围内',
    OUT_OF_RANGE:'不在范围内'
}

export const AGGREGATION_FILTER_OPERATOR_SUPPORT = {
    [FIELD_TYPES.NUMBER]:[
        AGGREGATION_FILTER_OPERATORS.EQUAL,
        AGGREGATION_FILTER_OPERATORS.NOT_EQUAL,
        AGGREGATION_FILTER_OPERATORS.LESS_THAN,
        AGGREGATION_FILTER_OPERATORS.GREATER_THAN,
        AGGREGATION_FILTER_OPERATORS.LESS_THAN_OR_EQUAL_TO,
        AGGREGATION_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL_TO
    ],
    [FIELD_TYPES.DATE]:[
        AGGREGATION_FILTER_OPERATORS.AFTER,
        AGGREGATION_FILTER_OPERATORS.BEFORE,
        AGGREGATION_FILTER_OPERATORS.IN_RANGE,
        AGGREGATION_FILTER_OPERATORS.OUT_OF_RANGE,
    ],
    [FIELD_TYPES.STRING]:[
        AGGREGATION_FILTER_OPERATORS.EQUAL,
        AGGREGATION_FILTER_OPERATORS.NOT_EQUAL
    ],
}

export const ORDER_SORT = {
    DESCENDING:"降序",
    ASCENDING:"升序"
}

export const TIME_INTERVAL = {
    AUTO:{
        label:'自动',
        value:'1d'
    },
    MINUTE:{
        label:'分钟',
        value:'1m'
    },
    HOURLY:{
        label:'小时',
        value:'1h'
    },
    DAILY:{
        label:'天',
        value:'1d'
    },
    WEEKLY:{
        label:'周',
        value:'1w'
    },
    MONTHLY:{
        label:'月',
        value:'1M'
    },
    YEARLY:{
        label:'年',
        value:'1y'
    }
}

export const SETTING_ITEM_OPTIONS = {
    FIELD:'field',
    VALUES:'values',
    PERCENTS:'percents',
    MINIMUM_INTERVAL:'interval',
    ORDER_BY:'orderBy',
    ORDER_SORT:'orderSort',
    CUSTOM_LABEL:'customLabel',
    FIlTERS:'filters'
}

export const OPTIONAL_OPTIONS = [
    SETTING_ITEM_OPTIONS.CUSTOM_LABEL
]



export const DEFAULT_ORDER_BY_OPTIONS = [
    // {
    //     label: '首字母顺序排列',
    //     value: 'alphabetical',
    //     info:{
    //         aggregation:"key",
    //     }
    // },
    {
        label: '总数排列',
        value: 'alphabetical',
        info:{
            aggregation:"总数",

        }
    }
]

export const AGGREGATIONS_ORDER_LIST_PRODUCER = {
    [AGGREGATIONS.COUNT]:()=>{
        return [];
    },
    [AGGREGATIONS.PERCENTILE_RANKS]:(config)=>{
        let values = config[SETTING_ITEM_OPTIONS.VALUES];
        let field = config[SETTING_ITEM_OPTIONS.FIELD];
        if(!field) return [];
        return values.map(value => {
            return {
                label:`Metric : ${field}的第${value}百分位等级`,
                value:getUniqueId(AGGREGATIONS.PERCENTILES+field,value),
                info:{
                    value,
                    field:SETTING_ITEM_OPTIONS.VALUES,
                    aggregation:AGGREGATIONS.PERCENTILE_RANKS
                }
            }
        })
    },
    [AGGREGATIONS.PERCENTILES]:(config)=>{
        let percents = config[SETTING_ITEM_OPTIONS.PERCENTS];
        let field = config[SETTING_ITEM_OPTIONS.FIELD];
        if(!field) return [];
        return percents.map(percent => {
            return {
                label:`Metric : ${field}的${percent}百分位数`,
                value:getUniqueId(AGGREGATIONS.PERCENTILES+field,percent),
                info:{
                    percent,
                    field:SETTING_ITEM_OPTIONS.PERCENTS,
                    aggregation:AGGREGATIONS.PERCENTILES
                }
            }
        })
    },
    DEFAULT_PRODUCER:(config)=>{
        return [{
            label:`Metric : ${config[SETTING_ITEM_OPTIONS.FIELD]}的${config.aggregation}`,
            value:getUniqueId(config.aggregation,'d'),
            info:{
                aggregation: config.aggregation,
                field:config[SETTING_ITEM_OPTIONS.FIELD],
            }
        }];
    }
}



//控制面板结构
export const controlPanelOptionTree = {
    [AGGREGATIONS.COUNT]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
    ],
    [AGGREGATIONS.AVERAGE]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.MAX]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.MEDIAN]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.MIN]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.PERCENTILE_RANKS]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD,
        SETTING_ITEM_OPTIONS.VALUES
    ],
    [AGGREGATIONS.PERCENTILES]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD,
        SETTING_ITEM_OPTIONS.PERCENTS
    ],
    [AGGREGATIONS.SUM]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.STANDARD_DEVIATION]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.UNIQUE_COUNT]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD
    ],
    [AGGREGATIONS.DATE]:[
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
        SETTING_ITEM_OPTIONS.FIELD,
        SETTING_ITEM_OPTIONS.MINIMUM_INTERVAL,
    ],
    [AGGREGATIONS.FILTER]:[
        SETTING_ITEM_OPTIONS.FIlTERS,
    ],
    [AGGREGATIONS.TERMS]:[
        SETTING_ITEM_OPTIONS.FIELD,
        SETTING_ITEM_OPTIONS.ORDER_BY,
        SETTING_ITEM_OPTIONS.ORDER_SORT,
        SETTING_ITEM_OPTIONS.CUSTOM_LABEL,
    ]
}
