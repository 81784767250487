// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/notAnswer.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/notFinded.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/images/correct.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".answerBox .el-card__body {\n  padding: 5px !important;\n}\n.answerBox.is-always-shadow {\n  box-shadow: none !important;\n  border: none;\n}\n.hoverExchangeBgButton {\n  display: inline-block;\n  cursor: pointer;\n  margin-bottom: 7px;\n}\n.hoverExchangeBgButton:hover {\n  color: #409EFF;\n}\n.question-description {\n  white-space: pre-wrap;\n  margin-bottom: 7px;\n  margin-left: 6px;\n  color: lightslategray;\n  border-left: solid #409EFF 3px;\n}\n.organiaztion-answer-input input {\n  padding-right: 40px;\n}\n.icon-organization-answer {\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: 100%;\n  width: 24px;\n  height: 24px;\n  float: right;\n  top: -32px;\n  right: 10px;\n  position: relative;\n}\n.icon-not-answer {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.icon-not-finded {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.icon-correct {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}", ""]);
// Exports
module.exports = exports;
