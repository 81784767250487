import config from "../../config";

export const getDownloadUrlByAnswerSheetId = function (fileId,answerSheetId){
    return `${config.baseUrl}template-file/${fileId}/template-answer-sheet/${answerSheetId}/download-docx-from-pro-qa`
}

export const getDownloadUrl = function (fileId){
    return `${config.baseUrl}file/${fileId}/download-docx`
}

export const dateFormat = function(dataStr){
    if (!dataStr) {
        return '';
    }
    const dateMat= new Date(dataStr);
    const year = dateMat.getFullYear();
    const month = autoAddZero(dateMat.getMonth() + 1);
    const day = autoAddZero(dateMat.getDate());
    const hh = autoAddZero(dateMat.getHours());
    const mm = autoAddZero(dateMat.getMinutes());
    const ss = autoAddZero(dateMat.getSeconds());
    return year + "/" + month + "/" + day + " " + hh + ":" + mm + ":" + ss;
}
export const autoAddZero = function(a){
    if (a < 10) {
        return '0' + a;
    }
    return a
}
