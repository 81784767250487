import  {logToES} from "./tagRequest";
import {colorLog, getCookieValue} from "../../../util";
import config from "../../../config";

/**
 * 定义全局点击调用函数(主动埋点)
 *
 * CooperationEditor.vue 可不传递doc_id ；非CooperationEditor.vue若有doc_id，需要主动传递
 *
 * @param eventName 事件名称 必须传
 * @param eventValue 调用事件时的参数 可不传
 */
export  function globalTagClick (eventName,eventValue) {
  let docId=null
  //这几个是index页面的doc docId只能从外部传进来,需要特殊处理
  if (eventName==="printDoc"||eventName==="downloadDoc"||eventName==="deleteDocCancel"||eventName==="deleteDocSuccess"){
    docId=eventValue.docId
  }else {
    docId=getDocIdByPageTagValue()
  }

  if (eventName==='loginByPhone'||eventName==='loginByAccount') {
    eventValue.uid = getCookieValue("uid")
  }
  logToES({event_name:eventName,text:JSON.stringify(eventValue),project_name:config.esProjectName,value:1,doc_id:docId})
}

/**
 * 定义全局点击监听函数(被动埋点)
 */
export function globalMonitorClick(){
    document.getElementById('app').onclick = function (event) {
        globalMonitorFunc(event)
    };
}

function globalMonitorFunc (event,onLineParamKey){
  let onLineKey=onLineParamKey==null?"otherPageOnLine":onLineParamKey
  let tagValue=getTagValueByPageTagValue()
  let docId=null
  if (tagValue&&onLineParamKey==null) {
    tagValue = JSON.parse(tagValue)
    //只有编辑页面才会统计极简模式和普通模式时间,其余均计入其他网页在线时间
    if (tagValue.page === 'cooperationEditor') {
      if (tagValue.isZenMode){
        onLineKey="simpleModeOnLine"
      } else {
        onLineKey="normalModeOnLine"
      }
      docId=tagValue.docId
    }
  }

  // let lastOnLineTime=sessionStorage.getItem(onLineKey)
  // let nowTime=new Date().getTime()
  // if (lastOnLineTime){
  //   let interval_time = nowTime - parseInt(lastOnLineTime)
  //   if (interval_time<60000) {
  //     //点击间隔时间没有超过一分钟，认为用户在线,更新时间差
  //       logToES({event_name: onLineKey, project_name: config.esProjectName, value:interval_time,doc_id:docId})
  //   }else {
  //     //点击间隔时间超过一分钟，认为用户不在线，从30s开始计时
  //     logToES({event_name: onLineKey, project_name: config.esProjectName, value: 30000,doc_id:docId})
  //   }
  // }else {
  //   //第一次记录
  //   logToES({event_name: onLineKey, project_name: config.esProjectName, value: 1000,doc_id:docId})
  // }
  // //重新设置在线时间
  // sessionStorage.setItem(onLineKey,nowTime.toString())

  //eventName有值的，若有页面tag_value有值，取docId
  if (event){
    let eventName = event.target.getAttribute("data-tag-id")
    if (eventName==null){
      eventName = event.target.id
    }
    if (eventName){
      let eventParams={event_name:eventName,project_name:config.esProjectName,value:1,doc_id:docId}
      let eventValue = event.target.getAttribute("data-tag-value")
      if (eventValue){
        eventParams["text"]=eventValue
      }
        logToES(eventParams)
    }
  }
}
/**
 * checker 自定义的事件
 */
window.addEventListener('resetH1HighLight', function (e) {
  logToES({event_name:e.type,project_name:config.esProjectName,value:1,doc_id: getDocIdByPageTagValue()})
})
window.addEventListener('resetH2HighLight', function (e) {
  logToES({event_name:e.type,project_name:config.esProjectName,value:1,doc_id: getDocIdByPageTagValue()})
})
window.addEventListener('resetHpHighLight', function (e) {
  logToES({event_name:e.type,project_name:config.esProjectName,value:1,doc_id: getDocIdByPageTagValue()})
})
window.addEventListener('zenModeClick', function (e) {
  logToES({event_name:e.type,project_name:config.esProjectName,value:1,doc_id: getDocIdByPageTagValue()})
})
window.addEventListener('autoFormatClick',function(e){
  logToES({event_name:e.type,project_name:config.esProjectName,value:1,doc_id: getDocIdByPageTagValue()})
})

window.addEventListener('ttsClick',function(e){
  logToES({event_name:e.type,project_name:config.esProjectName,value:1,doc_id: getDocIdByPageTagValue()})
})

function  getTagValueByPageTagValue(){
  let tagValue=document.getElementById('page-tag-value')
  tagValue=tagValue==null?tagValue:tagValue.innerHTML
  return tagValue
}

function  getDocIdByPageTagValue(){
  let tagValue=getTagValueByPageTagValue()
  let docId=null
  if (tagValue) {
    tagValue = JSON.parse(tagValue)
    //只有编辑页面才会统计极简模式和普通模式时间,其余均计入其他网页在线时间
    if (tagValue.page === 'cooperationEditor') {
      docId = tagValue.docId
    }
  }
  return docId
}
