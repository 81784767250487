<template>
  <div class="get-started" :class="{'is-hidden':isHidden}" ref="container" tabindex="5">
    <div class="get-started-content">
      <div class="content-wrap" style="display: inline-block;align-self: flex-end;">
        <el-tooltip placement="top" :disabled="isPopoverHidden">
          <template #content>
            <div class="feedback-area">
              <img src="../assets/images/wechat-service-other.jpg"/>
              <p style="color: whitesmoke;text-align: center">如有任何使用问题请联系秘塔客服</p>
            </div>
          </template>
          <div class="check-mark" :class="{'is-hidden':isHidden}" @click="toggleVisibility">
            <svg t="1646719925363" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1885" width="20" height="20">
              <path d="M578.064516 792.774194H445.935484v-165.161291c0-95.033806 40.23329-142.864516 137.083871-208.532645l7.498322-5.086968C658.861419 367.616 677.16129 345.154065 677.16129 297.290323A165.16129 165.16129 0 0 0 346.83871 297.290323H214.709677a297.290323 297.290323 0 1 1 594.580646 0c0 104.216774-43.272258 157.266581-144.582194 226.006709l-7.531355 5.12C593.754839 571.458065 578.064516 590.088258 578.064516 627.612903v165.161291z m-66.064516 231.225806a82.580645 82.580645 0 1 1 0-165.16129 82.580645 82.580645 0 0 1 0 165.16129z" p-id="1886" fill="#ffffff"></path>
            </svg>
          </div>
        </el-tooltip>
        <div :class="{'is-hidden':isDelayTextHidden}" class="delay-text">{{isHidden ? ''  : '移出'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedbackButton",
  data(){
    return {
      isHidden:false,
      isDelayTextHidden:false,
      isPopoverHidden:false
    }
  },
  methods:{
    toggleVisibility(){
      this.isHidden = !this.isHidden;
      if(this.isHidden){
        this.isPopoverHidden = true;
      }else{
        setTimeout(()=>{
          this.isPopoverHidden = this.isHidden;
        },100)
      }
      setTimeout(()=>{
        this.isDelayTextHidden = this.isHidden;
      },500)
    }
  }
}
</script>

<style scoped>
.get-started {
  right:28px;
  bottom:20px;
  position: fixed;
  z-index: 999999;
  transition: .2s bottom,.2s right;
  transition-timing-function: cubic-bezier(0.21, -0.1, 0.16, 1.41);
}
.get-started.is-hidden{
  right:-28px;
  bottom: -28px;
}
.get-started-content {
  display: flex;
  flex-direction: column-reverse;

  justify-content: flex-start;
}
.content-wrap{
  position:relative;
}
.delay-text{
  font-size: .875rem;
  height: fit-content;
  width:fit-content;
  position:absolute;
  margin: auto;
  inset: 0;
  color:white;
  visibility: hidden;
  opacity: 0;
  transition: .5s opacity,.5s visibility;
}
.content-wrap:hover .delay-text:not(.is-hidden){
  transition-delay: .5s;
  user-select: none;
  pointer-events: none;
  transition-property: opacity;
  visibility:visible;
  opacity: 1;
}

.feedback-area{
  text-align: center;
}
.content-wrap:hover .check-mark:not(.is-hidden){
  transition-delay: .5s;
  filter: brightness(60%);
}
.content-wrap:hover .check-mark.is-hidden{
  transform: scale(1.2);
}
.check-mark.is-hidden{
  transition-duration: .2s ;
  transition-property: transform;
  transition-timing-function: ease-out;
}
.feedback-area img{
  width: 200px;
  height: 200px;
  display: inline-block;
}
.feedback-area p{
  font-size: 1rem;
  margin: 5px 0 0 0;
}
.check-mark {

  border-radius: 120px;
  padding: 15px 15px 15px 15px;

  background: rgb(82, 128, 255);
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition-property: filter;
  transition-duration:.5s ;


}
.check-mark>svg{
  vertical-align: top;
}
</style>
