import {initTraceId, logToUranusEsRequest} from "../../api/api";
import {DEPLOY_TYPE} from "../../config";
import config from "../../config";

export const CUSTOM_EVENT={
    AQ_SHEET_GO_NEXT:{name:'aq_sheet_go_next_button_click',desc:'回答问题界面的答卷部分的下一步按钮点击事件'},
    AQ_MOUSE_MOVE:{name:'aq_mouse_move',desc:'回答问题界面鼠标移动'},
    AQ_MARKETING_DOWNLOAD:{name:'aq_marketing_download',desc:'推广文档的答卷部分的下载按钮点击事件'},
    AQ_PREVIEW_GO_NEXT:{name:'aq_preview_go_next',desc:'回答问题界面的预览文档部分的下一步按钮点击事件'},
    AQ_ANSWER_CONTENT_CHANGE:{name:'aq_answer_content_change',desc:'回答问题界面，问题答案发生改变'},
    AQ_SEND_EMAIL:{name:'aq_send_email',desc:'回答问题界面，获取邮件按钮点击'},
    AQ_SHOW_REGISTER_DIALOG: {name: 'aq_show_register_dialog', desc: '推广文档试用界面, 打开注册弹窗'},
    AQ_REGISTER_SUCCESS: {name: 'aq_register_success', desc: '推广文档试用界面, 注册成功回调, 隐藏注册弹窗'},
    AQ_SHOW_ADVERTISING_DIALOG: {name: 'aq_show_advertising_dialog', desc: '推广文档试用界面, 显示广告弹窗并开始后台下载文件'},
    AQ_ADVERTISING_DIALOG_TIME_OVER: {name: 'aq_advertising_dialog_time_over', desc: '推广文档试用界面, 广告显示结束'},
    AQ_BACKGROUND_DOWNLOADING_SUCCESS: {name: 'aq_background_downloading_success', desc: '推广文档试用界面, 后台下载文件成功'},
    AQ_BACKGROUND_DOWNLOADING_FAILED: {name: 'aq_background_downloading_failed', desc: '推广文档试用界面, 后台下载文件失败'},
    AQ_DOWNLOAD_LOCAL_FILE: {name: 'aq_download_local_file', desc: '推广文档试用界面, 流程结束，通过本地URL下载文件'},
    AQ_SYSTEM_ANSWER_SHEET_DATA_LOADED:{name: 'aq_system_answer_sheet_data_loaded', desc: '推广文档问题数据加载完成'},
    AQ_SYSTEM_DOC_DATA_LOADED:{name: 'aq_system_doc_data_loaded', desc: '推广文档文档内容数据加载完成'},
    DELETE_VARIABLE:{name: 'delete_variable', desc: '删除变量'},
}



export const globalESLogMonitorFunc = function (event) {
    if (event) {
        if (!(config.deployType === DEPLOY_TYPE.METAGO || config.deployType === DEPLOY_TYPE.RELEASE)) {
            return
        }
        let eventName = event.target.getAttribute("data-tag-id")
        if (eventName == null) {
            eventName = event.target.id
        }
        if (eventName) {
            let eventParams = {event_name: eventName, value: 1}
            let eventValue = event.target.getAttribute("data-tag-value")
            if (eventValue) {
                eventParams["text"] = eventValue
            }
            logToES(eventParams)
        }
    }
};
/**
 *
 * @param {{event_name,value,text}} _params
 */
export const logToES = function(_params) {
    try {
        // 如果traceid为空则请求uranus重新生成一个
        let traceid= getCookieValue('traceid')
        if (traceid === '') {
            if (config.deployType === DEPLOY_TYPE.TEST) {
                return;
            }
            initTraceId().finally(() => realLogToES(_params));
        }else {
            realLogToES(_params)
        }

    } catch (err) {
        console.error("log to es error", err);
    }
}
function realLogToES(_params) {
    let params = _params;
    let now = new Date();
    let UTCNow = new Date();
    UTCNow.setFullYear(now.getUTCFullYear())
    UTCNow.setMonth(now.getUTCMonth())
    UTCNow.setDate(now.getUTCDate())
    UTCNow.setHours(now.getUTCHours())
    UTCNow.setMinutes(now.getUTCMinutes())
    UTCNow.setSeconds(now.getUTCSeconds())
    let uid= getCookieValue('uid');
    let sid= getCookieValue('sid')
    let traceid= getCookieValue('traceid')
    params['traceid'] = traceid==null?"":traceid
    params['user_id'] = uid
    params['sid'] = sid
    params['project_name'] = config.esProjectName;
    params['timestamp'] = Math.floor(UTCNow / 1000)
    logToUranusEsRequest(params);
}
function getCookieValue(name) { /**获取cookie的值，根据cookie的键获取值**/
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        let point = cookieArr[i].indexOf("=");
        let cookiePair = [
            cookieArr[i].substring(0, point),
            cookieArr[i].substring(point + 1, cookieArr[i].length)
        ];
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return "";
}
