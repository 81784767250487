import axios from 'axios'
import config, {DEPLOY_TYPE} from "../config";
import qs from 'qs'
import { h } from 'vue';
import {colorLog, getCookie, isMobile} from "../util";
import {getMockChartData,getMockFields} from "../components/charts/js/mockDataAPI";
import {ElMessage} from "element-plus";

const defaultHeaders = {
    Accept: 'application/json, text/plain, */*; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest'
}

axios.defaults.timeout = 180000
// axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL;
axios.defaults.baseURL = config.baseUrl;
axios.defaults.withCredentials = true;

function formatPropMessage(msg,keyName){
    let contentChildren = [h('summary',keyName)];
    if(typeof msg[keyName] == 'object'){
        let obj = msg[keyName];
        for(let _key of Object.getOwnPropertyNames(obj)){
            contentChildren.push(
                h('p',[
                        h('span',{class:'mret-obj-keyname'},_key),
                        h('span',{class:'mret-obj-value'},typeof obj[_key] == 'object' ? JSON.stringify(obj[_key]) : obj[_key])
                    ]
                )
            );
        }
    }else{
        contentChildren.push(h('p',msg[keyName]));
    }
    return h('details',contentChildren);
}
function showBackEndMessage(title,desc,dataSet){
    let children = [];
        let detail;

        detail = h('h1',{class:'mret-title'},`${title}`);
        children.push(detail);
        detail = h('h2',{class:'mret-title'},`${desc}`);
        children.push(detail);

        for(let keyName in dataSet){
            if(keyName == 'status'){
                continue;
            }else{
                detail = formatPropMessage(dataSet,keyName);
            }
            children.push(detail);
        }

        ElMessage.error({message: h('div',{class:'mret-wrap'},children),duration:0,showClose:true,customClass:'meta-remote-error-tip'});
}

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    if(response.data.shouldShow && response.data.code !== 0){
        showBackEndMessage("MetaException : " + response.data.code,response.request.responseURL,response);
        return Promise.reject(response.data);
    }

    return response;
}, function (error) {
    if([DEPLOY_TYPE.TEST,DEPLOY_TYPE.TESTDEV,DEPLOY_TYPE.TESTCLM].includes(config.deployType) && localStorage.getItem("openErrorTip")){
        showBackEndMessage(error.response['status'],error.request.responseURL,error.response);
    }

    // 对响应错误做点什么
    return Promise.reject(error);
});

//给app的页面混合,加上app需要的header
export const requestHeaderMixin = {
    created(){
        if(isMobile()){
            appendAppHeader();
        }
    },
    unmounted(){
        removeAppHeader();
    },
    beforeRouteLeave(){
        removeAppHeader();
    }
}

export const appHeaders = {
    'metapro-windows':'app',
    'deviceType':'app'
}

export const appendAppHeader = (headers = appHeaders)=>{
    Object.assign(axios.defaults.headers.common, headers);
    return removeAppHeader;
};
export const removeAppHeader = ()=>{
    for(let keyName in appHeaders){
        delete axios.defaults.headers.common[keyName];
    }
};

// 设置默认头
Object.assign(axios.defaults.headers.common, defaultHeaders)
// // 设置axios请求拦截器
// axios.interceptors.request.use(
//     requestConfig => {
//         return requestConfig
//     },
//     err => {
//         console.log('axios.interceptors.request === err === ', err)
//         return Promise.reject(err)
//     }
// )

// axios.interceptors.response.use(
// response => {
//     let result = response.data
//     if (result.errCode === -100 || result.errCode === 401) {
//         return new Promise(() => {
//         })
//     } else {
//         return response
//     }
// },
// error => {
//     return Promise.reject(error) // 返回接口返回的错误信息
// })

const uranusBaseUrl = 'https://' + config.uranusDomain;

/**
 * 保存模板问题
 * @param {{id,title,answerName,answerType,showControlAnsId,showControlAnsVal,showControlAction,fileId}} question
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveTemplateQuestion = function (question) {
    return axios.post(`/template-file/${question.fileId}/question`, question)
};
/**
 * 删除模板问题
 * @param fileId
 * @param questionId
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteQuestion = function (fileId,questionId) {
    return axios.delete(`/template-file/${fileId}/template-question/${questionId}`)
}

/**
 * 根据fileId获取所有的模板问题
 * @param fileId 文件id
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAllTemplateQuestionByFileId = function (fileId) {
    return axios.get(`/template-file/${fileId}/question`)
};
/**
 * 获取该文件的模板问题信息，包括模板问题，和该文档所有问题组
 * @param fileId 文件id
 */
export const getTemplateQuestionInfoByFileId = function (fileId) {
    return axios.get(`/template-file/${fileId}/question-info`);
}
/**
 * 获取子问题
 * @param parentQuestionId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSubQuestions = function (parentQuestionId) {
    return axios.get(`/template-question/${parentQuestionId}/sub-question`);
}
/**
 * 获取该文件问题所有组
 * @param fileId
 * @param groupIds
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplateQuestionGroups = function (fileId, groupIds = []) {
    const param = { groupIds }
    return axios.post(`/template-file/${fileId}/filter-template-question-group`, param);
};

/**
 * 保存问题组
 * @param group
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveTemplateQuestionGroup = function (group) {
    return axios.post(`/template-file/${group.fileId}/template-question-group`, group)
};
/**
 * 删除问题组
 * @param fileId
 * @param groupId
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteTemplateQuestionGroup = function (fileId,groupId) {
    return axios.delete(`/template-file/${fileId}/template-question-group/${groupId}`)
};
/**
 * 根据fileId获取某个文档的答卷列表信息（包含这些答卷关联的所有的答案）
 * @param fileId 文件id
 * @param pageIndex 页码
 * @param pageSize 每页数据条数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplateAnswerSheetInfo = function (fileId, pageIndex, pageSize) {
    return axios.get(`/template-file/${fileId}/answer-sheet-complete-info`, {
        params: {
            pageIndex: pageIndex || 0,
            pageSize: pageSize || 10
        }
    })
};
/**
 * 根据fileId获取某个文档的答卷列表(不包含这些答卷关联的所有的答案，只有一些基本信息)
 * @param fileId 文件id
 * @param pageIndex 页码
 * @param pageSize 每页数据条数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplateAnswerSheets = function (fileId, pageIndex, pageSize) {
    return axios.get(`/template-file/${fileId}/answer-sheet-simple-info`, {
        params: {
            pageIndex: pageIndex || 0,
            pageSize: pageSize || 10
        }
    })
};


/**
 * 保存pro文件的标题
 * @param newMetaProFileTitle 新标题
 * @param metaProFileId 文件id
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveMetaProFileTitle = function (fileId, title) {
    return axios.patch(`/template-file/${fileId}`, {
        "title": title
    })
}
/**
 * 对pro文件进行纠错。
 * @param {{fileId,title,sourceContent}} param
 */
export const checkPro = function (param) {
    return axios.post("/checkPro", param)
}
/**
 * 获取该文件的默认答卷信息（包括答卷，和已答问题）
 * @param fileId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getDefaultAnswerSheetInfo = function (fileId) {
    return axios.get(`/template-file/${fileId}/default-answer-sheet-info`)
}
/**
 * 获取答卷信息
 * @param { fileId, showGroupIds, showAllGroup ,publicUserAuthToken} param
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAnswerSheetInfo = function (param) {
    let url = '/template-answer-sheet-info';

    return axios.post(url, param);
}
/**
 * 获取答卷的介绍信息
 * @param fileId
 * @param authType
 * @param publicUserAuthToken
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAnswerSheetIntroInfo = function (fileId, publicUserAuthToken=null) {
    let url = `/template-file/${fileId}/answer-sheet-intro-info`;
    if (publicUserAuthToken) {
        url += `?publicUserAuthToken=${publicUserAuthToken}`;
    }
    return axios.get(url)
};
/**
 * 保存某一个问题的答案
 * @param {{id,fileId,answerSheetId,questionId,answer}} param
 */
export const saveQuestionAnswer = function (param) {
    return axios.post(`/template-file/${param.fileId}/template-answer`, param)
}
/**
 * @param  fileId 文件id
 * @param {{answers,sourceType,email,publicUserAuthToken}} param 参数
 * 提交一张答卷
 */
export const submitAnswerSheet = function (fileId,param) {
    return axios.post(`/template-file/${fileId}/submit-answers`, param)
}
/**
 * 删除某份答卷
 * @param fileId
 * @param sheetId
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteAnswerSheet = function (fileId,sheetId) {
    return axios.delete(`/template-file/${fileId}/template-answer-sheet/${sheetId}`);
}
/**
 * 获取答卷分享的小程序二维码
 * @param shareKeyId  分享的id
 * @param width 宽度
 * @param isHyaline 背景色是否透明
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAnswerSheetShareMiniProQrCode = function (shareKeyId, width, isHyaline) {
    return axios.get(`/qrcode/answersheet?shareKeyId=${shareKeyId}&width=${width}&isHyaline=${isHyaline}`);
};


/**
 * 获取模板设置。
 * @param fileId
 * @param publicUserAuthToken 认证信息
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplateSetting = function (fileId,publicUserAuthToken=null) {
    let url = `/template-file/${fileId}/setting`;
    if (publicUserAuthToken) {
        url +=`?publicUserAuthToken=${publicUserAuthToken}`
    }
    return axios.get(url);
}

/**
 * 保存模板设置
 * @param setting
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveTemplateSetting = function (setting) {
    return axios.post(`/template-file/${setting.fileId}/setting`, setting);
}
/**
 * 获取文档数据
 * @param fileId
 * @param authType
 * @param publicUserAuthToken
 * @return {Promise<AxiosResponse<any>>}
 */
export const getMetaProData = function (fileId, publicUserAuthToken = null) {
    let url = `/template-file/${fileId}/pro-data`
    if (publicUserAuthToken != null) {
        url += `?publicUserAuthToken=${publicUserAuthToken}`;
    }
    return axios.get(url);
};

/**
 * 将模板文件从隐藏文件夹移动到可见文件夹
 * @param params {fileId: xxx}
 * @return {Promise<AxiosResponse<T>>}
 */
export const activeHiddenTemplateFile = (params) => {
    return axios.post('template/active', params);
};

/**
 * 检查用户是否登录
 * @return {Promise<AxiosResponse<T>>}
 */
export const checkLoginStatus = () => {
    return axios.get('/template/login/status');
};

/**
 * 下载系统模板文档生成的word文档
 * @param params
 */
export const downloadSystemTemplate = (params) => {
    return axios.post('/template/download', params, { responseType: "blob" });
};

export const downloadByAnswerSheetId = (fileId, answerSheetId,publicUserAuthToken=null) => {
    let url = `/template-file/${fileId}/template-answer-sheet/${answerSheetId}/download-docx-from-pro-qa`
    if (publicUserAuthToken) {
        url += `?publicUserAuthToken=${publicUserAuthToken}`;
    }
    return axios.get(url, { responseType: "blob" });
}

export const saveDocxByAnswerSheetId = (fileId, answerSheetId) => {
    return axios.get(`/template-file/${fileId}/template-answer-sheet/${answerSheetId}/save-docx-from-pro-qa`)
}

export const downloadFile = (fileId) => {
    return axios.get(`/download/${fileId}`, { responseType: "blob" })
}

/**
 * 验证邀请码
 * @params {invite_code: xxx}
 */
export const checkValidCode = (params) => {
    return axios.get(`/verify`, { params: params })
};

/**
 * 获取登录信息
 * 登陆接口 接受参数
 * @param source
 * @param userInfo
 * @param cb
 * @returns {Promise<AxiosResponse<any>>}
 */
export const requestLogin = (source, userInfo, cb) => {
    return axios.post(`/user?s=${source}`, userInfo).then(res => {
        cb({ user: res.data })
    }).catch(res => {
        if (res.data) {
            cb({ user: res.data })
        } else {
            cb({ user: { msg: '服务器繁忙，请稍后再试' } })
        }
    })
}

/**
 * 邮箱方式登录
 * @param {login_by=account, email, pwd}
 */
export const loginByAccount = (params) => {
    if (config.deployType === 'TEST') {
        params = { 'username': 'metasota', 'password': 'metasota123' }
        return axios.post(`/dummyUranusLogin`, params)
    } else {
        return axios.post(`/login`, params)
    }
}

/**
 * 手机验证码方式登录
 * @param {login_by=sms, phone, sms_code}
 */
export const loginByPhone = (params) => {
    if (config.deployType === 'TEST') {
        params = { 'username': 'metasota', 'password': 'metasota123' }
        // return axios.post(`/dummyUranusLogin`, params)
        return Promise.resolve({ data: { code: 0 } });
    } else {
        return axios.post(`/login`, params)
    }
};

/**
 *
 * 其他方式登录
 * @param params
 */
export const loginUranus = (params) => {
    return axios.post(`/login`, params)
}

/**
 * 校验用户输入的验证码跟服务端发送的是否一致
 * @params {type, content}
 */
export const checkPhoneCode = (params) => {
    return axios.get(`/verify`, { params: params })
};

/**
 * 根据用户手机号请求验证码短信
 * 验证手机号格式是否正确
 * 验证邮箱格式是否正确
 * @param type
 * @param params
 * @return {Promise<AxiosResponse<T>>}
 */
export const getPhoneCode = (type, params) => {
    return axios.post(`/verify?type=${type.type}`, params)
};

/**
 * 获取邀请码机制状态
 * @params {content: SYS-OPN00000000, type: invite_code}
 */
export const getValid = (params) => {
    return axios.get(`/verify`, { params: params })
};

/**
 * 获取组织信息
 * @param companyName
 */
export const getAiQiChaCompanyData = function (companyName, timestamp) {
    return axios.get(`/company-info?s=answerQuestion&names=${companyName}&timestamp=${timestamp}`);
};

/**
 * 获取公司信息，目前在概览处调用
 * @param companyName
 * @param timestamp
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCompanyInfo = function (companyName, timestamp) {
    return axios.get(`/company-info?names=${companyName}&timestamp=${timestamp}`);
};

/**
 * 获取最近填写过得组织信息
 */
export const getRecentOrganizations = function () {
    return axios.get(`/recent/organization`);
};
/**
 * 保存分享的信息
 * @param data 数据正文
 * @param type 数据类型，默认为0，表示邀请作答的信息
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveShareInfo = function (data, type = 0) {
    let url = '/share-info'
    if (type) {
        url += "?type=" + type;
    }
    return axios.post(url, data);
}

/**
 * 获取分享的信息
 */
export const getShareInfo = function (id) {
    return axios.get('/share-info?id=' + id)
}
/**
 * 导出文档到email
 * @param sheetId 答卷id
 * @param publicUserAuthToken 认证信息
 * @return {Promise<AxiosResponse<any>>}
 */
export const exportAnswerSheetToEmail = function (sheetId, publicUserAuthToken) {
    return axios.get(`/export-answer-sheet-to-email?sheetId=${sheetId}&publicUserAuthToken=${publicUserAuthToken}`);
}

/**
 * 第二种文档内容检查
 * @param fileId
 */
export const labelFileTag = function (fileId) {
    return axios.get(`/labelFileTag?fileId=${fileId}`);
}

/**
 * 第三种文档内容检查
 * @param fileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const exportSingleLabelFile = function (fileId) {
    return axios.get(`/exportSingleLabelFile?fileId=${fileId}`);
}

/**
 *
 * @param fileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkSingleFile = function (fileId) {
    return axios.post(`/checkSingleFile?fileId=${fileId}`)
}

/**
 * 获取用户信息 用于检查登录状态
 * @param uid
 * @param sid
 * @param accessKey
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMyInfo = () => {
    return axios.get(`/clm-user`)
}

/**
 * 获取当前登录用户信息
 * @params {uid}
 */
export const getUserInfo = (params) => {
    return axios.get(`/user/${params.uid}`)
}
/**
 * 生成一个短链接
 * @param pathUrl 预期是不包含域名的url
 * @return {Promise<AxiosResponse<any>>}
 */
export const generateShortUrl = (pathUrl) => {
    return axios.post('/shortUrl', { url: pathUrl })
}
/**
 * 判断是不是特殊账号
 * @return {Promise<AxiosResponse<any>>}
 */
export const isSmarter = () => {
    return axios.get('/smarter');
};


export const logToUranusEsRequest = (param) => {
    if (!config.noModel){
        return axios.post(`${config.uranusUrl}/admin/user/event_report`, param)
    }
};
/**
 * 当没有traceId的时候请求该接口，由后台生成一个traceId
 * @return {Promise<AxiosResponse<any>>}
 */
export const initTraceId = () => {
    return axios.get(`${config.uranusUrl}/search/hots?random_count=3&is_random=true`)
}

export const getTemplatePreviewDownloadStatus = function () {
    return axios.get(`/template/preview/download/status`);
}

export const getFileOwnerDownloadQuota = function (fileId) {
    return axios.get(`/download/quota/${fileId}`);
}

/**
 * 创建一个新手引导
 * @param fileId 文件id
 * @return {Promise<AxiosResponse<any>>}
 */
export const createGuide = (fileId) => {
    return axios.post(`guide/create`, { fileId: fileId })
}
/**
 * 修改新手引导的状态
 * @param id
 * @param status
 * @return {Promise<AxiosResponse<any>>}
 */
export const markGuide = (id, status) => {
    return axios.post('guide/markStatus', { id: id, guideStatus: status })
}
/**
 * 获取某个文档的新手引导信息
 * @param fileId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getGuideInfo = (fileId) => {
    return axios.get(`guide/info?fileId=${fileId}`)
}
/**
 * 获取团队模板库信息
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTeamTemplateBank = () => {
    return axios.get('/team-template-bank');
}

/**
 * 获取系统模板库信息
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSystemTemplateBank = () => {
    return axios.get('/system-template-bank');
}
/**
 * 获取模板的分享信息，从而进入答题界面
 * @param templateId
 * @return {Promise<AxiosResponse<any>>}
 */
export const createShareParam = (templateId) => {
    return axios.get(`/published-template/${templateId}/for-draft-contract`)
}
/**
 * 通过提交的
 * @param answers 答案
 * @param publishTemplateId 模板id
 * @param contractStatus 起草后合同的状态
 * @return {Promise<AxiosResponse<any>>}
 */
export const draftContractByAnswers = (answers, publishTemplateId) => {
    return axios.post(`/contract/upload-as-draft-by-template`, { answers: answers, publishTemplateId: publishTemplateId})
};
/**
 * 获取合同主体、附件信息、版本信息等信息。
 * @param contractId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getContractViewDetail = (contractId) => {
    return axios.get(`/contract/${contractId}/detail`);
}
/**
 * 更新合同信息
 * @param contractId
 * @param value
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateContractTitle = (contractId, value) => {
    return axios.patch(`/contract/${contractId}/title`, {value});
}

export const updateContractViewMode = (contractId, value) => {
    return axios.patch(`/contract/${contractId}/setting/viewMode`, {value});
}

/**
 * 获取团队所有的组织结构
 * @param teamId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTeamOrganizations = (teamId) => {
    return axios.get(`/team/${teamId}/organizations`)
}

/**
 * 获取团队的teamConfig
 * @param teamId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTeamInfo = (teamId)=>{
    return axios.get(`/team/${teamId}`);
}

/**
 * 创建组织
 * @param organization
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveOrganization = (organization) => {
    return axios.post(`/team/${organization.teamId}/organization`, organization)
}

/**
 * 更新组织
 * @param organization
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateOrganization = (organizationId,newOrganizationName) => {
    return axios.patch(`/organization/${organizationId}/name`, {value:newOrganizationName})
}

/**
 * 删除组织
 * @param organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteOrganization = (organizationId) => {
    return axios.delete(`/organization/${organizationId}`)
}

/**
 * 获取组织下的成员
 * @param organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationUser = (organizationId) => {
    return axios.get(`/organization/${organizationId}/user`)
}

/**
 * 组织结构添加成员
 * @param organizationId
 * @param uidList
 * @returns {Promise<AxiosResponse<any>>}
 */
export const organizationSaveUser = (organizationId, uidList) => {
    return axios.post(`/organization/${organizationId}/user`, {value : uidList})
}

/**
 * 获取团队所有的成员
 * @param teamId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTeamUser = (teamId) => {
    return axios.get(`/team/${teamId}/user`)
}

/**
 * 获取团队所有的成员
 * @param teamId
 * @param pageSize
 * @param pageIndex
 * @param filter 过滤参数
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTeamUserByPage = (teamId,pageSize=1,pageIndex=9999,filter="") => {
    return axios.get(filter!==""?`/team/${teamId}/user/search?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`:`/team/${teamId}/user/search?pageSize=${pageSize}&pageIndex=${pageIndex}`)
}

/**
 * 获取用户有权看到的模板
 * @param teamId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplates = () => {
    return axios.get(`/published-templates`)
}

/**
 * 发布模板文件
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
export const putTemplatePublish = function (params) {
    return axios.put(`team/${params.publishedTemplate.teamId}/published-template`, params);
};

/**
 * 修改模板文件
 * @param teamId
 * @return {Promise<AxiosResponse<any>>}
 */
export const updatePublishedTemplate = function (publishedTemplateId, params) {
    return axios.patch(`/published-template/${publishedTemplateId}`, params);
};

/**
 * 获取模板相关信息
 * @param publishedTemplateId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplateSettings = (publishedTemplateId) => {
    return axios.get(`/published-template/${publishedTemplateId}/settings`)
}

/**
 * 模板发布审核通过
 * @param teamId
 * @param publishedTemplateId
 * @return {Promise<AxiosResponse<any>>}
 */
export const passPublishedTemplate = function (publishedTemplateId) {
    return axios.post(`/published-template/${publishedTemplateId}/approval-passed`);
};

/**
 * 模板发布审核不通过
 * @param teamId
 * @param publishedTemplateId
 * @return {Promise<AxiosResponse<any>>}
 */
export const rejectPublishedTemplate = function (publishedTemplateId, params) {
    return axios.post(`/published-template/${publishedTemplateId}/approval-rejection`, params);
};

/**
 * 重新发布模板
 * @param publishedTemplateId
 * @return {Promise<AxiosResponse<any>>}
 */
export const rePublishPublishedTemplate = function (publishedTemplateId) {
    return axios.post(`/published-template/${publishedTemplateId}/re-publish`);
};


/**
 * 获取文件的模板
 * @param fileId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getTemplateFileMeta = (fileId) => {
    return axios.get(`/template-file/${fileId}`)
}


/**
 * 获取已发布文件的审批信息
 * @param fileId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getPublishTemplateFileApprovalInfo = (fileId) => {
    return axios.get(`/template-file/${fileId}`)
}
/**
 *
 * @param teamId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTeamPublishedTemplateCategories = (teamId) => {
    return axios.get(`team/${teamId}/published-template-categories`)
}

/**
 *
 * @param category
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addPublishedTemplateCategory = (category) => {
    return axios.post(`/team/${category.teamId}/published-template-category`, category)
}

/**
 *
 * @param category
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updatePublishedTemplateCategory = (category) => {
    return axios.put(`/team/${category.teamId}/published-template-category/${category.id}`, category)
}
/**
 *
 * @param teamId
 * @param categoryId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deletePublishedTemplateCategory = (teamId,categoryId) => {
    return axios.delete(`/team/${teamId}/published-template-category/${categoryId}`)
}

/**
 *
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractVersions = (contractId) => {
    return axios.get(`contract/${contractId}/contract-version`)
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContracts = (filter, index, size) => {
    return axios.get(`contracts`, { params: { filter: filter, pageIndex: index, pageSize: size } })
}

/**
 * 获取合同的编辑内容
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractEditContent = (contractId) => {
    return axios.post(`/contract/${contractId}/edit`)
}


export const getContractPreviewStatus = (contractId)=>{
    return axios.get(`/contract/${contractId}/preview-status`)
}

export const getContractVersionPreviewStatus = (versionId)=>{
    return axios.get(`/contract-version/${versionId}/preview-status`)
}


export const approveContract = (contractId, status) => {
    return axios.patch(`/contract/${contractId}/approval`, status)
}

/**
 * 获取合同的审批流程和查看权限
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractApprovalProcessAndPermission = (contractId) => {
    return axios.get(`/contract/${contractId}/process-permission-setting`)
}

/**
 * 设置/修改合同的审批流程
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveOrUpdateContractApprovalProcess = (contractId, metaApprovalStageDTOList) => {
    return axios.post(`/contract/${contractId}/approval-process`, metaApprovalStageDTOList)
}

/**
 * 设置/修改合同的权限
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveOrUpdateContractPermissionAccess = (contractId, entityPermissionAccessList) => {
    return axios.post(`/contract/${contractId}/permission-access`, entityPermissionAccessList)
}

/**
 * 获取合同附件信息
 * @param contractId
 * @param pageIndex
 * @param pageSize
 * @return {Promise<AxiosResponse<any>>}
 */
export const getContractAttachments = (contractId, pageIndex = 0, pageSize = 10) => {
    return axios.get(`contract/${contractId}/attachments?pageIndex=${pageIndex}&pageSize=${pageSize}`);
};

/**
 * 删除合同附件
 * @param contractId
 * @param attachmentId
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteContractAttachment = (contractId, attachmentId) => {
    return axios.delete(`contract/${contractId}/attachment/${attachmentId}`);
};

/**
 * 修改模板文件的权限
 * @param teamId
 * @param publishedTemplateId
 * @param entityPermissionAccessList
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateTemplatePublishPermissionAccess = (publishedTemplateId, entityPermissionAccessList) => {
    return axios.post(`/published-template/${publishedTemplateId}/permission-access`, entityPermissionAccessList)
}

export const launchApprovalProcess = (contractId, req) => {
    return axios.post(`/contract/${contractId}/launch-approval-process`, req)
}

export const getContractApprovalProcess = (contractId) => {
    return axios.get(`/contract/${contractId}/approval-process`)
}

/**
 * 保存新版本
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveNewVersion = (contractId) => {
    return axios.post(`/contract/${contractId}/contract-version`)
}
/**
 * 获取合同仪表盘统计信息
 * @return {Promise<AxiosResponse<any>>}
 */
export const getContractDashboardData = () => {
    return axios.get('/contract/dashboard-data')
}
export const getProFileList = (index, size) => {
    return axios.get('/templates', { params: { pageIndex: index, pageSize: size } })
}

/**
 * 获取邀请加密链接
 * @param organization
 * @returns {Promise<AxiosResponse<any>>}
 */
export const organizationInviteMember = (teamId,oId,url) => {
    return axios.get(`/team/${teamId}/url`, {params:{organizationId: oId,url:url}})
}

/**
 * 获取主页资源列表
 * @param index
 * @param size
 * @param filter 过滤
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getResourceList = (params) => {
    return axios.get('/resource', { params })
}
/**
 * 获取某个合同版本的信息
 * @param contractId
 * @param versionId
 */
export const getContractVersion = ( versionId) => {
    return axios.get(`/contract-version/${versionId}`)
};
/**
 * 对contract的两个版本进行diff
 * @param contractId 合同id
 * @param originVersionId 原版本（用作比较的基石）
 * @param diffVersionId 对比版本（看相对于原版本发生了什么变化）
 * @return {Promise<AxiosResponse<any>>}
 */
export const diffContractVersion = (originVersionId, diffVersionId) => {
    return axios.post(`/contract-version/diff`, {
        originVersionId: originVersionId,
        diffVersionId: diffVersionId
    })
};

/**
 * 创建团队
 * @param name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createTeam = (name,industry)=>{
    return axios.post(`/clm-teams`, {name: name,industry:industry})
}
/**
 * pdf前端获取到的内容和后端获取到的内容是不一致的，所以需要做一次转换
 * @param  param {{originVersionId,originPdfContent,diffVersionId,diffPdfContent, alerts}}
 * @return {Promise<AxiosResponse<any>>}
 */
export const convertContentIndexToPdfIndex = (param) => {
    return axios.post(`/contract-version/diff/pdf-index`, param)
};



/**
 * 团队级签署策略开关
 * @param teamId
 * @param value 是否允许在发起签署是不上传新文件 true:允许不上传 false:不允许
 * @returns {Promise<AxiosResponse<any>>}
 */
export const setFinalizedNeedNotCommitNewVersion = (teamId, value) => {
    return axios.post(`/team/${teamId}/finalized-need-not-commit-new-version`, { value: value })
}

/**
 * 移除tag
 * @param contractId
 * @param tagId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const removeContractTag = (contractId, tagId) => {
    return axios.delete(`/contract/${contractId}/contract-tag/${tagId}`)
}

/**
 * 添加tag
 * @param contractId
 * @param tagName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addContractTag = (contractId, tagName, tagType) => {
    return axios.post(`/contract/${contractId}/contract-tag`, { tagName: tagName, tagType })
}
/**
 * 设置为已签署
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const finalizedContract = (contractId) => {
    return axios.patch(`/contract/${contractId}/status/finalized`)
}

/**
 * 回退到某个版本
 * @param contractId
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export const revertContract = (contractId) => {
    return axios.patch(`/contract/${contractId}/revert-to-draft`)
}

/**
 * 上传新版后检测diff
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const diffContractUploadedVersion = (contractId) => {
    return axios.post(`/contract/${contractId}/contract-version/diff-latest`)
}

/**
 * 获取默认审批者和权限
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDefaultTemplateSetting = () => {
    return axios.get('/published-template/default-setting')
}

/**
 * 设置为定稿
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const draftFinalized = (contractId) => {
    return axios.patch(`/contract/${contractId}/status/draft-finalized`)
}
/**
 * 获取组织邀请二维码
 * @param teamId
 * @param organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationInviteMemberQRCode = (teamId,organizationId) => {
    return axios.get(`/team/${teamId}/qrcode`,{params:{id:organizationId}})
}

/**
 * 下载组织邀请二维码
 * @param teamId
 * @param organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const downloadOrganizationInviteMemberQRCode = (teamId,organizationId) => {
    return axios.get(`/team/${teamId}/organization-invite-member/qrcode/download`, { responseType: "blob",params:{id:organizationId} })
}

export const getAllReviewersAndProcessors = () => {
    return axios.get('/reviewers-and-processors')
}

export const cypherReview = (contract) => {
    return axios.post('/cypher-review', contract)
}

export const getDataDictionary = (teamId) => {
    return axios.get(`/team/${teamId}/data-dictionary`)
}
export const saveDataDictionary = (teamId, data) => {
    return axios.patch(`/team/${teamId}/data-dictionary`, { value: data })
}

export const revertToOldVersion = (contractId, contractVersionId) => {
    return axios.post(`/contract/${contractId}/contract-version/${contractVersionId}/revert`);
}

/**
 * 获取合同概览关系提取是否提取完成的状态
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractRelationExtractStatus = (contractId) => {
    return axios.get(`/contract/${contractId}/pipeline-status`)
}

/**
 * 获取合同概览
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractRelation = (contractId) => {
    return axios.get(`/contract/${contractId}/overview`)
}

/**
 * 提交内容修正合同概览
 * @param contractId
 * @param content
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postContractRelation = (contractId,versionId, content) => {
    return axios.post(`/contract/${contractId}/overview`, { versionId:versionId,content: content })
}

/**
 * 搜索合同或文件
 * @param searchKey
 * @param searchTime
 * @param searchTypes
 * @param searchStatuses
 * @param normalTagNames
 * @param pendingTagNames
 * @param otherParties
 * @param sortName
 * @param sortDirection
 * @returns {Promise<AxiosResponse<any>>}
 */
export const searchContractsAndFiles = (searchKey, searchTime, searchTypes, searchStatuses, normalTagNames, pendingTagNames, otherParties, sortName, sortDirection) => {
    return axios.get(`/contract-file`,
        {
            params: {
                key: searchKey,
                t: searchTime,
                types: searchTypes,
                statuses: searchStatuses,
                normalTags: normalTagNames,
                pendingTags: pendingTagNames,
                parties: otherParties,
                sortName: sortName,
                sortDirection: sortDirection,
            }
        })
}

/**
 * 获取CLM首页dashboard所需数据
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractStatisticsData = () => {
    return axios.get(`/resource/statistics`)
}

/**
 * 获取任务
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractTasks = (pageIndex,pageSize) => {
    return axios.get(`/resource/tasks`,{params:{pageIndex:pageIndex,pageSize:pageSize}})
}

export const cancelContract = (ids,reason) => {
    return axios.post('/contract/cancel', { ids: ids,reason:reason })
}

export const deleteResources = (resources, completely) => {
    return axios.delete('/resource/batch', { data: { resources: resources, completely: completely } })
}

export const createBlankTemplate = () => {
    return axios.post('/template/blank')
}

export const createBlankContract = (status, metaApprovalProcessId,associatedContractId) => {
    return axios.post('/contract/upload-as-draft-by-blank', { status: status, metaApprovalProcessId: metaApprovalProcessId,associatedContractId: associatedContractId})
}
/**
 * 从pdf的某个位置创建评论
 * @param contractId
 * @param fileId
 * @param markParam
 * @return {Promise<AxiosResponse<any>>}
 */
export const createCommentsFromPdf = (versionId, markParam) => {
    return axios.post(`/contract-version/${versionId}/comment`, markParam);
};
/**
 * 在某个评论下追加评论
 * @param versionId
 * @param {{markId,commentText,wordStart,wordEnd,start,end}} appendComment
 * @return {Promise<AxiosResponse<any>>}
 */
export const appendCommentsFromPdf = (versionId,  appendComment) => {
    return axios.post(`/contract-version/${versionId}/reply-comment`, appendComment);
};
/**
 * 获取pdf下的所有comment
 * @param versionId
 * @param pdfContent
 * @param versionNum
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAllPdfComment = (versionId, pdfContent, version) => {
    return axios.post(`/contract-version/${versionId}/comments`, { pdfContent, version });
};
/**
 * 删除某一条评论
 * @param versionId
 * @param id 评论id
 * @return {Promise<AxiosResponse<any>>}
 */
export const deletePdfComments = (versionId, id) => {
    return axios.delete(`/contract-version/${versionId}/comment?id=${id}`);
};
/**
 * 解决某一组评论
 * @param versionId
 * @param markId
 * @return {Promise<AxiosResponse<any>>}
 */
export const solvePdfComments = (versionId, markId) => {
    return axios.patch(`/contract-version/${versionId}/solve-comment?markId=${markId}`);
};
/**
 * 查看预览的文件的版本号，普通文件是0，只有协作版的pro文件有自己的版本号。
 * @param contractId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getPreviewFileVersionNum = (versionId) => {
    return axios.get(`/contract-version/${versionId}/version-num`)
};



/**
 * 批量上传
 * @param formData
 * @returns {Promise<AxiosResponse<any>>}
 */
export const bulkUpload = (formData) => {
    return axios.post('/contract/bulk-upload', formData, {
        'Content-Type': 'multipart/form-data'
    })
};

/**
 * 获取批量上传列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBulkUploads = () => {
    return axios.get(`/contract/bulk-upload`)
}

/**
 * 获取批量上传详细信息
 * @param bulkUploadId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBulkUpload = (bulkUploadId, statuses) => {
    return axios.get(`/contract/bulk-upload/${bulkUploadId}`, {
        params: {
            statuses: statuses
        }
    })
}



export const getContractSharedList = (contractId) => {
    return axios.get(`/contract/${contractId}/shared-list`)
}

export const updateContractShare= (item)=>{
    return axios.post(`/contract/${item.contractId}/contract-share-user/${item.id}`,item)
}

export const updateContractShareStatus = (item)=>{
    return axios.put(`/contract/${item.contractId}/contract-share-user/${item.id}/status`,{status:item.status})
}

export const deleteContractShareUser = (contractId,shareId) => {
    return axios.delete(`/contract/${contractId}/contract-share-user/${shareId}`)
}

export const resendShareEmail = (contractId,id, url, msg) => {
    return axios.post(`/contract/${contractId}/contract-share-user/${id}/resend`, { url: url, msg: msg })
}

export const getTeamMetaApprovalProcessDataList = (pageIndex,pageSize) => {
    return axios.get(`/meta-approval-process-data-list`,{params:{pageIndex:pageIndex,pageSize:pageSize}})
}
export const getTeamMetaApprovalProcesses = () => {
    return axios.get(`/meta-approval-processes`)
}
export const getMetaApprovalProcess = (id) => {
    return axios.get(`/meta-approval-process/${id}`)
}

/**
 * 新增审批流
 * @param {{process,approvalStages,stageOrders,calendarManagers}} data
 * @return {Promise<AxiosResponse<any>>}
 */
export const saveTeamMetaApprovalProcess = ( data) => {
    return axios.post(`/meta-approval-process`, data)
}
/**
 * 修改审批流
 * @param {{process,approvalStages,stageOrders,calendarManagers}} data
 * @return {Promise<AxiosResponse<any>>}
 */
export const updateTeamMetaApprovalProcess = (data) => {
    return axios.put(`/meta-approval-process/${data.approvalProcess.id}`, data)
}

export const getContractApprovalDetail = (contractId) => {
    return axios.get(`/contract/${contractId}/approval/detail`)
}

export const deleteMetaApprovalProcessById = (id) => {
    return axios.delete(`/meta-approval-process/${id}`)
};

export const deleteMetaApprovalProcessByIds = (ids) => {
    return axios.delete(`/meta-approval-process/delete`,{data : ids})
};
/**
 * 记录概览的修改
 * @param contractId
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const recordOverviewModify = (contractId,data) => {
    return axios.post(`contract/${contractId}/overview-modify-log`, data)
}

export const addOverviewModifyAuditing = (contractId) => {
    return axios.post(`contract/${contractId}/overview-modify-log/auditing`)
}

export const getInviteList = () => {
    return axios.get(`invite-list`);
}

export const inviteUser = (teamId, data) => {
    return axios.post(`/team/${teamId}/organization-invite-user`, data)
}

export const removeUser = (teamId, userId,recipientUid) => {
    return axios.delete(`/team/${teamId}/user`, {data : {uid : userId , recipient : recipientUid}})
}

/**
 *
 * @param fileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWaitForCalendarContracts = () => {
    return axios.get(`/contract/wait-for-contract-date`)
};

/**
 * 设置合同日历
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const setContractCalendar = (contractId, contractDateDetails) => {
    return axios.post(`/contract/${contractId}/contract-date/batch`, contractDateDetails)
}

/**
 * 获取合同设置的日期事件
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractDates = (params) => {
    // return axios.get(`/contract-dates`, {params, paramsSerializer: (params) => {return params.tagIds.map(item => `tagIds=${item}`).join("&");}})
    return axios.get(`/contract-date`, {params, paramsSerializer: (params) => {return qs.stringify(params, { indices: false })}})
};

export const getActivity = (pageIndex, pageSize) => {
    return axios.get(`/activity`,{params : {pageIndex:pageIndex , pageSize: pageSize}})
};

export const getMessages = (param) => {
    return axios.get(`/message`,{params : param})
};

export const getAuditing = (contractId) => {
    return axios.get(`/contract/${contractId}/auditing`)
};

export const getUnCheckMessagesCount = () => {
    return axios.get(`/message/unchecked-count`)
};

export const getAllTeamUnCheckMessagesCount = () => {
    return axios.get(`/message/all-unchecked-count`)
};

export const updateMessagesStatusToChecked = (id) => {
    return axios.patch(`/message/${id}/checked`)
};

// export const updateMessagesStatusToUnchecked = (id) => {
//     return axios.put(`/message/${id}/unchecked`)
// };

export const updateAllMessagesStatusToChecked = () => {
    return axios.put(`/message/set-all-unchecked`)
};

export const getAssociatedContract = (contractId)=>{
    return axios.get(`/contract/${contractId}/associated`)
}

export const saveAssociatedContract = (contractId,associatedContractId)=>{
    return axios.post(`/contract/${contractId}/contract-association`,{value : associatedContractId})
}

export const removeAssociatedContract = (contractId,associatedContractId)=>{
    return axios.delete(`/contract/${contractId}/contract-association`,{data :{value : associatedContractId}})
}

export const shareContract = (contractId, data) => {
    return axios.post(`/contract/${contractId}/share`, data)
}

export const deleteContractDate = (contractDateId) => {
    return axios.delete(`/contract-date/${contractDateId}`)
};

export const addContractDate = (contractId, data) => {
    return axios.post(`/contract/${contractId}/contract-date`, data)
}

export const updateContractDate = (contractDateId, data) => {
    return axios.patch(`/contract-date/${contractDateId}`, data)
}

export const getOrganizationsInviteInfo = (inviteKey)=>{
    return axios.get(`/organization-invite-info`,{params:{inviteKey: inviteKey}})
}

export const getIndustry = ()=>{
    return axios.get('/industry');
}

export const createTeamConfig = (params) => {
    return axios.post(`/team`, params)
}

export const updateTeamConfig = (params) => {
    return axios.patch(`/team/${params.teamId}`, params)
}

export const getAllTeamInfos = () => {
    return axios.get(`/team`)
}

/**
 * 获取用户名称
 * @params {uid}
 */
export const getUserName = (uid) => {
    return axios.get(`/clm-user/${uid}/name`);
}

export const switchCurrentTeam = (teamId) => {
    return axios.patch(`/clm-user/team/`,{value : teamId});
}

export const setContractDateStatus = (contractDateId, data)=>{
    return axios.patch(`/contract-date/${contractDateId}/status`, data)
}

export const getContractsShareWithMe = ()=>{
    return axios.get('/contract-share/shared-contracts')
}

export const getContractVersionDetail = (versionCode)=>{
    return axios.get(`/contract-version/${versionCode}/detail`);
}

export const getTags = ()=>{
    return axios.get('/tag')
}

export const checkTimeText = (timeText)=>{
    return axios.get('/check-time-text', { params: { text: timeText } })
}

/**
 * 检查|计算 概览时间
 * @param signDate
 * @param validFrom
 * @param validTo
 * @param validLast
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkOverviewTime = (signDate, validFrom, validTo, validLast)=>{
    return axios.get('/check-or-calculate-overview-time',
        { params: {
                signDate: signDate,
                validFrom: validFrom,
                validTo: validTo,
                validLast: validLast,
        } })
}

export const getContractCalendarCandidates = (contractId)=>{
    return axios.get(`/contract/${contractId}/contract-date/candidate`)
}


export const restoreResource=(data)=>{
    return axios.post(`/resource/restore`,data)
}

export const getContractHistorySharedList = (versionId)=>{
    return axios.get(`/contract-version/${versionId}/history-shared-list`)
}

export const getPdfOcrStatus = (contractId)=>{
    return axios.get(`/contract/${contractId}/pdf-ocr-status`)
}

export const getRecentShares = ()=>{
    return axios.get('/contract-share/recent-share')
}

/**
 * 获取合同标签
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContractTagsById = (contractId) => {
    return axios.get(`/contract/${contractId}/contract-tag`)
}

/**
 * 获取当前团队所有角色信息
 * @param teamId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllRoles = () => {
    return axios.get(`/role`)
}

/**
 * 获取所有可配置的角色权限
 * @param teamId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllPermissions = () => {
    return axios.get(`/permission`)
}

/**
 * 获取角色信息
 * @param roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRoleDetail = (roleId) => {
    return axios.get(`/role/${roleId}`)
}

/**
 * 获取角色详情信息(拥有该角色的用户和该角色拥有哪些权限)
 * @param roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveRole = (data) => {
    return axios.post(`/role/detail`,data)
}

/**
 * 设置该团队的默认role
 * @param teamId和roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateDefaultRoleId = (roleId, teamId) => {
    return axios.patch(`/team/${teamId}/default-roleId`,{value : roleId})
}

/**
 * 删除自定义的role
 * @param roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteRole = (roleId) => {
    return axios.delete(`/role/${roleId}`)
}

/**
 *
 * @param publishedTemplateId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deletePublishedTemplate = (publishedTemplateId) => {
    return axios.delete(`/published-template/${publishedTemplateId}`)
}

/**
 *
 * @param fileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteTemplateFile = (fileId) => {
    return axios.delete(`/template-file/${fileId}`)
}

/**
 * @param fileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTemplateAuditing = (fileId) => {
    return axios.get(`/template-file/${fileId}/auditing`)
}

/**
 * 登出
 * @returns {Promise<AxiosResponse<any>>}
 */
export const clmLogOut = () => {
    return axios.post("/clm-logout",null,{responseType:"json"});
}

/**
 * 登出
 * @returns {Promise<AxiosResponse<any>>}
 */
export const logOut = () => {
    return axios.post("/logout",null,{responseType:"json"});
}

/**
 * 设置用户信息
 */
export const updateUserNickName = (userName) => {
    return axios.patch(`/clm-user/name`,{value : userName})
}

export const getEmailCode = (type, params) => {
    return axios.post(`/verify?type=${type.type}`, params)
}
export const checkEmailCode = (params) => {
    return axios.get(`/verify`, { params: params })
}

/**
 * 修改新用户信息
 * @params params
 */
export const updateUserInfo = (params) => {
    return axios.patch(`/user/${params.uid}`, params)
}

/**
 * 复制模板文件到草稿箱
 * @param publishedTemplateId
 * @return {Promise<AxiosResponse<any>>}
 */
export const copyTemplateFileToDraftBox = (publishedTemplateId) =>{
    return axios.post(`/published-template/${publishedTemplateId}/copy-to-draft-box`)
}

/**
 * 上传帖子中的图片
 * @params {imgdata}
 */
export const uploadImg = (params) => {
    const sid = getCookie('sid');
    const timestamp = Date.now();
    return axios.post('/uranus/upload', params,{params:{sid, timestamp}})
}

export const getQRCode = () => {
    return axios.get('/wx_metago_public_qr/metago');
}

export const getSharePermissions = (contractId)=>{
    return axios.get(`/contract/${contractId}/share-permissions`);
}

export const batchSetContractDateStatus = (params) => {
    return axios.post(`/contract-dates/set-status`, params)
}

export const visualizationSearch = (param)=>{
    return axios.post('/visualization-search',param)
}

export const getElasticTableInfo = (tableName)=>{
    return axios.get(`/visualization-info/${tableName}`)
}

export const getVisualization= (id)=>{
    return axios.get(`/visualization/${id}`)
}

export const updateVisualization= (id,param)=>{
    return axios.post(`/visualization/${id}`,param)
}

export const saveVisualization= (data)=>{
    return axios.post(`/visualization`,data)
}

export const getTeamVisualization = (teamId)=>{
    return axios.get(`/team/${teamId}/visualizations`)
}

export const getVisualizationTermsData = (fieldName)=>{
    return axios.get(`/visualization-data/filed/${fieldName}`)
}
/**
 * 获取图表数据
 */
export const getChartData = (params) => {
    return getMockChartData(params);
}
export const getChartFields = (params) => {
    return getMockFields(params);
}

export const getLaunchedByMe = ()=>{
    return axios.get(`/resource/launched-by-me`);
}
export const acceptSignedDiff = (contractId, data) => {
    return axios.post(`/contract/${contractId}/accept-signed-diff`,data)
}

export const cancelSignedDiff = (contractId, data) => {
    return axios.post(`/contract/${contractId}/cancel-signed-diff`,data)
}
export const createContractStartEditEvent = (contractId)=>{
    return axios.post(`/contract/${contractId}/event/start-edit`)
}

export const contractCheckFeedBack = (param)=>{
    return axios.post('/bt/feed-back',param)
}

/**
 * 合同检查。
 * @param contractId 合同id
 * @param param {checkMode,content,forceCheck,settingJSONString} 文本内容
 * @return {Promise<unknown>}
 */
export  const  checkContract = (contractId,param)=>{
    return axios.put(`/contract/${contractId}/check`,param);
}

export const getContractSetting = (contractId) => {
    return axios.get(`/contract/${contractId}/setting`)
}

export const updateContractSetting = (contractId, contractSettingDTO) => {
    return axios.patch(`/contract/${contractId}/setting`, contractSettingDTO)
}

export const deleteSupplementContract = (contractId,supplementContractId) =>{
    return axios.delete(`/contract/${contractId}/supplemental-contract/${supplementContractId}`)
}

export const getContractBulletin = (contractId)=>{
    return axios.get(`/contract/${contractId}/bulletin`)
}

export const updateContractBulletin = (contractId,message)=>{
    return axios.post(`/contract/${contractId}/bulletin`,{message:message})
}
export const getContractBlockTag = (contractId)=>{
    return axios.get(`/contract/${contractId}/block-tag`)
}
export const completeContractTag = (contractId,tagId,fromData)=>{
    return axios.post(`/contract/${contractId}/contract-tag/${tagId}/complete`,fromData)
}

export const cancelContractTagCompleteStatus = (contractId,tagId) => {
    return axios.post(`/contract/${contractId}/contract-tag/${tagId}/cancel-complete`);
}

//待办组件固定调用
export const deleteTag = (tagId) => {
    return axios.delete(`/tag/${tagId}`);
}

//待办组件固定调用
export const updateTagType = (tagId,param)=>{
    return axios.post(`/tag/${tagId}`,param)
}
//待办组件固定调用
export const updateTagsSortIndex = (param)=>{
    return axios.post('/tag-sort-index',param)
}

/**
 * 添加标签
 * @param tagDTO
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addTag = (tagDTO) => {
    return axios.post(`/tag`, tagDTO);
}

/**
 * 模板添加标签
 * @param publishedTemplateId
 * @param tagDTO
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addPublishedTemplateTag = (publishedTemplateId, tagDTO)=>{
    return axios.post(`/published-template/${publishedTemplateId}/tag`, tagDTO)
}

/**
 * 模板删除关联标签
 * @param publishedTemplateId
 * @param tagId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deletePublishedTemplateTag = (publishedTemplateId, tagId)=>{
    return axios.delete(`/published-template/${publishedTemplateId}/tag/${tagId}`)
}

/**
 * 时间提醒添加标签
 * @param contractDateId
 * @param tagDTO
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addContractDateTag = (contractDateId, tagDTO)=>{
    return axios.post(`/contract-date/${contractDateId}/tag`, tagDTO)
}

/**
 * 时间提醒删除关联标签
 * @param contractDateId
 * @param tagId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteContractDateTag = (contractDateId, tagId)=>{
    return axios.delete(`/contract-date/${contractDateId}/tag/${tagId}`)
}

/**
 * 时间提醒完成待办标签
 * @param contractDateId
 * @param tagId
 * @param fromData
 * @returns {Promise<AxiosResponse<any>>}
 */
export const completeContractDateTag = (contractDateId, tagId, fromData)=>{
    return axios.post(`/contract-date/${contractDateId}/tag/${tagId}/complete`, fromData)
}

/**
 * 时间提醒取消完成待办标签
 * @param contractDateId
 * @param tagId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const cancelCompleteContractDateTag = (contractDateId, tagId)=>{
    return axios.post(`/contract-date/${contractDateId}/tag/${tagId}/cancel-complete`)
}
/**
 * 判断此版合同和上版本合同的字数区别是否达到阈值,而保存一条新记录
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userConfirmLaunchReview = (contractId)=>{
    return axios.patch(`/contract/${contractId}/user-confirm-launch-review`)
}
export const updateJobStatus = ()=>{
    return axios.get(`/update-paused-job-status`)
}
export const getAllContractTagRelation = (tagId) => {
    return axios.get(`/contract-tag/${tagId}/count`);
}

export const getAllPublishedTemplateTagRelation = (tagId) => {
    return axios.get(`/published-template-tag/${tagId}/count`);
}

/**
 * 删除一个标签的所有合同的关联
 * @param tagId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const removeAllContractTagRelation = (tagId) => {
    return axios.delete(`/tag/${tagId}/remove-contract-relation`);
}
/**
 * 删除一个标签的所有模板的关联
 * @param tagId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const removeAllPublishedTemplateTagRelation = (tagId) => {
    return axios.delete(`/tag/${tagId}/remove-published-template-relation`);
}

/**
 * 删除一个标签的所有模板的日历
 * @param tagId
 */
export const removeAllDateRelation = (tagId) => {
  return axios.delete(`/tag/${tagId}/remove-contract-date-relation`);
}

export const getTeamReviewRecord = (teamId) => {
    return axios.get(`/team/${teamId}/get-team-review-record`);
}

export default axios;

