import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element.js'
import {ElMessage} from "element-plus";
import router from './router'
import store from './store'
import axios, {appendAppHeader, getMyInfo} from './api/api'
import VueAxios from 'vue-axios'
import SvgIcon from './components/SvgIcon/svgIcon';
import clipboard from 'clipboard';
import {globalESLogMonitorFunc} from "./assets/js/tag";
import config, {DEPLOY_TYPE} from "./config";
import {checkPlatformIsMobile, isMobile,isWeiXin} from "./util";


const app = createApp(App).use(store).use(router);
app.component('svg-icon', SvgIcon);

installElementPlus(app);
app.mount('#app');
app.use(VueAxios, axios);

app.config.globalProperties.$clipboard=clipboard

app.config.globalProperties.$getCookie = function (name) {
    var arr = document.cookie.match(
        new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    )
    if (arr != null) {
        return unescape(arr[2])
    }
    return null
}


// 被动监听用户的点击事件，从而进行事件上报
document.onclick = function (e) {
    globalESLogMonitorFunc(e);
}

router.beforeEach((to, from, next) => {
    //如果没有匹配到页面则到404页面
    if(to.matched.length === 0){
        next({
            name:'notfound'
        })
        return;
    }

    //如果不是测试环境而去访问测试页面会重定向
    if(config.deployType != DEPLOY_TYPE.TEST && to.path.startsWith('/test')){
        next({name:'contractIndex'});
        return;
    }
    //对于那些需要通过query变化来加载内容的页面防止重复请求myInfo
    if(to.name===from.name){
        if(sessionStorage.getItem('userInfo')!==null && sessionStorage.getItem('userInfo')!=="{}"){
            next();
            return;
        }

    }
    //设置延时器让created先执行在进行路由跳转
    setTimeout((res) => {
        // 判断该路由是否需要登录权限
        if (to.meta && to.meta.requireAuth) {
            // 通过vuex state获取当前的状态是否存在
            // 请求myInfo，将用户信息存储到sessionStorage里面，如果没有登录，则跳转到登陆页面
            sessionStorage.setItem('userInfo', "{}");
            sessionStorage.setItem('teamInfo', "{}");
            if(isMobile()){
                //给手机页面加上额外请求头header
                appendAppHeader(isWeiXin() ? {'extra-agent':'wechat-clm','wetchat-clm':1} : undefined);
            }
            getMyInfo().then(userInfoObj => {
                if (userInfoObj.data.code === 0) {
                    let loginUser = userInfoObj.data.userInfo;
                    console.log('loginUser === ', loginUser);
                    sessionStorage.setItem('userInfo', JSON.stringify(loginUser))
                    if (userInfoObj.data.teamInfo) {
                        sessionStorage.setItem('teamInfo', JSON.stringify(userInfoObj.data.teamInfo))
                    }

                    //用户信息里没有手机号并且不是使用账号登录,但有邀请码,转到"补全信息"页面
                    if((!loginUser['phone'] && !localStorage.getItem("loginByAccount") && loginUser['inviteCode']===config.guestInviteCode)){
                        //到"补全信息"、"合同编辑"、"合同对比"、"合同历史版本预览"不需要跳转
                        if( to.name!='register' && to.name!='contractEdit' && to.name!='contractDiff' && to.name!='contractHistory') {
                            next({
                                path: '/register',
                                query: {
                                    redirect: to.fullPath
                                }
                            })
                        }else{
                            next();
                        }
                    }
                    //用户没有主团队，跳到”创建团队“页面
                    else if (!loginUser['mainSiteTeamId']) {
                        console.log('go createTeam ---- ')
                        if (to.name !== 'createTeam' && !(to.name=='contractIndex' && to.query.vo)) {
                            next({
                                path: '/createTeam',
                                query:{
                                    redirect:to.fullPath
                                }
                            })
                        } else {
                            next();
                        }
                    }
                    else {
                        next();
                    }
                }
                else if (userInfoObj.data.code === 401 || userInfoObj.data.code === 402) {
                    // 未登录
                    console.log('未登录 ----------------')
                    // TODO: 此处好像无法跳转到别的端口
                    if(to.name=='contractIndex' && to.query.vo){
                        next({
                            path:"/joinTeam",
                            query:{
                                vo:to.query.vo,
                                redirect:to.fullPath
                            }
                        })
                    } else {
                        next({
                            //先判断是否是手机端
                            path: checkPlatformIsMobile() ? '/mobileLogin' : '/home',
                            // fullPath: 'http://172.186.1.185:8001/proLogin',
                            query: {
                                redirect: to.fullPath
                            }
                        })
                    }
                } else {
                    console.error('获取用户信息失败------  userInfoObj:', userInfoObj);
                    next();
                }
            }).catch(err => {
                ElMessage.error('与服务器断开连接');
                console.error('获取用户信息失败------  err:', err);
                next({
                    name:'home',
                    params:{
                        showLoginComponent:true
                    }
                })
                // next();
            })
        } else {
            next();
        }
    }, 100);
})
