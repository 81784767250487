<template>
  <Mask
          :display="authenticationDialog ? 'block':'none'"
          :z-index="1001"
  >
    <div class="loginComponentWrap insetCenter">
      <AuthorizeUserComponent v-on:loginSuccess="authenticationSuccess" :missionType="missionType"
                              v-on:signupSuccess="authenticationSuccess"
                              :inviteCode="parsedParam.vc"
                              v-bind:source="parsedParam.source">
        <template #tip>
          注册后，免费下载该文档
        </template>
      </AuthorizeUserComponent>
    </div>

  </Mask>

  <SmartContractGenerateLoadingCover v-if="generateContractDialog" :timeOver="fakeTimerOut"/>

  <div style="position: fixed;height:48px;line-height:48px;width: 100%;z-index: 100;background: white;" v-if="needHeader">
    <div style="display: flex;flex-direction: row; box-shadow: 0 5px 10px 0 rgba(8, 24, 60, 0.1);height: 100%;" v-if="!isMobile">
      <div class="leftHeader">
        <el-tooltip class="item" effect="dark" content="MetaGo智能合同" placement="bottom-end">
          <a :href="metaGoHomePage" style="display: inline-block;height: 40px;"><img src="../assets/images/metasotalog.png" class="logo" style="cursor: pointer"></a>
        </el-tooltip>
      </div>
      <!--            <div style="width: 33%;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" :title="answerSheetIntroInfo.fileName">{{answerSheetIntroInfo.fileName}}</div>-->
      <div style="width: 50%;text-align: right;padding-right: 35px;">
        <template v-if="this.parsedParam.canPreview">
          <!--                    <el-dropdown trigger="click" @command="switchPreviewMode" v-if="answerQuestionViewMode!==ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET">-->
          <!--                        <el-link  href="javascript:void(0)" style="margin-right: 8px;" :underline="false">附加信息</el-link>-->
          <!--                        <template #dropdown>-->
          <!--                            <el-dropdown-menu>-->
          <!--                                <el-dropdown-item  :command="1">显示</el-dropdown-item>-->
          <!--                                <el-dropdown-item  :command="0">隐藏</el-dropdown-item>-->
          <!--                            </el-dropdown-menu>-->
          <!--                        </template>-->
          <!--                    </el-dropdown>-->
          <el-dropdown trigger="click" @command="switchViewMode" >
            <el-link  href="javascript:void(0)" :underline="false">切换模式<i class="el-icon-arrow-down el-icon--right" style="margin-left: 0;"></i></el-link>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item  :command="ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET">答题模式</el-dropdown-item>
                <el-dropdown-item  :command="ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW">预览模式</el-dropdown-item>
                <el-dropdown-item  :command="ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW" v-if="!isMobile">左右分栏</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </div>

    </div>

    <el-tabs v-else v-model="answerQuestionViewMode" :stretch="true"  @tab-click="mobileViewModeChange">
      <el-tab-pane label="回答" :name="ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET" ></el-tab-pane>
      <el-tab-pane  :name="ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW" >
        <template #label>
          <span>预览<span style="color:#409EFF " v-if="totalUnreadRelatedCount>0">({{totalUnreadRelatedCount}})</span></span>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>

  <el-container style="padding:50px 0px 0px;overflow-y: hidden;height: 100vh;display: flex;justify-content: center;" :style="{'padding-top':needHeader?'50px':'0'}" class="myBody">
    <el-aside id="question-container" :style="{width: cssLeftWidth}" style="height: 100%;overflow-y: auto;">
      <div id="answerSheetContent" :class="{leftScroller:answerQuestionViewMode===ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW}">
        <div style="height: 10px; background-color: transparent;" v-if="answerQuestionViewMode===ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW"></div>
        <template-answer-sheet-intro v-if="paramInitFinished&&answerQuestionViewMode===ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET"  :file-id="fileId" :auto-get="false" :auth="this.parsedParam.authInfo" :answer-sheet-intro="answerSheetIntroInfo" style="margin: 20px 0;"></template-answer-sheet-intro>
        <!--                <el-row >-->
        <!--                    <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:6}"  style="background-color:#FFF;padding:34px 2% 50px;min-height: 100vh;">&lt;!&ndash;问题的回答模式&ndash;&gt;-->

        <el-collapse v-model="activeGroups" style="position: relative" class="groupQuestionCollapse">
          <el-collapse-item  v-for="(group,index) in groups" :key="group.id" :title="group.name" :name="group.id" v-show="groupVisible[index]">
            <template #title>
              <img src="../assets/images/groupMenu.png" class="groupMenuIcon"/>{{group.name}}
            </template>
            <doc-question-answer-mode v-for="(answer,j) in groupAnswerSheets[index]" :key="answer.answer.questionId"
                                      v-show="groupAnswerSheetVisible[index][j]"
                                      :ref="getAnswerRefName(answer.answer.questionId)"
                                      :answerSheet="answer" :auto-save="false"
                                      @answer-changed="answerChanged"
            ></doc-question-answer-mode>
          </el-collapse-item>

          <el-collapse-item v-if="!this.parsedParam.system&&!this.parsedParam.forCreateContract&&!this.isMobile"  title="联系人信息" :name="-1" >
            <template #title>
              <img src="../assets/images/groupMenu.png" class="groupMenuIcon"/>联系人信息
            </template>
            <el-card class="answerBox" style="margin-bottom: 50px;">
              <div style="margin-bottom: 10px;"><span >请输入您的邮箱</span></div>
              <div>
                <el-input
                    placeholder="请输入您的邮箱"
                    v-model="userInfo.email">
                </el-input>
              </div>
            </el-card>
          </el-collapse-item>

        </el-collapse>
      </div>

    </el-aside>
    <el-divider direction="vertical" class="docTemplateDivider" v-show="answerQuestionViewMode===ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW"></el-divider>
<!--    <el-main id="editor-content-container" style="overflow: visible;" :style="{width: cssRightWidth}" :class="{'mobile_view':isMobile}"  class="previewModeBackground">-->
    <el-main id="editor-content-container" style="overflow: visible;" :style="{width: cssRightWidth}" :class="{'mobile_view':isMobile}" v-show="paramInitFinished&&!isOnlyAnswerSheetViewMode" class="previewModeBackground">
      <doc-content ref="content" @editor-messages="handelEditorMessages" @editor-ready="editorReady"
                   :variableConfig="editorJsVariableConfig" :fileId="fileId"
                   :get-setting="getSetting" :auth="this.parsedParam.authInfo"
                   :content="docContent" :default-mode="docContentMode" v-if="docContent!=null">
      </doc-content>

    </el-main>
  </el-container>
</template>
<style lang="scss">
#editorjs_edit_preview{
  line-height: 1.5;
}
#editor-content-container{
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
#editor-content-container.mobile_view{
  padding:0;
}
.leftHeader{
  width: 50%;
  text-align: left;
  padding-left: 8px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
}
.groupMenuIcon{
  width: 14px;
  margin-right: 10px;
}
#question-container .el-collapse .el-collapse-item:last-child .el-collapse-item__wrap{
  border-bottom: none!important;
}
#question-container .el-collapse{
  border-top:none;
  border-bottom: none;
}
.myBody::-webkit-scrollbar{
  display: none;
}

#answerSheetContent {
  /*width: 100%;*/
  /*min-height: 100%;*/
  height: 100%;
  overflow-y: scroll;
  /*position: absolute;*/
  position: relative;
  /*background-color: rgba(242,242,242,1);*/

  .el-card {
    border: none;
  }

  .is-always-shadow {
    box-shadow: none;
  }
  .groupQuestionCollapse{
    .el-collapse-item__header{
      font-weight: bold;
      background-color: #F6F7FA;
      padding-left: 15px;
    }
    .el-collapse-item__wrap{
      padding-left: 15px;
    }
  }

}
#answerSheetContent::-webkit-scrollbar{
  display: none;
}
#answerSheetContent.leftScroller{
  padding-right: 3px;
}
#answerSheetContent.leftScroller::-webkit-scrollbar {
  /*display: none;*/
  width: 5px;
  height: 5px;
  display: block;

}
#answerSheetContent.leftScroller::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
}

.answerBox{
  margin: 10px 0;
  text-align: left;
}
.dividerLine{
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background-color: #DCDFE6;
  position: relative;
}
.previewModeBackground {
  background-color: #F2F3F5;
  position: relative;
}
.docTemplateDivider {
  height: 100%;
  cursor: ew-resize;
  width: 3px;
  margin-left: 0;
}
.docTemplateDivider:hover {
  background-color: #3370ff;
}
.docTemplateDividerHovered {
  background-color: #3370ff;
}

.my-loading-modal {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0,0,0,.2);
}

.my-loading-content {
  position: absolute;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  padding: 10px;
  border-radius: 2px;
}
.mobile_view{
  .codex-editor{
    padding-left: 5px;
    padding-right: 5px;
  }
  .introContainer{
    margin-left: 5px;
    margin-right: 5px;
  }
}
.dialog-authentication >.el-dialog__header {
  display: none;
}
.dialog-authentication >.el-dialog__body {
  padding: 0;
}
</style>

<script>
import {
  ACTIVE_MODEL,
  ANSWER_QUESTION_VIEW_MODEL,
  answerSheetShouldShowQuestion,
  AnswerSheetSourceType,
  convertQuestionProperty,
  EditorMsgType,
  getAnswerCacheMap,
  getOrProduceQuestionDefaultAnswer,
  getQuestionAnswerCacheKey,
  produceAnswer,
  VARIABLE_TYPE_OPTIONS,
  COMPANY_BASIC_INFO_MAPPING,
  fixOrganizationQuestionIndex, getVisibleDependencyAnswerSheet, convertContentToStringIFNotNull, fixAnswerContent
} from "../constant/question";
import DocQuestionAnswerMode from "../components/DocQuestionAnswerMode";
import {
  downloadSystemTemplate, getAiQiChaCompanyData,
  getAnswerSheetInfo,
  getAnswerSheetIntroInfo,
  getShareInfo, getMyInfo,
  submitAnswerSheet, downloadByAnswerSheetId, draftContractByAnswers, getMetaProData
} from "../api/api";
import {isMobile, itemJsonStrPro2Obj} from "../util";
import {bool, single} from "../components/common/question";
import {getDownloadUrlByAnswerSheetId} from "../components/common/commonUtil"
import DocContent from "../components/DocContent";
import {ElLoading, ElMessage} from "element-plus";
import TemplateAnswerSheetIntro from "../components/TemplateAnswerSheetIntro";
import { defineAsyncComponent } from 'vue';
import config  from "../config";
import {CUSTOM_EVENT, logToES} from "../assets/js/tag";
import {ContractApprovalStatus} from "../constant/contract";
import Mask from "../components/universalUI/Mask";

export default {
  name: 'AnswerSheet',
  mounted: function () {
    this.init();
    if (!this.isMobile) {
      this.bindDividerEvent();
    }
  },
  data: function () {
    return {
      missionType:'login',
      ContractApprovalStatus,
      CUSTOM_EVENT:CUSTOM_EVENT,
      groupAnswerSheetVisible:[],
      groupVisible:[],
      metaGoHomePage: config.baseUrl,
      generateContractDialog: false,
      /**
       * 下载合同网络请求是否完成
       * */
      contractGenerating: false,
      /**
       * 下载合同时假的加载弹窗
       */
      contractLoadingCoverShowing: false,
      authenticationDialog: false,
      downloading: false,
      templateUrl: null,
      activeGroups: [],
      answerSheet: [],
      groups: [],
      isMobile: isMobile(),
      fileId: null,
      paramInitFinished:false,
      answerSheetId: this.$route.query.answerSheetId,
      submitDisable: false,
      userInfo: {
        email: localStorage.getItem('respondentEmail')
      },
      teamInfo:JSON.parse(sessionStorage.getItem("teamInfo")),
      parsedParam: {
        showGroupIds: [],
        showAllGroup: false,
        downloadAfterAnswerSheetSubmit: false,
        canPreview: false,
        authInfo: null,

        // 以下两个是创建合同专有属性
        forCreateContract:false,
        publishTemplateId:null
      },
      docContent: null,
      docContentMode: ACTIVE_MODEL.ANSWER_MODEL,
      ANSWER_QUESTION_VIEW_MODEL: ANSWER_QUESTION_VIEW_MODEL,
      leftWidth: window.innerWidth * 0.33,
      divider: null,
      isDraging: false,
      mouseCurrentX: -1,
      showVariable: false,
      windowWidth: window.innerWidth,
      rightWidth: window.innerWidth * 0.37 - 59,
      variableConfig: {
        showVariable: true
      },
      rightLeftMaxRatio: 2.5,
      rightLeftMinRatio: 1,
      answerQuestionViewMode: ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET,
      editorJsVariableConfig: {
        showVariable: false
      },
      answerSheetIntroInfo: {
        questionnaireTitle: '',
        questionnaireIntro: '',
        fileName:''
      },
      loadStatus: {
        answerSheet: false,
        docContent: false
      },
      loadingInstance:null,
      orq: {}, // 保存组织类问题是否正在请求中，最新答案，是否有新的排队请求等信息
      mouseMoveTimeout:null,
      // 未读的答案改变造成的区域变化的个数的map
      unreadAnswerChangedRelatedRectMap:new Map()
    };
  },
  watch:{
    'userInfo.email':function (val) {
      localStorage.setItem('respondentEmail',val)
    },
    'answerSheet':{
      handler: function () {
        this.reCalculateGroupAnswerSheetVisible()
      },
      deep:true
    },
    'answerQuestionViewMode':function () {
      this.unreadAnswerChangedRelatedRectMap.clear();
    },
  },
  computed: {
    'answerSheetMap': function () {
      return new Map(this.answerSheet.map(a => [a.question.id, a]))
    },
    'needHeader': function () {
      return !(this.isMobile && !this.parsedParam.canPreview)
    },
    groupAnswerSheets:function () {
      const groupAnswerSheetsMap = new Map();
      const answerSheets = this.answerSheet;
      for (let i = 0; i < answerSheets.length; i++) {
        if (!groupAnswerSheetsMap.has(answerSheets[i].question.groupId)) {
          groupAnswerSheetsMap.set(answerSheets[i].question.groupId, []);
        }
        groupAnswerSheetsMap.get(answerSheets[i].question.groupId).push(answerSheets[i]);
      }
      return this.groups.map(group=>{
        const values = groupAnswerSheetsMap.get(group.id)
        return values ? values : [];
      })
    },
    cssLeftWidth:function () {
      switch (this.answerQuestionViewMode) {
        case ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW:
          return `${this.leftWidth}px`;
        case ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET:
          return this.isMobile ? '100%' : '50%';
        case ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW:
          return  '0';
      }
      return `${this.leftWidth}px`;
    },
    cssRightWidth:function () {
      switch (this.answerQuestionViewMode) {
        case ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW:
          return `${this.rightWidth}px`;
        case ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET:
          return '0';
        case ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW:
          return  '100%';
      }
      return `${this.rightWidth}px`;
    },
    isOnlyAnswerSheetViewMode: function () {
      return this.answerQuestionViewMode === ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET;
    },
    totalUnreadRelatedCount:function () {
      let totalUnreadRelatedCount = 0;
      this.unreadAnswerChangedRelatedRectMap.forEach((val) => {
        totalUnreadRelatedCount += val;
      })
      return totalUnreadRelatedCount;
    }
  },
  methods: {
    getAnswerSheetNextText:function(){
      if (this.isMobile) {
        return '下一步'
      }
      return this.parsedParam.forCreateContract ?'立即创建':'提交';
    },
    getPreviewNextText:function(){
      if (this.parsedParam.forCreateContract) {
        return '立即创建'
      }
      return this.parsedParam.downloadAfterAnswerSheetSubmit?'导出':'下一步'
    },
    mobileViewModeChange:function(mode){
      if (mode !== ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET) {
        if (this.$refs.content) {
          this.$refs.content.editorResize();
        }
      }
    },
    /**
     * 重新计算那个问题该显示，哪个问题该隐藏
     */
    reCalculateGroupAnswerSheetVisible: function () {
      let sheetSize = this.groupAnswerSheets.length;
      let groupAnswerSheetVisible = [];
      let groupVisible = [];
      for (let i = 0; i < sheetSize; i++) {
        let groupAnswerSheet = this.groupAnswerSheets[i];
        let groupQuestionSize = groupAnswerSheet.length;
        groupAnswerSheetVisible.push([]);
        let currentGroupVisible = false;
        for (let j = 0; j < groupQuestionSize; j++) {
          let answerSheet = this.groupAnswerSheets[i][j];
          let questionVisible = this.showQuestion(answerSheet);
          groupAnswerSheetVisible[i].push(questionVisible);
          if (questionVisible) {
            currentGroupVisible = true;
          }
        }
        groupVisible.push(currentGroupVisible);
      }
      this.groupAnswerSheetVisible = groupAnswerSheetVisible;
      this.groupVisible = groupVisible;
    },
    logMouseMoveEvent: function(){
      if (this.mouseMoveTimeout) {
        clearTimeout(this.mouseMoveTimeout);
      }
      this.mouseMoveTimeout = setTimeout(()=>{
        console.log('mouse move****')
        logToES({event_name:CUSTOM_EVENT.AQ_MOUSE_MOVE.name,value:1, text: CUSTOM_EVENT.AQ_MOUSE_MOVE.desc})
      },200)
    },
    bindDividerEvent: function () {
      this.divider = document.querySelector('.docTemplateDivider');
      if (this.divider) {
        this.divider.onmousedown = (e) => {
          this.isDraging = true;
          this.mouseCurrentX = e.pageX;
          this.divider.classList.add('docTemplateDividerHovered');
          e.stopPropagation();
        }
      }
      document.addEventListener('mousemove', (event) => {
        if (this.isDraging) {
          this.dividerDragging(event);
          event.stopPropagation();
          event.preventDefault();
        }
        this.logMouseMoveEvent();
      });
      document.addEventListener('mouseup', (event) => {
        if (this.isDraging) {
          this.isDraging = false;
          this.mouseCurrentX = -1;
          this.divider.classList.remove('docTemplateDividerHovered');
        }
      });
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
        this.rightWidth = this.windowWidth - this.leftWidth - 59;
      });
    },
    dividerDragging: function (event) {
      const questionContainer = document.querySelector("#question-container");
      const baseWidth = parseInt(questionContainer.style.width);
      const offset = event.pageX - this.mouseCurrentX;
      const windowWidth = window.innerWidth;
      const newLeftWidth = baseWidth + offset;
      const newRightWidth = windowWidth - newLeftWidth - 59;
      const rightLeftRatio = newRightWidth / newLeftWidth;
      if (rightLeftRatio < this.rightLeftMinRatio || rightLeftRatio > this.rightLeftMaxRatio) {
        return;
      }
      this.leftWidth = newLeftWidth;
      this.rightWidth = newRightWidth;
      this.mouseCurrentX = event.pageX;
      this.$refs['content'].editorResize();
    },
    initViewMode: function () {
      if (!this.parsedParam.canPreview) {
        this.answerQuestionViewMode = ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET;
        return
      }
      if (this.isMobile) {
        this.answerQuestionViewMode = ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET;
      } else {
        this.answerQuestionViewMode = ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW;
      }
    },
    switchViewMode:function(mode){
      console.log('switchMode',mode);
      this.answerQuestionViewMode = mode
    },
    /**
     * 切换editorJs的模式。
     */
    switchPreviewMode:function(show){
      this.editorJsVariableConfig.showVariable = show === 1;
    },
    init: function () {
      try{
        this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
        this.parseParam().then(()=>{
          this.initViewMode();
          return this.createAnswerSheet();
        }).then(()=>{
          return this.getAnswerSheetIntroInfo();
        }).then(()=>{
          return this.initEditor();
        }).then(()=>{
          try {
            this.doAnswerAllQuestion();
          } catch (error) {
            console.error('不能预览  初始化调用doAnswerAllQuestion失败！')
          }
        }).finally(()=>{
          setTimeout(()=>{
            this.loadingInstance.close();
          },600)

        });

      }catch (e) {
        console.error(e)
      }
    },
    //是否显示某个问题
    showQuestion: function (answerSheet) {
      return answerSheet.question.extraUIInfo.defaultShow && answerSheetShouldShowQuestion(answerSheet, this.answerSheetMap);
    },
    parseParam: function () {
      const shareKeyId = this.$route.query.data;
      const system = this.$route.query.system;
      const vc = this.$route.query.vc;
      const source = this.$route.query.s;
      this.parsedParam.system = system && parseInt(system) === 1;
      this.parsedParam.vc = vc ? vc : 'MGO-VOL7322C69B';
      this.parsedParam.source = source ? source : '';
      return getShareInfo(shareKeyId).then(res => {
        if (res.data.code === 0) {
          this.paramInitFinished = true;
          let param = JSON.parse(res.data.data);
          this.fileId = param.fileId;
          this.parsedParam = Object.assign(this.parsedParam, param);
        }else {
          this.$message.error('参数错误');
        }
      }).catch(err => {
        this.$message.error('参数错误');
      });
    },
    getAnswerSheetIntroInfo:function(){
      return getAnswerSheetIntroInfo(this.fileId,this.parsedParam.authInfo).then(res=>{
        const {code, data} = res.data;
        if (code === 0) {
          this.answerSheetIntroInfo = data;
        }
      })
    },
    getSetting:function(){
      return this.answerSheetIntroInfo;
    },
    createAnswerSheet: function () {
      const self = this;
      /**
       * 获取缓存的答卷信息
       * @type {{}|any}
       */
          // 暂时取消对缓存的获取
          // let cachedAnswerSheet = self.loadAnswerSheetCache();
      let cachedAnswerSheet = {};

      return this.getOrCreateAnswerSheetInfo().then(res => {
        // console.log('getAnswerSheetInfo*************')
        // console.log(res)
        // console.log('getAnswerSheetInfo*************')
        const answerSheetInfo = res.data.data;
        //获取到所有问题的答案缓存
        const answers = answerSheetInfo.answers;
        if (answerSheetInfo.sheet) {
          this.answerSheetId = answerSheetInfo.sheet.id;
        }else{
          this.answerSheetId = null;
        }
        const questions = fixOrganizationQuestionIndex(convertQuestionProperty(answerSheetInfo.questions));
        const questionAnswerMap = getAnswerCacheMap(answers);

        self.answerSheet = questions.map((question) => {
          let answer = questionAnswerMap.get(getQuestionAnswerCacheKey(question.id, question.answerType));
          // console.log(`id:${question.id}  answer:`, answer);
          if (answer) {
            itemJsonStrPro2Obj(answer, 'content');
            if (typeof answer.content === "number") {
              answer.content = JSON.stringify(answer.content);
            }
            fixAnswerContent(question,answer);
            // 设置默认答案。（如果存入缓存之后设置的默认答案，就需要将默认答案重新设置到答案里面）
            if ((answer.content == null || answer.content === '') && question.defaultAnswer != null) {
              answer.content = getOrProduceQuestionDefaultAnswer(question, true);
            }
          } else {
            answer = produceAnswer(question.answerType, getOrProduceQuestionDefaultAnswer(question,true), question.id,  self.answerSheetId,self.fileId);
            if (cachedAnswerSheet[getQuestionAnswerCacheKey(question.id, question.answerType)] !== undefined) {
              // 如果有缓存
              answer.content = cachedAnswerSheet[getQuestionAnswerCacheKey(question.id, question.answerType)];
            }
          }
          question.extraUIInfo={defaultShow: !question.parentId}
          return {
            question: question,
            questionUUID: question.uuid,
            answer: answer
          };
        });
        this.groups = answerSheetInfo.groups;
        this.activeGroups = this.groups.map(g => g.id).concat(-1);
        this.loadStatus.answerSheet = true;
        logToES({event_name:CUSTOM_EVENT.AQ_SYSTEM_ANSWER_SHEET_DATA_LOADED.name,value:1, text: CUSTOM_EVENT.AQ_SYSTEM_ANSWER_SHEET_DATA_LOADED.desc});
      });

    },
    getOrCreateAnswerSheetInfo() {
      return getAnswerSheetInfo({publicUserAuthToken:this.parsedParam.authInfo,fileId:this.fileId,showGroupIds:this.parsedParam.showGroupIds,showAllGroup:this.parsedParam.showAllGroup});
    },
    /**
     * 预览文档界面的下一步按钮点击事件响应
     */
    previewGoNext:function(){
      if(!this.validateForm(this.userInfo,this.answerSheet)){
        return;
      }
      if (this.parsedParam.forCreateContract) {
        // 如果是创建合同(不管电脑还是手机)
        this.createContract();
      }else if (this.isMobile) {
        this.mobileJumpToSubmitPage();
      }else{
        // 如果是电脑，则进入提交流程
        this.onSubmit();
      }
    },
    goNext:function(){
      // 开始提交
      if(!this.validateForm(this.userInfo,this.answerSheet)){
        return;
      }
      if (this.isMobile) {
        // 如果是手机，点击下一步，则判断能否预览
        if (this.parsedParam.canPreview) {
          // 如果能预览，则切换到预览模式
          this.answerQuestionViewMode = ANSWER_QUESTION_VIEW_MODEL.ONLY_DOC_PREVIEW;
        } else {
          // 创建合同文件不会进入这里，因为canPreview都是true
          // 如果不能预览，则直接跳到填写email界面
          this.mobileJumpToSubmitPage();
        }

      } else {
        // 如果是电脑，则进入提交流程
        if (this.parsedParam.forCreateContract) {
          // 如果是创建合同，就直接创建合同
          this.createContract();
        }else{
          this.onSubmit();
        }
      }
    },
    mobileJumpToSubmitPage:function(){
      const answers = [], oldAnswerMap = new Map();
      this.answerSheet.forEach(as => {
        const copyAnswer = Object.assign({}, as.answer);
        copyAnswer.content = convertContentToStringIFNotNull(copyAnswer.content);
        answers.push(copyAnswer);
        oldAnswerMap.set(as.question.id, as.answer);
      });
      const param = {answers: answers, sourceType: AnswerSheetSourceType.PC, email: this.userInfo.email}
      this.$router.push({
        name:'answerQuestionSubmitPage',
        params:{
          paramJSON: JSON.stringify(param),
          permissionJSON:JSON.stringify(this.parsedParam),
          fileId: this.fileId
        }
      })
    },
    getAnswersParam: function () {
      const answers = [], oldAnswerMap = new Map();
      this.answerSheet.forEach(as => {
        const copyAnswer = Object.assign({}, as.answer);
        copyAnswer.content = convertContentToStringIFNotNull(copyAnswer.content);
        answers.push(copyAnswer);
        oldAnswerMap.set(as.question.id, as.answer);
      });
      return {answers:answers, oldAnswerMap: oldAnswerMap}
    },
    createContract:function(){
      let {answers} = this.getAnswersParam();
      this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
      this.loadingInstance.setText('创建中')
      draftContractByAnswers(answers, this.parsedParam.publishTemplateId).then(res => {
        if (res.data.code === 0) {
          this.loadingInstance.setText('创建成功')
          this.$message.success('创建合同成功');
          let contract = res.data.data;
          this.$router.push({name: 'contractView', query: {id: contract.id, name: contract.title}})
        } else if (res.data.code === 401) {
          this.$message.error('暂无权限，请在当前浏览器登录后操作');
        } else {
          this.$message.error('创建合同失败，请稍后再试');
        }
      }).catch(error => {
        console.error(error);
        this.$message.error('创建合同失败，请稍后再试');
      }).finally(() => {
        this.loadingInstance.close()
      });
    },
    onSubmit:function(){
      //开始提交
      if (!this.validateEmail()) {
        return;
      }
      // if(!this.validateForm(this.userInfo,this.answerSheet)){
      //     return;
      // }

      // const answers = [], oldAnswerMap = new Map();
      // this.answerSheet.forEach(as => {
      //   const copyAnswer = Object.assign({}, as.answer);
      //   copyAnswer.content = convertContentToStringIFNotNull(copyAnswer.content);
      //   answers.push(copyAnswer);
      //   oldAnswerMap.set(as.question.id, as.answer);
      // });

      let {answers,oldAnswerMap} = this.getAnswersParam();
      const param = {answers: answers, sourceType: AnswerSheetSourceType.PC, email: this.userInfo.email,publicUserAuthToken:this.parsedParam.authInfo};

      this.submitDisable = true;
      submitAnswerSheet(this.fileId,param).then(res => {
        this.clearAnswerSheetCache();
        if (res.data.code === 0) {
          //取得新的answerSheetId，刷新到原来的对象里面去
          const newAnswers = res.data.data;
          if (newAnswers.length > 0) {
            this.answerSheetId = newAnswers[0].answerSheetId;
          }
          newAnswers.forEach(newAnswer=>{
            //将保存后的数据更新的内存中的答案对象中。
            const oldAnswer = oldAnswerMap.get(newAnswer.questionId);
            if (oldAnswer) {
              //但是保留原有的content（因为它可能是对象，免去再一次将字符串转换为对象的操作）
              newAnswer.content=oldAnswer.content;
              Object.assign(oldAnswer, newAnswer);
            }
          });
          const downloadAfterSubmit =this.parsedParam.downloadAfterAnswerSheetSubmit;
          this.$message.success(downloadAfterSubmit?'提交成功,即将开始下载,请耐心等候。':'提交成功');
          if (downloadAfterSubmit) {
            this.download(this.fileId,this.answerSheetId)
          }
        }else {
          this.$message.error('提交失败');
        }

      }).catch(error => {
        console.error(error);
        this.$message.error('提交失败');
      }).finally(()=>{
        this.submitDisable = false;
      });
    },
    download: function (fileId, sheetId) {
      this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
      downloadByAnswerSheetId(fileId, sheetId,this.parsedParam.authInfo).then(res => {
          this.loadingInstance.close();
          this.loadingInstance = null;
          let url = window.URL.createObjectURL(res.data);
          let a = document.createElement('a');
          let filename = decodeURIComponent(res.headers['filename']);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
      }).catch(err => {
        this.loadingInstance.close();
        this.loadingInstance = null;
        err.response.data.text().then(message => {
          ElMessage({
            showClose: true,
            message: JSON.parse(message).message,
            type: 'error'
          });
        })
      })
    },
    /**
     * 系统模板文档，下载功能
     * 流程
     * 1. 验证是否登录， 登录弹窗
     * 2. 生成文件并发送二进制到本地， 加载弹窗; 下载成功并且弹窗显示完成之后
     * 3. 下载文件
     */
    onDownload: function() {
      /**
       * 禁用下载键
       */
      this.downloading = true;

      let uid = this.$getCookie("uid");
      // const sid = this.$getCookie("sid");
      // const accessKey = this.$getCookie('access_key')
      if (!uid) {
        sessionStorage.removeItem('userInfo');
        this.authenticationDialog = true;
        logToES({event_name: CUSTOM_EVENT.AQ_SHOW_REGISTER_DIALOG.name, value: 1, text: CUSTOM_EVENT.AQ_SHOW_REGISTER_DIALOG.desc});
        return;
      }
      /**
       * 检查登录状态
       */
      getMyInfo().then(res => {
        if (res.data.code !== 0) {
          sessionStorage.removeItem('userInfo');
          this.authenticationDialog = true;
          logToES({event_name: CUSTOM_EVENT.AQ_SHOW_REGISTER_DIALOG.name, value: 1, text: CUSTOM_EVENT.AQ_SHOW_REGISTER_DIALOG.desc});
          return;
        }
        sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
        this.showADLoadingCover();
      }).catch(err => {
        try {
          if (err.response.status === 401) {
            sessionStorage.removeItem('userInfo');
            this.authenticationDialog = true;
            logToES({event_name: CUSTOM_EVENT.AQ_SHOW_REGISTER_DIALOG.name, value: 1, text: CUSTOM_EVENT.AQ_SHOW_REGISTER_DIALOG.desc});
          }
        } catch (ex){
          console.error(err);
          this.$message.error(err);
          this.downloading = false;
        }
      });
    },
    /**
     * 显示广告界面
     * 下载模板
     */
    showADLoadingCover: function () {
      logToES({event_name: CUSTOM_EVENT.AQ_SHOW_ADVERTISING_DIALOG.name, value: 1, text: CUSTOM_EVENT.AQ_SHOW_ADVERTISING_DIALOG.desc});
      this.templateUrl = null;

      /**
       * 显示弹窗
       * @type {boolean}
       */
      this.generateContractDialog = true;
      this.contractGenerating = true;
      this.contractLoadingCoverShowing = true;
      this.generateSystemTemplate().then(blob => {
        this.templateUrl = window.URL.createObjectURL(blob);
        this.contractGenerating = false;
        logToES({event_name: CUSTOM_EVENT.AQ_BACKGROUND_DOWNLOADING_SUCCESS.name, value: 1, text: CUSTOM_EVENT.AQ_BACKGROUND_DOWNLOADING_SUCCESS.desc});
        this.generateComplete();
      }).catch(err => {
        this.$message.error(err);
        this.downloading = false;
        this.contractGenerating = false;
        this.contractLoadingCoverShowing = false;
        this.generateContractDialog = false;
        logToES({event_name: CUSTOM_EVENT.AQ_BACKGROUND_DOWNLOADING_SUCCESS.name, value: 1,
          text: `${CUSTOM_EVENT.AQ_BACKGROUND_DOWNLOADING_SUCCESS.desc} --- ${err}`});
      });
    },
    /**
     * 假的计时器
     */
    fakeTimerOut: function() {
      this.contractLoadingCoverShowing = false;
      logToES({event_name: CUSTOM_EVENT.AQ_ADVERTISING_DIALOG_TIME_OVER.name, value: 1, text: CUSTOM_EVENT.AQ_ADVERTISING_DIALOG_TIME_OVER.desc});
      this.generateComplete();
    },
    /**
     * 使用模板和答案生成合同文档
     */
    generateSystemTemplate() {
      return new Promise((resolve, reject) => {
        const answers = this.answerSheet.map(as => {
          const copyAnswer = Object.assign({}, as.answer);
          copyAnswer.content = convertContentToStringIFNotNull(copyAnswer.content);
          copyAnswer.updateTime = Date.now();
          return copyAnswer;
        });
        const params = {answers: answers, fileId: this.fileId, publicUserAuthToken: this.parsedParam.authInfo};

        downloadSystemTemplate(params).then(res => {
          resolve(res.data);
        }).catch(err => {
          console.error(err);
          reject('生成文档失败');
        });
      });
    },
    /**
     * 文件是否下载完成， 隐藏弹窗，重置状态
     */
    generateComplete: function() {
      /**
       * 必须等待网络请求和假的下载弹窗都完成之后
       */
      if (this.contractGenerating || this.contractLoadingCoverShowing) {
        return
      }

      /**
       * 隐藏弹窗
       * @type {boolean}
       */
      this.generateContractDialog = false;

      /**
       * 下载失败才会是空
       */
      if (this.templateUrl === null) {
        return;
      }

      this.downloadSystemTemplateByUrl(this.templateUrl);
    },
    /**
     * 使用二进制文件生成的URL下载文档
     */
    downloadSystemTemplateByUrl(url) {
      logToES({event_name: CUSTOM_EVENT.AQ_DOWNLOAD_LOCAL_FILE.name, value: 1, text: CUSTOM_EVENT.AQ_DOWNLOAD_LOCAL_FILE.desc});

      let a = document.createElement('a');
      let filename = `${Date.now()}.docx`;
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);

      this.downloading = false;

      // 跳转到创建团队页面
      if (sessionStorage.getItem("userInfo")) {
        // let loginUser = JSON.parse(sessionStorage.getItem("userInfo"));
        // if (loginUser['inviteCode'] === 'CLMDT-CLM73EE4497') {
        //   this.$router.push({
        //     name: 'Home',
        //   })
        //   return;
        // }
        this.$router.push({
          name: 'contractIndex',
        })
        return;
      }

      /**
       * 返回首页
       * @type {string}
       */
      this.$router.push({
        name: 'Home',
      })
    },
    validateEmail:function () {
      const emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!this.userInfo.email) {
        this.$message.error('请填写邮箱信息')
        return false;
      }
      if(!emailReg.test(this.userInfo.email)){
        this.$message.error('请输入正确的邮箱')
        return false;
      }
      return true;
    },
    /**
     * 验证表单
     * @param userInfo 提交的关于用户的一些信息。
     * @param answerSheets 答卷信息
     */
    validateForm:function(userInfo,answerSheets){
      for (let i = 0; i < answerSheets.length; i++) {
        let answerSheet = answerSheets[i];
        let questionVisible = this.showQuestion(answerSheet, this.answerSheetMap);
        if (questionVisible && !this.validateAnswerSheet(answerSheet)) {
          // 只有可见状态的问题才去验证它的答案。
          this.alertAnswerNotValidate(answerSheet);
          return false;
        }
      }
      return true;
    },
    //验证某一个答案是否合法
    validateAnswerSheet:function(answerSheet){
      if (single(answerSheet.question)||bool(answerSheet.question)) {
        //如果是单选,或者布尔类型的问题
        return answerSheet.answer.content !== null && answerSheet.answer.content !== undefined &&answerSheet.answer.content.length > 0;
      }
      //其它类型不验证。
      return true;
    },
    //提示用户哪一个问题不合法
    alertAnswerNotValidate:function(answerSheet){
      this.$message.error(answerSheet.question.answerName + '不能为空');
    },
    /**
     * 填写的答案已经改变
     */
    answerChanged: function (answerSheet) {
      // this.$emit('answer-changed', answerSheet)
      this.saveAnswerSheetInCache();
      if (this.$refs['content']) {
        let visibleDependencyAnswerSheet = getVisibleDependencyAnswerSheet(answerSheet.answer.question, this.groupAnswerSheets);
        let involvedQuestionVisible = [];
        for (let i = 0; i < visibleDependencyAnswerSheet.length; i++) {
          let questionVisible = this.showQuestion(visibleDependencyAnswerSheet[i], this.answerSheetMap);
          involvedQuestionVisible.push({uuid:visibleDependencyAnswerSheet[i].questionUUID,visible:questionVisible})
        }
        answerSheet.involvedQuestionVisible = involvedQuestionVisible;
        let scrollToRect = false;
        if (this.answerQuestionViewMode === ANSWER_QUESTION_VIEW_MODEL.ANSWER_SHEET_DOC_PREVIEW) {
          scrollToRect = answerSheet.scrollToRect;
        }
        let res = this.$refs['content'].answerChanged(answerSheet.answer.questionUUID, answerSheet.answer.answer.content, answerSheet.involvedQuestionVisible, scrollToRect);
        this.recordAnswerChangedRelatedRect(answerSheet.answer.questionUUID, res.count);
      }
      if (!answerSheet.answer.question.parentId && answerSheet.answer.question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
        // 回答的组织名称
        const questionId = answerSheet.answer.question.id;
        // 查询爱企查，回答相关子问题
        // const answerContent = answerSheet.answer.answer.content;
        this.requestAiQiChaAndAnswerSubQuestions(answerSheet, questionId);
      }
      logToES({event_name:CUSTOM_EVENT.AQ_ANSWER_CONTENT_CHANGE.name,value:1, text: CUSTOM_EVENT.AQ_ANSWER_CONTENT_CHANGE.desc+',name:'+answerSheet.answer.question.answerName})
    },
    /**
     * 记录当前改变的问题影响了多少个区域
     *
     */
    recordAnswerChangedRelatedRect: function (questionUUID,count) {
      if (this.answerQuestionViewMode === ANSWER_QUESTION_VIEW_MODEL.ONLY_ANSWER_SHEET) {
        this.unreadAnswerChangedRelatedRectMap.set(questionUUID, count);
      }
    },
    requestAiQiChaAndAnswerSubQuestions: function (answerSheet, questionId) {
      const answerContent = answerSheet.answer.answer.content;
      if (!answerContent || answerContent.length === 0) {
        return;
      }
      if (this.orq[questionId] && this.orq[questionId]['requesting'] === true) {
        this.orq[questionId]['waitingRequest'] = true;
        this.orq[questionId]['recentAnswerSheet'] = answerSheet;
        return;
      } else {
        this.orq[questionId] = {
          'waitingRequest': false,
          'recentAnswerSheet': answerSheet
        }
      }
      this.orq[questionId]['requesting'] === true;
      const timestamp = new Date().getTime();
      this.orq[questionId]['requesttime'] = timestamp;
      getAiQiChaCompanyData(answerContent, timestamp).then(res => {
        console.log('getAiQiChaCompanyData  res === ', res);
        if (res.data.timestamp != this.orq[questionId]['requesttime']) {
          console.log('时间不对！！！！！！requesttime === ' + this.orq[questionId]['requesttime']);
          return;
        }
        const currentAnswerSheet = this.findAnswerSheetByUUID(this.orq[questionId]['recentAnswerSheet']['answer']['question']['uuid'])
        const currentAnswerName = this.orq[questionId]['recentAnswerSheet']['answer']['question']['answerName']
        if (res.data.data[answerContent] != null) {
          currentAnswerSheet['finded'] = true;
        } else {
          currentAnswerSheet['finded'] = false;
        }
        for (let i = 0; i < this.answerSheet.length; i++) {
          const item = this.answerSheet[i];
          if (item.question['parentId'] === questionId) {
            if (res.data.data[answerContent] != null) {
              const detail = res.data.data[answerContent]['detail'];
              const itemAnswerName = item.question.answerName;
              const itemKey = itemAnswerName.substring(currentAnswerName.length + 1);
              const companyInfoKey = COMPANY_BASIC_INFO_MAPPING[itemKey];
              if (detail[companyInfoKey]) {
                item.answer.content = detail[companyInfoKey];
                item.question.extraUIInfo.defaultShow = false;
              } else {
                item.answer.content = '请填写该组织的' + itemKey;
                item.question.extraUIInfo.defaultShow = true;
              }
            } else {
              item.answer.content = '暂未找到该组织';
              item.question.extraUIInfo.defaultShow = true;
            }
          }
        }
        this.orq[questionId]['requesting'] === false;
        if (this.orq[questionId]['waitingRequest'] === true) {
          this.requestAiQiChaAndAnswerSubQuestions(this.orq[questionId]['recentAnswerSheet'], questionId)
        }
      })
    },
    findAnswerSheetByUUID: function (questionUUID) {
      for (let i = 0; i < this.answerSheet.length; i++) {
        if (String(questionUUID) === String(this.answerSheet[i].question.uuid)) {
          return this.answerSheet[i];
        }
      }
      return null;
    },
    saveAnswerSheetInCache:function () {
      const cache = {};
      this.answerSheet.forEach(a => {
        cache[getQuestionAnswerCacheKey(a.answer.questionId,a.answer.answerType)] = a.answer.content;
      })
      localStorage.setItem(`answerCache${this.fileId}`, JSON.stringify(cache));
    },

    loadAnswerSheetCache: function () {
      const cache = localStorage.getItem(`answerCache${this.fileId}`)
      if (!cache) {
        return {}
      }
      return JSON.parse(cache);
    },
    clearAnswerSheetCache:function () {
      localStorage.removeItem(`answerCache${this.fileId}`)
    },

    initEditor:function(){
      return getMetaProData(this.fileId,  this.parsedParam.authInfo).then(res => {
        console.log('getFileMetaProData  res === ', res);
        const {code, data} = res.data;
        if (code === 0) {
          // docContent.blocks = convertAnswerSheetIntroToEditorJsBlocks(this.answerSheetIntroInfo).concat(docContent.blocks);
          this.docContent = data;
          console.log('content',this.docContent)
          logToES({event_name:CUSTOM_EVENT.AQ_SYSTEM_DOC_DATA_LOADED.name,value:1, text: CUSTOM_EVENT.AQ_SYSTEM_DOC_DATA_LOADED.desc});
        }
      })
    },
    handelEditorMessages: function (msg) {
      console.log('received editor msg**********');
      console.log(msg)
      for (let i = 0; i < msg.length; i++) {
        this.handelEditorMessage(msg[i]);
      }
    },
    handelEditorMessage: function (msg) {
      // if (msg.action === EditorMsgType.QUESTION_LIST_ACCESS) {
      //     this.questionListAccess(msg);
      // } else if (msg.action === EditorMsgType.QUESTION_ACCESS) {
      //     this.questionAccess(msg)
      // } else if (msg.action === EditorMsgType.FOCUS_QUESTION) {
      //     this.$refs.question.focusQuestionByUUID(msg.params.questionUUID);
      // } else if (msg.action === EditorMsgType.ADD_OR_LINK) {
      //     if(!this.checkBeforeLink(msg)){
      //         return
      //     }
      //     this.$refs.question.openAddOrLinkQuestionDialog(msg.params, msg.callback);
      // } else

      if (msg.action === EditorMsgType.SHOW_MESSAGE) {
        ElMessage({
          showClose: true,
          message: msg.params.message,
          type: msg.params.type
        });
      }
    },
    // //将目前所有的问题返回给editorJs
    // questionListAccess: function (msg) {
    //     if (msg.callback) {
    //         let items = this.$refs.question.getItems().map(item => {
    //             return {answerName: item.answerName, uuid: item.uuid, answerType: item.answerType}
    //         })
    //         // console.log(items)
    //         msg.callback(items);
    //     }
    // },
    // questionAccess: function (msg) {
    //     if (msg.callback) {
    //         const question = this.$refs.question.findItemByUUID(msg.params.uuid);
    //         // console.log('questionAccess  question  === ', question);
    //         msg.callback(question);
    //     }
    // },
    getAnswerRefName: function (questionId) {
      return 'answerSheet' + questionId;
    },
    editorReady:function(){
      this.refreshEditorQuestionInfo();
      this.doAnswerAllQuestion();
    },
    refreshEditorQuestionInfo:function(){
      this.$refs['content'].refreshQuestions(this.answerSheet.map(answerSheet=>answerSheet.question));
    },
    doAnswerAllQuestion:function () {
      // this.answerSheet.forEach(as => {
      //     let docAnswerModeRef = this.$refs[this.getAnswerRefName(as.question.id)];
      //     if (docAnswerModeRef && docAnswerModeRef.notifyAnswerChanged) {
      //         docAnswerModeRef.notifyAnswerChanged(false);
      //     }
      // });
      const answers = [];
      for (let j = 0; j < this.answerSheet.length; j++) {
        const item = this.answerSheet[j];
        const answerSheet = { "answer": item };
        let visibleDependencyAnswerSheet = getVisibleDependencyAnswerSheet(answerSheet.answer.question, this.groupAnswerSheets);
        let involvedQuestionVisible = [];
        for (let i = 0; i < visibleDependencyAnswerSheet.length; i++) {
          let questionVisible = answerSheetShouldShowQuestion(visibleDependencyAnswerSheet[i], this.answerSheetMap);
          involvedQuestionVisible.push({uuid:visibleDependencyAnswerSheet[i].questionUUID,visible:questionVisible})
        }
        answers.push({
          questionUUID: answerSheet.answer.questionUUID,
          answer: answerSheet.answer.answer.content,
          subQuestionsVisible: involvedQuestionVisible
        });
        if (!answerSheet.answer.question.parentId && answerSheet.answer.question.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value) {
          // 回答的组织名称
          const questionId = answerSheet.answer.question.id;
          // 查询爱企查，回答相关子问题
          // const answerContent = answerSheet.answer.answer.content;
          this.requestAiQiChaAndAnswerSubQuestions(answerSheet, questionId);
        }
      }
      if (this.$refs['content']) {
        this.$refs['content'].answerAllQuestion(answers);
      }
    },
    authenticationSuccess: function () {
      this.authenticationDialog = false;
      if (!sessionStorage.getItem("userInfo")) {
        getMyInfo().then(res => {
          if (res.data.code === 0) {
            let loginUser = res.data.userInfo;
            sessionStorage.setItem('userInfo', JSON.stringify(loginUser))
            if (res.data.teamInfo) {
              sessionStorage.setItem('teamInfo', JSON.stringify(res.data.teamInfo))
            }
            logToES({event_name: CUSTOM_EVENT.AQ_REGISTER_SUCCESS.name, value: 1, text: CUSTOM_EVENT.AQ_REGISTER_SUCCESS.desc});
            this.showADLoadingCover();
          } else if (res.data.code === 401){
            this.authenticationDialog = true;
          } else {
            this.$message.error('查询登陆状态失败');
            console.error('authenticationSuccess  getMyInfo  res:', res);
          }
        }).catch(err => {
          this.$message.error('查询登陆状态失败');
          console.error('authenticationSuccess  getMyInfo  err:', err);
        })
      } else {
        logToES({event_name: CUSTOM_EVENT.AQ_REGISTER_SUCCESS.name, value: 1, text: CUSTOM_EVENT.AQ_REGISTER_SUCCESS.desc});
        this.showADLoadingCover();
      }
    }
  },
  components: {
    TemplateAnswerSheetIntro,
    DocQuestionAnswerMode,
    DocContent,
    Mask,
    /**
     * 后面使用异步方式引用
     */
    AuthorizeUserComponent: defineAsyncComponent(() => import(/* webpackChunkName: "signup-component" */'../components/account/AuthorizeUserComponent')),
    SmartContractGenerateLoadingCover: defineAsyncComponent(() => import(/* webpackChunkName: "smart-contract-loading-cover" */'../components/SmartContractGenerateLoadingCover')),
  }
};
</script>
<style scoped>
.el-icon-loading {
  font-size: 30px;
}
</style>
