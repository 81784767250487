//变量类型枚举
import {itemJsonStrPro2Obj, itemObjProp2JSONStr} from "../../util";
import {bool, isTextType, multiple, single, singleOrMultiple} from "../../components/common/question";

export const COMPANY_BASIC_INFO_MAPPING = {
    '法定代表人': 'operName',
    '注册资本': 'registCapi',
    '统一社会信用代码': 'creditCode',
    '工商注册号': 'no',
    '企业类型': 'econKind',
    '行政区划': 'null', // 企查查接口没有返回行政区划
    '注册地址': 'address',
    '经营范围': 'scope',
    '纳税人识别号': 'creditCode',
    '组织机构代码': 'orgNo',
    '成立日期': 'startDate'
}
export const VARIABLE_TYPE_OPTIONS = {
    TEXT: {name: '文本', value: 1, icon: 'icon-wenben'},
    BOOL: {name: '是否', value: 2, icon: 'icon-buerzhi'},
    SINGLE_OPTION: {name: '单选', value: 3, icon: 'icon-iconoption'},
    MULTIPLE_OPTION: {name: '多选', value: 4, icon: 'icon-xuanze'},
    ORGANIZATION: {name: '组织名称', value: 5, icon: 'el-icon-office-building'},
}
//布尔类型枚举
export const BOOL_OPTIONS = {
    TRUE: {name: '是', value: '1'},
    FALSE: {name: '否', value: '0'},
}
//逻辑板块，依赖问题和答案的关系
export const SHOW_CONTROL_RELATIONSHIP_OPTIONS = {
    VALUE_EQ: {name: '值为', value: 0},
    SHOW_IF: {name: '存在', value: 1},
    HIDE_IF: {name: '不存在', value: 2},
}

//逻辑问题的答案一致后的行为
export const SHOW_CONTROL_OPTIONS = {
    SHOW: {name: '显示', value: 1},
    HIDE: {name: '隐藏', value: 0},
}
//question对象需要转换的属性
export const Question_NEED_CONVERTE_PROP = ['options', 'showControlAnsVal', 'defaultAnswer'];

//编辑模板里面的tab名字
export const EDIT_MODEL = {
    BASIC: "basic",
    LOGIC: "logic"
}
//当前处于哪个模式下（编辑模式、预览模式）
export const ACTIVE_MODEL = {
    EDIT_MODEL: 'edit_model',
    ANSWER_MODEL: 'answer_model'
};
//答题界面的模式
export const ANSWER_QUESTION_VIEW_MODEL = {
    ONLY_ANSWER_SHEET: 'only_answer_sheet',// 只显示答题界面
    ONLY_DOC_PREVIEW: 'only_doc_preview',// 只显示文档界面
    ANSWER_SHEET_DOC_PREVIEW: 'answer_sheet_doc_preview'// 同时显示答题界面和文档界面
};
export const EditorMsgType = {
    ADD: "add",
    LINK: "link",
    ADD_OR_LINK: "addOrLink",
    QUESTION_ACCESS: "question-access",
    QUESTION_LIST_ACCESS: "question-list-access",
    FOCUS_QUESTION: "focus-question",
    SHOW_MESSAGE: "show-message",
    DELETE_VARIABLE: "delete-variable",
}
/**
 * 答卷的来源类型
 * @type {{PC: number, MINI_APP: number}}
 */
export const AnswerSheetSourceType = {
    PC: 0,//电脑端
    MINI_APP: 1,//微信小程序
};
/**
 * 将来源类型转化成字符串
 * @param sourceType
 * @return {string}
 */
export const formatSourceType = function (sourceType) {
    if (sourceType === AnswerSheetSourceType.PC) {
        return '网页';
    } else if (sourceType === AnswerSheetSourceType.MINI_APP) {
        return '小程序'
    }
};

/**
 * 将从网络中获取出来的json字符串属性转换为对象
 * @param question
 */
export const questionStrProp2Obj = function (question) {
    for (const i in Question_NEED_CONVERTE_PROP) {
        itemJsonStrPro2Obj(question, Question_NEED_CONVERTE_PROP[i])
    }
}

/**
 * 将question对象中的对象属性转换为json字符串
 * @param question
 */
export const questionObjProp2JSON = function (question) {
    for (const i in Question_NEED_CONVERTE_PROP) {
        itemObjProp2JSONStr(question, Question_NEED_CONVERTE_PROP[i]);
    }
};
/**
 * 根据问题id和回答的类型，获取答案储存的key
 * @param questionId 问题id
 * @param answerType 回答类型
 * @return {string}
 */
export const getQuestionAnswerCacheKey = function (questionId, answerType) {
    return `${questionId}_${answerType}`;
}
/**
 * 将answer转换为回答的缓存。
 * @param answers
 */
export const getAnswerCacheMap = function (answers) {
    const questionAnswerMap = new Map();
    for (let i = 0; i < answers.length; i++) {
        //这里之所以以id和答案类型作为key，而不是直接以id作为key，是为了应对如果问题本身的答案类型发生了变化，那么原来缓存的答案内容就无效了。
        const key = getQuestionAnswerCacheKey(answers[i].questionId, answers[i].answerType)
        questionAnswerMap.set(key, answers[i])
    }
    return questionAnswerMap;
}

/**
 * 获取一个问题答案的默认值
 * @param question 问题
 * @param defaultEmpty 是否默认答案为空
 */
export const getQuestionDefaultAnswer = function (question, defaultEmpty) {
    if (question.answerType === VARIABLE_TYPE_OPTIONS.TEXT.value) {
        return defaultEmpty ? null : "";
    } else if (question.answerType === VARIABLE_TYPE_OPTIONS.BOOL.value) {
        return defaultEmpty ? null : BOOL_OPTIONS.TRUE.value;
    } else if (question.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value) {
        if (defaultEmpty) {
            return null;
        }
        if (question.options && question.options.length > 0) {
            // return question.options[0];
            return question.options[0].name ? question.options[0].name : null;
        }
        return '';
    } else if (question.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value) {
        if (defaultEmpty) {
            return [];
        }
        if (question.options && question.options.length > 0) {
            // return [question.options[0]];
            return question.options[0].name ? [question.options[0].name] : [];
        }
        return [];
    } else {
        return "";
    }
}
export const getItemDefaultValue = function (item) {
    if (item.answerType === VARIABLE_TYPE_OPTIONS.BOOL.value) {
        return BOOL_OPTIONS.TRUE.value;
    } else if (item.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value) {
        return null;
    } else if (item.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value) {
        return [];
    } else {
        return "";
    }
};
/**
 * 如果问题本身指定了默认答案，则返回指定的默认答案，否则则系统生成一个默认的答案。
 * @param question
 * @param defaultEmpty
 * @return {null|string|string|[]|[*]}
 */
export const getOrProduceQuestionDefaultAnswer = function (question, defaultEmpty) {
    if (question.defaultAnswer === null || question.defaultAnswer === undefined) {
        return getQuestionDefaultAnswer(question, defaultEmpty);
    }
    if (typeof question.defaultAnswer === "number") {
        return String(question.defaultAnswer);
    }
    return question.defaultAnswer;
}
export const produceAnswer = function (answerType, content, questionId, answerSheetId, fileId) {
    return {
        answerType: answerType,
        content: content,
        questionId: questionId,
        fileId: fileId,
        answerSheetId: answerSheetId
    }
};
export const convertContentToStringIFNotNull = function (content) {
    if (content === null) {
        return content;
    }
    if (typeof content !== 'string') {
        return JSON.stringify(content);
    }
    return content;
}
export const convertQuestionProperty = function (questions) {
    for (let i = 0; i < questions.length; i++) {
        //将json字符串属性解析为对象
        questionStrProp2Obj(questions[i]);
    }
    return questions;
}
//该问题是否有完整的依赖问题条件（依赖问题、值、控制行为（显示/隐藏））
export const questionDependencyComplete = function (question) {
    return !(question.showControlAnsId === null ||
        ((question.showControlRelationshipType === SHOW_CONTROL_RELATIONSHIP_OPTIONS.VALUE_EQ.value || question.showControlRelationshipType === null) && question.showControlAnsVal === null)
        || question.showControlAction === null);
}
/**
 * 比较数组1和数组2是否相等。
 * @param {[string]} array1
 * @param {[string]} array2
 * @param {boolean} and true：表示确认两个数组中的元素是否完全一样
 *                      false：表示求是否两个数组是否存在交集，只要这两个元素同时有一个元素，则返回true
 * @return {boolean}
 */
export const compareArray = function (array1, array2, and) {
    if (and) {
        return JSON.stringify(Array.from(array1).sort()) === JSON.stringify(Array.from(array2).sort());
    } else {
        return array1.filter(a => array2.indexOf(a) > -1).length > 0;
    }
};

/**
 * 在答卷界面是否应该显示某个问题
 * @param answerSheetItem 某一个问题以及它对应的答案
 * @param answerSheetMap 问题id->问题以及它对应答案  的map
 * @return {boolean} 是否应该显示
 */
export const answerSheetShouldShowQuestion = function (answerSheetItem, answerSheetMap) {
    let question = answerSheetItem.question

    // 如果是子问题，则子问题的显隐依赖于父问题
    if (question.parentId) {
        let parentAnswerSheet = answerSheetMap.get(question.parentId);
        // 如果父问题不存在，则不显示该子问题。
        if (!parentAnswerSheet) {
            return false
        }
        // 如果父问题都没有显示，则这个问题本身也不显示
        if (!answerSheetShouldShowQuestion(parentAnswerSheet, answerSheetMap)) {
            return false;
        }
    }

    // 如果有逻辑依赖，则根据逻辑依赖的对象来决定显隐。
    if (!questionDependencyComplete(question)) {
        return true
    }
    // 如果明确指定了
    let controlAnswerSheet = answerSheetMap.get(question.showControlAnsId)
    let actionShow = question.showControlAction === SHOW_CONTROL_OPTIONS.SHOW.value;
    if (question.showControlRelationshipType === SHOW_CONTROL_RELATIONSHIP_OPTIONS.VALUE_EQ.value) {
        //如果明确指定的依赖问题没有在内容中关联区域，则不显示。
        if (!controlAnswerSheet) {
            return false
        }
        // 如果它所依赖的问题都没有显示，则这个问题本身也不显示
        if (!answerSheetShouldShowQuestion(controlAnswerSheet, answerSheetMap)) {
            return false;
        }
        // console.log('question.parentId:' + question.parentId, question,JSON.stringify(question));

        //答案是否正确
        let answerRight;
        if (multiple(controlAnswerSheet.question)) {
            answerRight = compareArray(controlAnswerSheet.answer.content, question.showControlAnsVal, false)
        } else {
            answerRight = String(controlAnswerSheet.answer.content) === String(question.showControlAnsVal)
        }
        if (!actionShow) {
            //如果是控制行为是隐藏，那么答案正确就隐藏，答案错误就显示。
            return !answerRight
        }
        //答案正确就显示、答案错误就隐藏
        return answerRight
    } else if (question.showControlRelationshipType === SHOW_CONTROL_RELATIONSHIP_OPTIONS.SHOW_IF.value) {
        //如果明确指定的依赖问题没有在内容中关联区域，则不显示(因为HIDE_IF是当依赖问题显示时显示本问题)。
        if (!controlAnswerSheet) {
            return !actionShow
        }
        if (actionShow) {
            return answerSheetShouldShowQuestion(controlAnswerSheet, answerSheetMap);
        } else {
            return !answerSheetShouldShowQuestion(controlAnswerSheet, answerSheetMap);
        }
    } else if (question.showControlRelationshipType === SHOW_CONTROL_RELATIONSHIP_OPTIONS.HIDE_IF.value) {
        //如果明确指定的依赖问题没有在内容中关联区域，则显示(因为HIDE_IF是当依赖问题隐藏时显示本问题)。
        if (!controlAnswerSheet) {
            return actionShow
        }
        if (actionShow) {
            return !answerSheetShouldShowQuestion(controlAnswerSheet, answerSheetMap);
        } else {
            return answerSheetShouldShowQuestion(controlAnswerSheet, answerSheetMap);
        }
    }
}
/**
 * 问题是否没有选项。
 * @param question
 * @return {*|boolean}
 */
export const noOptions = function (question) {
    return singleOrMultiple(question) && question.options && question.options.length === 0;
};
/**
 * 更新group的sortIndex
 * @param groups
 */
export const updateGroupSortIndex = function (groups) {
    const updatedGroups = [];
    for (let i = 0; i < groups.length; i++) {
        if (groups[i].sortIndex !== i) {
            groups[i].sortIndex = i;
            updatedGroups.push(groups[i]);
        }
    }
    return updatedGroups;
}
/**
 * 修正组织结构的子问题的位置。
 * @param questions
 * @return {*[]}
 */
export const fixOrganizationQuestionIndex = function (questions) {
    const childQuestionMap = new Map();
    const newQuestions = [];
    for (let i = 0; i < questions.length; i++) {
        let question = questions[i];
        if (question.parentId) {
            let children = childQuestionMap.get(question.parentId);
            if (!children) {
                children = [];
                childQuestionMap.set(question.parentId, children);
            }
            children.push(question);
        } else {
            newQuestions.push(question);
        }
    }
    return newQuestions.flatMap(q => {
        let questions = [q];
        if (childQuestionMap.get(q.id)) {
            questions = questions.concat(childQuestionMap.get(q.id))
        }
        return questions;
    })
}
/**
 * 表示添加问题还是关联问题。
 * @type {{RELATED: string, ADD: string}}
 */
export const ADD_RELATED_MODE = {
    ADD: 'add',
    RELATED: 'related'
};

/**
 * 获取该问题会影响到的子孙辈answerSheet
 * @param question
 * @param groupAnswerSheets
 * @return {[]}
 */
export const getVisibleDependencyAnswerSheet = function (question, groupAnswerSheets) {
    let dependencyItQuestions = new Set();
    dependencyItQuestions.add(question.id);
    // 利用被依赖的问题一定在前面的性质，我们可以从前到后遍历，从而获取依赖于该问题的子孙辈问题。
    let result = [];
    for (let i = 0; i < groupAnswerSheets.length; i++) {
        for (let j = 0; j < groupAnswerSheets[i].length; j++) {
            let item = groupAnswerSheets[i][j];
            let question = item.question
            let parentId = question.parentId;
            let showControlAnsId = question.showControlAnsId;
            if (dependencyItQuestions.has(parentId) || dependencyItQuestions.has(showControlAnsId)) {
                dependencyItQuestions.add(question.id);
                result.push(item);
            }
        }
    }
    return result;
}

/**
 * 修复问题答案（可能在两次过程中问题本身发生了变化，比如类型、选项）
 * @param question 问题
 * @param answer 答案
 */
export const fixAnswerContent = function (question, answer) {
    let options = question.options;
    let optionNames = [];
    if (options && options.length > 0) {
        optionNames = options.map(option => option.name);
    }
    if (!oldAnswerTypeStillValidate(question, answer)) {
        answer.content = getQuestionDefaultAnswer(question, true);
        return
    }
    if (single(question) && optionNames.indexOf(answer.content) === -1) {
        answer.content = getQuestionDefaultAnswer(question, true);
        return
    }
    if (multiple(question)) {
        answer.content = answer.content.filter(opt => optionNames.indexOf(opt) >= 0);
        return
    }
}
/**
 * 当前answer的答案类型是否匹配当前问题的类型。
 * @param question
 * @param answer
 * @return {boolean}
 */
export const oldAnswerTypeStillValidate = function (question, answer) {
    if (isTextType(question) && typeof answer.content === 'object') {
        return false;
    }
    if (bool(question) && !(String(answer.content) === String(BOOL_OPTIONS.TRUE.value) || String(answer.content) === String(BOOL_OPTIONS.FALSE.value))) {
        return false;
    }
    if (single(question) && typeof answer.content === 'object') {
        return false;
    }
    if (multiple(question) && !Array.isArray(answer.content)) {
        return false;
    }
    return true;
}

export const getObjectValues = function (obj) {
    let values = [];
    for (let key in obj) {
        values.push(obj[key]);
    }
    return values;
};

export const getShowControlRelationShipSelectInfo = function () {
    return getObjectValues(SHOW_CONTROL_RELATIONSHIP_OPTIONS)
};
