<template>
  <div id="mask" @click="handleClickMask" :style="{display:visible?visible:'block','z-index':zIndex > 0 ? zIndex : 999 }" ref="mask">
      <slot></slot>

  </div>
</template>

<script>
export default {
  name: "Mask",
  props:['display','zIndex','locateBody'],
  data:function () {
    return {
      visible: this.display
    }
  },
  watch:{
    display(val){
      this.visible = val;
    }
  },
  mounted() {
    if(this.locateBody){
      document.body.appendChild(this.$refs.mask);
    }
  },
  methods:{
    openMask(){
      this.visible = 'block';
    },
    closeMask(){
      this.visible = 'none';
    },
    handleClickMask(e){
      console.log(e)
      if(e.target == this.$refs.mask){
        this.$emit('clickVacancy')
      }
    }
  }
}
</script>

<style scoped>
#mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.3);
}
</style>
