// let config
export const DEPLOY_TYPE = {
    METAGO: 'METAGO',
    RELEASE: 'RELEASE',
    TEST:'TEST',
    TESTDEV:'TESTDEV',
    TESTCLM:'TESTCLM',
    CLM:'CLM',
    LOCALCLM:'LOCALCLM',
    ALPHA:'ALPHA',
    BETA: 'BETA',
    EXP: 'EXP'
}

// 根据域名切换配置
const deployType = function () {
    if (location.hostname === 'metago.cn') {
        return DEPLOY_TYPE.METAGO;
    } else if (location.hostname === 'cloud.metasotalaw.cn') {
        return DEPLOY_TYPE.RELEASE;
    }
    else if(location.hostname== 'testmetago.metasotalaw.cn'){
        return DEPLOY_TYPE.TESTDEV;
    } else if (location.hostname== 'clm.metasotalaw.cn') {
        return DEPLOY_TYPE.TESTCLM;
    } else if (location.hostname== 'clm.metago.cn') {
        return DEPLOY_TYPE.CLM;
    }else if(location.hostname=='localclm.metaeye.cn'){
        return DEPLOY_TYPE.LOCALCLM;
    }else if(location.hostname == 'metagobeta.metasotalaw.cn'){
        return DEPLOY_TYPE.BETA;
    }else if(location.hostname == 'metagoalph.metasotalaw.cn'){
        return DEPLOY_TYPE.ALPHA;
    }else if(location.hostname == 'metagoexp.metasotalaw.cn'){
        return DEPLOY_TYPE.EXP;
    }
    else {
        return DEPLOY_TYPE.TEST;
    }
}();
const config = function() {
    if (deployType === DEPLOY_TYPE.METAGO) {
        // metago配置
        return  {
            deployType: deployType,
            baseUrl: 'https://metago.cn/',
            esProjectName:'metago',
            uranusDomain: 'metago.cn',
            uranusUrl: 'https://metago.cn',
            xiezuocatUrl: "https://xiezuocat.com",
            xiezuocatBackendUrl: "https://xiezuocat.com/html",
            neptuneDomain:"metaso.cn",
            guestInviteCode:"GUEST-CLM0018C779",
            registerInviteCode:"CLMDT-CLM73EE4497"
        }
    } else if (deployType === DEPLOY_TYPE.CLM) {
        return  {
            deployType: deployType,
            baseUrl: 'https://clm.metago.cn/',
            esProjectName:'clm-metago',
            uranusDomain: 'clm.metago.cn',
            uranusUrl: 'https://clm.metago.cn',
            xiezuocatUrl: "https://xiezuocat.com",
            xiezuocatBackendUrl: "https://xiezuocat.com/html",
            neptuneDomain:"metaso.cn",
            guestInviteCode:"GUEST-CLM0018C779",
            registerInviteCode:"CLMDT-CLM73EE4497",
            teamLogoTeamId:"62845585c697a77e67d27412"
        }
    } else if (deployType === DEPLOY_TYPE.RELEASE) {
        // 测试环境配置
        return  {
            deployType: deployType,
            baseUrl: 'https://cloud.metasotalaw.cn/',
            esProjectName:'cloud',
            uranusDomain: 'metasotalaw.cn',
            uranusUrl: 'https://metasotalaw.cn',
            xiezuocatUrl: "https://checker.metasotalaw.cn",
            xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
            neptuneDomain:"metaso.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847"
        }
    } else if (deployType === DEPLOY_TYPE.TEST_DEV) {
        return  {
            deployType: deployType,
            baseUrl: 'https://testmetago.metasotalaw.cn/',
            domain: 'https://testmetago.metasotalaw.cn/',
            esProjectName:'testmetago',
            uranusDomain: 'metasotalaw.cn',
            uranusUrl: 'https://metasotalaw.cn',
            xiezuocatUrl: "https://checker.metasotalaw.cn",
            xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
            neptuneDomain:"metaso.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847",
        }
    } else if (deployType === DEPLOY_TYPE.TESTCLM) {
        return  {
            deployType: deployType,
            baseUrl: 'https://clm.metasotalaw.cn/',
            domain: 'https://clm.metasotalaw.cn/',
            esProjectName:'clm',
            uranusDomain: 'metasotalaw.cn',
            uranusUrl: 'https://metasotalaw.cn',
            xiezuocatUrl: "https://checker.metasotalaw.cn",
            xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
            neptuneDomain:"metasotalaw.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847"
        }
    } else if (deployType === DEPLOY_TYPE.TEST) {
        return  {
            deployType: deployType,
            baseUrl: `http://${location.hostname}:8080/`,
            // baseUrl: `http://172.168.1.136:8001`,
            uranusDomain: 'metasotalaw.cn',
            esProjectName:'cloud_local',
            uranusUrl: 'https://metasotalaw.cn',
            xiezuocatUrl: "http://localhost:8080",
            xiezuocatBackendUrl: "http://localhost:8081/html",
            neptuneDomain: "metasotalaw.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847"
        }
    }else if (deployType === DEPLOY_TYPE.LOCALCLM) {
        return  {
            deployType: deployType,
            baseUrl: `http://${location.hostname}/`,
            // baseUrl: `http://172.168.1.136:8001`,
            uranusDomain: 'localclm.metaeye.cn',
            esProjectName:'cloud_local',
            uranusUrl: 'https://localmetago:6100',
            xiezuocatUrl: "http://localhost:8080",
            xiezuocatBackendUrl: "http://localhost:8081/html",
            neptuneDomain: "metasotalaw.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847",
            noModel: true
        }
    }else if(deployType === DEPLOY_TYPE.TESTDEV){
        return  {
            deployType: deployType,
            baseUrl: 'https://testmetago.metasotalaw.cn/',
            esProjectName:'testmetago',
            uranusDomain: 'metasotalaw.cn',
            uranusUrl: 'https://metasotalaw.cn',
            xiezuocatUrl: "https://checker.metasotalaw.cn",
            xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
            neptuneDomain:"metaso.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847",
            teamLogoTeamId:"5e7db45e58fb5b34e945e7b0",
        }
    }else if(deployType === DEPLOY_TYPE.BETA){
      return  {
        deployType: deployType,
        baseUrl: 'https://metagobeta.metasotalaw.cn/',
        domain: 'https://metagobeta.metasotalaw.cn/',
        esProjectName:'metagobeta',
        uranusDomain: 'metasotalaw.cn',
        uranusUrl: 'https://metasotalaw.cn',
        xiezuocatUrl: "https://checker.metasotalaw.cn",
        xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
        neptuneDomain:"metaso.cn",
        guestInviteCode:"GUEST-CLM3D356A1E",
        registerInviteCode:"CLMDT-CLM46256847",
      }
    }else if(deployType === DEPLOY_TYPE.ALPHA){
      return  {
        deployType: deployType,
        baseUrl: 'https://metagoalph.metasotalaw.cn/',
        domain: 'https://metagoalph.metasotalaw.cn/',
        esProjectName:'metagoalph',
        uranusDomain: 'metasotalaw.cn',
        uranusUrl: 'https://metasotalaw.cn',
        xiezuocatUrl: "https://checker.metasotalaw.cn",
        xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
        neptuneDomain:"metaso.cn",
        guestInviteCode:"GUEST-CLM3D356A1E",
        registerInviteCode:"CLMDT-CLM46256847",
      }
    }else if(deployType === DEPLOY_TYPE.EXP){
      return  {
        deployType: deployType,
        baseUrl: 'https://metagoexp.metasotalaw.cn/',
        domain: 'https://metagoexp.metasotalaw.cn/',
        esProjectName:'metagoexp',
        uranusDomain: 'metasotalaw.cn',
        uranusUrl: 'https://metasotalaw.cn',
        xiezuocatUrl: "https://checker.metasotalaw.cn",
        xiezuocatBackendUrl: "https://checker.metasotalaw.cn/html",
        neptuneDomain:"metaso.cn",
        guestInviteCode:"GUEST-CLM3D356A1E",
        registerInviteCode:"CLMDT-CLM46256847",
      }
    }
    else {
        return {
            deployType: deployType,
            baseUrl: `http://${location.hostname}:8001/`,
            uranusDomain: 'metasotalaw.cn',
            esProjectName:'cloud_local',
            uranusUrl: 'https://metasotalaw.cn',
            xiezuocatUrl: "http://localhost:8080",
            xiezuocatBackendUrl: "http://localhost:8081/html",
            neptuneDomain: "metasotalaw.cn",
            guestInviteCode:"GUEST-CLM3D356A1E",
            registerInviteCode:"CLMDT-CLM46256847"
        }
    }

}()
export default config
