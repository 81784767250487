import {CHART_TYPES} from "./statisticChartConfig";

export const mockData = {
    [CHART_TYPES.LINE]:{
        "labels": [
            "2019/2/6 2:12 - 2019/3/8 2:12",
            "2019/3/8 2:12 - 2019/4/7 2:12",
            "2019/4/7 2:12 - 2019/5/7 2:12",
            "2019/5/7 2:12 - 2019/6/6 2:12",
            "2019/6/6 2:12 - 2019/7/6 2:12",
            "2019/7/6 2:12 - 2019/8/5 2:12",
            "2019/8/5 2:12 - 2019/9/4 2:12",
            "2019/9/4 2:12 - 2019/10/4 2:12",
            "2019/10/4 2:12 - 2019/11/3 2:12",
            "2019/11/3 2:12 - 2019/12/3 2:12",
            "2019/12/3 2:12 - 2020/1/2 2:12",
            "2020/1/2 2:12 - 2020/2/1 2:12",
            "2020/2/1 2:12 - 2020/3/2 2:12",
            "2020/3/2 2:12 - 2020/4/1 2:12",
            "2020/4/1 2:12 - 2020/5/1 2:12",
            "2020/5/1 2:12 - 2020/5/31 2:12",
            "2020/5/31 2:12 - 2020/6/30 2:12",
            "2020/6/30 2:12 - 2020/7/30 2:12",
            "2020/7/30 2:12 - 2020/8/29 2:12",
            "2020/8/29 2:12 - 2020/9/28 2:12",
            "2020/9/28 2:12 - 2020/10/28 2:12",
            "2020/10/28 2:12 - 2020/11/27 2:12",
            "2020/11/27 2:12 - 2020/12/27 2:12",
            "2020/12/27 2:12 - 2021/1/26 2:12",
            "2021/1/26 2:12 - 2021/2/25 2:12",
            "2021/2/25 2:12 - 2021/3/27 2:12",
            "2021/3/27 2:12 - 2021/4/26 2:12",
            "2021/4/26 2:12 - 2021/5/26 2:12",
            "2021/5/26 2:12 - 2021/6/25 2:12",
            "2021/6/25 2:12 - 2021/7/25 2:12",
            "2021/7/25 2:12 - 2021/8/24 2:12",
            "2021/8/24 2:12 - 2021/9/23 2:12",
            "2021/9/23 2:12 - 2021/10/23 2:12",
            "2021/10/23 2:12 - 2021/11/22 2:12",
            "2021/11/22 2:12 - 2021/12/22 2:12",
            "2021/12/22 2:12 - 2022/1/21 2:12",
            "2022/1/21 2:12 - 2022/2/20 2:12",
            "2022/2/20 2:12 - 2022/3/21 22:06"
        ],
        "datasets": [
            {
                "label": null,
                "data": [
                    2,
                    2,
                    1,
                    3,
                    0,
                    0,
                    3,
                    1,
                    0,
                    1,
                    0,
                    0,
                    1,
                    0,
                    0,
                    1,
                    2,
                    2,
                    1,
                    2,
                    0,
                    1,
                    3,
                    1,
                    2,
                    0,
                    2,
                    1,
                    2,
                    2,
                    3,
                    3,
                    0,
                    0,
                    0,
                    1,
                    4,
                    3
                ],
                "borderColor": "rgb(75, 192, 192)",
                "tension": 0.4084947992978105,
                "fill": false
            }
        ]
    },
    [CHART_TYPES.PIE]:{
        labels: ['保险', '保证金', '违约金', '押金','多余项1','多余项2','内环1','内环2'],
        datasets: [
            {
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16','#0fd270', '#8951e4','#ecc143', '#e46751'],
                data: [40, 20, 80, 10,0,0,0,0]
            },
            {
                backgroundColor: ['#0fd270', '#8951e4',],
                data: [0, 0, 0, 0,20,50,0,0]
            },{
                backgroundColor: ['#ecc143', '#e46751',],
                data: [0, 0, 0, 0,0,0,11,90]
            }
        ],
    },
    [CHART_TYPES.RANK]:{
        chartProps: [{
            prop: 'contractSize',
            label: '合同大小',
            sortable: true,
            showOverflowTooltip: true,
        }, {
            prop: 'contractId',
            label: '合同Id',
            sortable: true,
            showOverflowTooltip: true,
        }]
        ,
        data: [{
            contractSize: 11313,
            contractId: 1
        }, {
            contractSize: 11200,
            contractId: 2
        },
            {
                contractSize: 1022,
                contractId: 3
            },
        ]
    }
}

function mockAsync(data){
    let proxyResolve;
    let pro = new Promise(resolve => {
        proxyResolve = resolve;
    })

    //模拟异步
    setTimeout(proxyResolve({
        data:{
            code: 200,
            data
        }
    }),500);

    return pro;
}

export function getMockChartData (type){
    return mockAsync(mockData[type]);
}

export function getMockFields(params){
    let fields = [
        {
            type:'字符串',
            fields:[
                'contractId',
                'contractTitle',
                'creator',
                'signDate',
                'resolveAddress',
                'signAddress',
                'main',
                'otherParties'
            ]
        },
        {
            type:'日期',
            fields: [
                'signTime'
            ]
        },
        {
            type:'数字',
            fields: [
                'editTimes'
            ]
        },
    ];
    return mockAsync(fields);
}
