import {BOOL_OPTIONS, VARIABLE_TYPE_OPTIONS} from "../../constant/question";
import {itemJsonStrPro2Obj} from "../../util";

/**
 * 获取问题用于显示选项的数据结构
 * @param item
 * @return {{TRUE: {name: string, value: number}, FALSE: {name: string, value: number}}|*[]|*}
 */
export const getItemSelectInfo = function (item) {
    if (item.answerType === VARIABLE_TYPE_OPTIONS.BOOL.value) {
        return getObjectValues(BOOL_OPTIONS);
    }
    if (item.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value || item.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value) {
        return item.options.map(v => {
            return {name: v.name, value: v.name};
        })
    }
    return []
}

export const getObjectValues = function (obj) {
    let values = [];
    for (let key in obj) {
        values.push(obj[key]);
    }
    return values;
};
/**
 * 获取答案的纯文本展示形势
 * @param answer
 * @return {string|*}
 */
export const getAnswerSimpleText = function (answer) {
    itemJsonStrPro2Obj(answer,'content');
    if (bool(answer)) {
        return answer.content === BOOL_OPTIONS.TRUE.value ? '是' : '否';
    }else if (multiple(answer)) {
        return answer.content.join(', ')
    }else{
        return answer.content
    }
}
/**
 * 是单选或者多选
 * @param {{answerType}} item
 */
export const singleOrMultiple = function (item) {
    return single(item) || multiple(item);
}
/**
 * 是布尔
 * @param {{answerType}} item
 */
export const bool = function (item) {
    return item.answerType === VARIABLE_TYPE_OPTIONS.BOOL.value
}

/**
 * 是布尔
 * @param {{answerType}} item
 */
export const isTextType = function (item) {
    return item.answerType === VARIABLE_TYPE_OPTIONS.TEXT.value
}

/**
 * 是布尔
 * @param {{answerType}} item
 */
 export const isOrganization = function (item) {
    return item.answerType === VARIABLE_TYPE_OPTIONS.ORGANIZATION.value
}
/**
 * 能否将当前文本作为默认答案。
 * @param question
 * @return {boolean|boolean}
 */
export const canTakeTextAsDefaultAnswer = function (question) {
    return (question.defaultAnswer === null || question.defaultAnswer === undefined) && isTextType(question)
};
/**
 * 是单选
 * @param {{answerType}} item
 */
export const single = function (item) {
    return item.answerType === VARIABLE_TYPE_OPTIONS.SINGLE_OPTION.value
}
/**
 * 是单选或者布尔
 * @param {{answerType}} item
 */
export const singleOrBool = function (item) {
    return single(item) || bool(item);
}
/**
 * 是多选
 * @param {{answerType}} item
 */
export const multiple = function (item) {
    return item.answerType === VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value
}
/**
 * 将 问卷和问卷标题转为editorjs需要的block信息。
 * @param answerSheetIntro
 * @return {[]|*[]}
 */
export const convertAnswerSheetIntroToEditorJsBlocks = function (answerSheetIntro) {
    let result =[]
    if (answerSheetIntro.questionnaireTitle) {
        result.push(titleBlock(answerSheetIntro.questionnaireTitle));
    }
    if (!answerSheetIntro.questionnaireIntro) {
        return result;
    }
    return result.concat(answerSheetIntro.questionnaireIntro.split("\n").map(text => simpleBlock(text)));
}

/**
 * 返回 标题block
 * @param text
 * @return {{data: {rawText: *, rFonts: {eastAsia: string, ascii: string}, paraRPr: {b: boolean, fontSz: number, textFont: {eastAsia: string, ascii: string}}, sz: string, text: [{insert: *, attributes: {rFonts: {eastAsia: string, ascii: string}, sz: number, bold: boolean}}], bold: string, align: string}, type: string}}
 */
function titleBlock(text) {
    return {
        "type": "metaParagraph",
        "data": {
            "text": [
                {
                    "attributes": {
                        "rFonts": {
                            "ascii": "Times New Roman",
                            "eastAsia": "宋体"
                        },
                        "sz": 30,
                        "bold": true
                    },
                    "insert": text
                }
            ],
            "paraRPr": {
                "b": true,
                "fontSz": 30,
                "textFont": {
                    "ascii": "Times New Roman",
                    "eastAsia": "黑体"
                }
            },
            "rawText": text,
            "rFonts": {
                "ascii": "Times New Roman",
                "eastAsia": "黑体"
            },
            "sz": "30",
            "bold": "true",
            "align": "center"
        }
    }
}
function simpleBlock(text) {
    return {
        "type":"metaParagraph",
        "data":{
            "text":[
                {
                    "attributes":{
                        "rFonts":{
                            "ascii":"Times New Roman",
                            "eastAsia":"宋体"
                        }
                    },
                    "insert":text
                }
            ],
            // "id":"ff86b04c-e86c-4b9a-adc7-4256f97419b8",
            "paraRPr":{
                "textFont":{
                    "ascii":"Times New Roman",
                    "eastAsia":"宋体"
                }
            },
            "rawText":text,
            "align":"both",
            "rFonts":{
                "ascii":"Times New Roman",
                "eastAsia":"宋体"
            }
        }
    }
}
