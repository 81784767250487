<template>
    <div>
        <h4 style="text-align: center;font-size: 20px;" v-if="answerSheetIntro.questionnaireTitle&&answerSheetIntro.questionnaireTitle.length>0">{{answerSheetIntro.questionnaireTitle}}</h4>

        <div class="answerSheetIntro"  v-if="answerSheetIntro.questionnaireIntro&&answerSheetIntro.questionnaireIntro.length>0" v-html="answerSheetIntro.questionnaireIntro"></div>
    </div>

</template>

<style lang="scss" >
    .answerSheetIntro{
        text-align: left;
        text-indent: 5px;
        font-size: 12px;
        white-space: pre-wrap;
        padding: 0 14px;
        ul,ol {
            padding-left: 14px;
        }
    }
</style>
<script>
    import {
        getTemplateSetting, saveTemplateSetting,
    } from "../api/api";
    import {bool} from "./common/question";
    export default {
        name: "templateAnswerSheetIntro",
        props:{
            fileId: {type: [String,Number]},
            auth:{
                type:String,
                default:function () {
                    return null;
                }
            },
            autoGet:{
                type:Boolean,
                default:function () {
                    return true;
                }
            },
            answerSheetIntro:{
                type: Object,
                default: function () {
                    return {
                        questionnaireTitle: '',
                        questionnaireIntro: ''
                    };
                }
            }
        },
        data:function () {
            return {
                saver: null
            }
        },
        mounted() {
            if (this.autoGet) {
                this.getTemplateSetting();
            }
        },
        methods:{
            getTemplateSetting:function(){
                getTemplateSetting(this.fileId,this.auth).then(res=>{
                    if (res.data.code === 0) {
                        const setting = res.data.data
                        this.answerSheetIntro.questionnaireIntro = setting.questionnaireIntro;
                        this.answerSheetIntro.questionnaireTitle = setting.questionnaireTitle;
                    }
                });
            }
        },
        components: {}
    }
</script>
